import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { PaginationCustom, PanelHeader, Spinner } from '../../components';
import { projects } from '../../helpers/actions/sellers';
import ProjectCard from './ProjectCard';
import Filters from './Filters';

class MyProjects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projects: [],
      count: 0,
      page: 1,
      limit: 20,
      search: '',
      type: null,
      supervisor: null,
      status: null
    };
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, search, type, supervisor, status } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(projects({ limit, offset, search, type, supervisor, status }))
      .then(({ data, meta }) =>
        this.setState({
          projects: data,
          ...meta,
          loading: false
        })
      )
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    this.load();
  }

  executeFilter = (name, value) => {
    this.setState(
      ps => ({
        ...ps,
        page: 1,
        [name]: value
      }),
      () => this.load()
    );
  };

  render() {
    let { loading, projects, page, limit, count } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}

          <Filters filter={(name, value) => this.executeFilter(name, value)} />

          {projects.length > 0 ? (
            <div>
              <Row>
                {projects.map((project, key) => (
                  <Col key={key} xs={12} md={3}>
                    <ProjectCard project={project} />
                  </Col>
                ))}
              </Row>
              <Row>
                <Col xs={12} className={'text-center'}>
                  <PaginationCustom
                    className="display-flex justify-content-center"
                    page={page}
                    count={count}
                    maxPerPage={limit}
                    onPageChange={page =>
                      this.setState({ page }, () => this.load())
                    }
                  />
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col xs={12} md={12}>
                <Card>
                  <CardBody>
                    <p className={'text-not-found text-center'}>
                      <Trans>No projects found</Trans>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(MyProjects));
