import React from 'react';
import { Trans } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Button, GoogleMapLibrary } from 'components';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

class QuestionInputAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: null
    };
  }

  isValid() {
    let address = this.address;
    return address.validity.valid;
  }

  async confirm() {
    if (this.isValid()) {
      let { address } = this.state;

      await geocodeByAddress(address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          this.props.onConfirm({ address, lat, lng });
        })
        .catch(() => {
          this.props.onConfirm({ address, lat: null, lng: null });
        });
    }
  }

  render() {
    let { address } = this.state;

    return (
      <div>
        <Row>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>{this.props.question}</Trans>
            </h5>
          </Col>
          <Col xs={12}>
            <GoogleMapLibrary>
              <PlacesAutocomplete
                value={address || ''}
                onChange={address => this.setState({ address })}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                  <div>
                    <input
                      ref={node => (this.address = node)}
                      {...this.props.inputProps}
                      {...getInputProps({
                        className:
                          'form-control p-3 mb-2 bg-white rounded options-radios-card'
                      })}
                    />
                    <ul
                      className={
                        'dropdown-menu inner ' +
                        (suggestions.length === 0 ? '' : 'show')
                      }
                    >
                      {suggestions.map(suggestion => (
                        <li {...getSuggestionItemProps(suggestion)}>
                          <a href="/#" className="dropdown-item">
                            <span className="text">
                              {suggestion.description}
                            </span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </PlacesAutocomplete>
            </GoogleMapLibrary>
          </Col>
          <Col xs={12}>
            <Button
              className="float-right"
              color="default"
              onClick={() => this.confirm()}
            >
              <Trans>Confirm</Trans>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

QuestionInputAddress.propTypes = {};

export default QuestionInputAddress;
