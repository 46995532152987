import React from 'react';
import { Trans, translate } from 'react-i18next';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  InputGroup
} from 'reactstrap';
import { auth } from 'firebase';
// import bgImage from '../../assets/img/bg14.webp';
import logo from '../../assets/img/ps-logo-white.png';
import { Spinner } from '../../components';
import { firebaseConnect, isEmpty } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { randomIntFromInterval } from '../../helpers/utils';

let imageBack = '';
let random = randomIntFromInterval(1, 4);
import(`../../assets/back/kitchen_${random}.jpg`).then(image => {
  imageBack = image.default;
});

const LoginPage = translate('translations-fr')(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        password: '',
        loginMessage: null,
        loading: false
      };
    }

    componentDidMount() {
      let token = localStorage.getItem('token');
      if (
        !isEmpty(this.props.auth) &&
        token !== 'null' &&
        token !== '' &&
        token !== undefined
      ) {
        let { history } = this.props;
        history.push('/dashboard');
      }
    }

    setUserToken() {
      return new Promise((resolve, reject) => {
        auth()
          .currentUser.getIdToken(true)
          .then(token => {
            localStorage.setItem('token', token);
            resolve(true);
          })
          .catch(err => {
            reject(err);
          });
      });
    }

    onChange(name, nameValid, value, valid) {
      this.setState({
        [name]: value,
        [nameValid]: valid
      });
    }

    validate() {
      let email = this.email;
      let password = this.password;

      this.setState({
        [email.attributes.getNamedItem('namevalid').value]:
          email.validity.valid,
        [password.attributes.getNamedItem('namevalid').value]:
          password.validity.valid
      });

      return email.validity.valid && password.validity.valid;
    }

    login() {
      if (this.validate()) {
        this.setState(ps => ({ ...ps, loading: true }));
        let { email, password } = this.state;

        auth()
          .signInWithEmailAndPassword(email, password)
          .then(authResult => {})
          .catch(err => {
            this.setState({ loginMessage: err.message, loading: false });
          });
      }
    }

    render() {
      let { email, password, loginMessage, loading } = this.state,
        { t } = this.props;

      return (
        <div>
          {loading ? (
            <Spinner
              style={{
                zIndex: 9999,
                backgroundColor: 'rgba(255,255,255, 0.3)'
              }}
              inside={true}
            />
          ) : null}
          <div className="full-page-content">
            <div className="login-page">
              <Container>
                <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                  {isEmpty(this.props.auth) ? (
                    <Form>
                      <Card className="card-plain">
                        <CardHeader>
                          <div className="logo-container-ps">
                            <img src={logo} alt="now-logo" />
                          </div>
                          {loginMessage ? (
                            <Alert color="danger">
                              <span>{loginMessage}</span>
                            </Alert>
                          ) : null}
                        </CardHeader>
                        <CardBody>
                          <InputGroup
                            size="lg"
                            className={
                              'no-border ' +
                              (this.state.emailFocus ? 'input-group-focus' : '')
                            }
                          >
                            <div className={'input-group-addon'}>
                              <i className="now-ui-icons users_circle-08" />
                            </div>
                            <Input
                              type="email"
                              innerRef={node => (this.email = node)}
                              namevalid="emailValid"
                              placeholder={`${t('Email')}...`}
                              value={email || ''}
                              required="required"
                              onFocus={() =>
                                this.setState({ emailFocus: true })
                              }
                              onBlur={() =>
                                this.setState({ emailFocus: false })
                              }
                              onChange={event =>
                                this.onChange(
                                  'email',
                                  'emailValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                              className="login_ipunt"
                            />
                          </InputGroup>
                          {this.state.emailValid === false ? (
                            <p className="text-center text-danger">
                              {t('Invalid Email')}
                            </p>
                          ) : null}
                          <InputGroup
                            size="lg"
                            className={
                              'no-border ' +
                              (this.state.passwordFocus
                                ? 'input-group-focus'
                                : '')
                            }
                          >
                            <div className={'input-group-addon'}>
                              <i className="now-ui-icons text_caps-small" />
                            </div>
                            <Input
                              type="password"
                              innerRef={node => (this.password = node)}
                              namevalid="passwordValid"
                              placeholder={`${t('Password')}...`}
                              value={password || ''}
                              required="required"
                              onFocus={() =>
                                this.setState({ passwordFocus: true })
                              }
                              onBlur={() =>
                                this.setState({ passwordFocus: false })
                              }
                              onChange={event =>
                                this.onChange(
                                  'password',
                                  'passwordValid',
                                  event.target.value,
                                  event.target.validity.valid
                                )
                              }
                              className="login_ipunt"
                            />
                          </InputGroup>
                          {this.state.passwordValid === false ? (
                            <p className="text-center text-danger">
                              {t('Invalid Password')}
                            </p>
                          ) : null}
                        </CardBody>
                        <CardFooter>
                          <Button
                            className={'btn btn-round btn-block'}
                            color="primary"
                            size="lg"
                            onClick={() => this.login()}
                          >
                            <Trans>Login</Trans>
                          </Button>
                        </CardFooter>
                      </Card>
                    </Form>
                  ) : (
                    <div className="text-center">
                      <h3 className="text-white">
                        <Trans>Hello</Trans>
                      </h3>
                      <h4 className="text-white">
                        <Trans>You are now signed In!</Trans>
                      </h4>
                      <h4 className="text-white">
                        <Trans>Redirecting</Trans>{' '}
                        <i className="fas fa-cog fa-spin" />
                      </h4>
                      <Button color={'link'} onClick={() => auth().signOut()}>
                        <Trans>Sign-out</Trans>
                      </Button>
                    </div>
                  )}
                </Col>
              </Container>
            </div>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: 'url(' + imageBack + ')' }}
          />
        </div>
      );
    }
  }
);

const mapStateToProps = state => {
  return { auth: state.fb.auth };
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect()
)(LoginPage);
