import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {
  wallOperations,
  electriqueOperations,
  plumbingOperations,
  coatingFloors,
  coatingWalls,
  coatingCeilings
} from '../../../variables/estimation';
import QuestionRadio from '../components/QuestionRadio';
import QuestionCheckbox from '../components/QuestionCheckbox';

class IncPieceInformationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      piece: this.props.piece,
      coatingSurfaces: [],
      index: 1,
      lastQuestion: 10
    };
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({ piece: nextProps.piece, index: 1 });
  }

  next(data, nextIndex) {
    let { piece, index, lastQuestion } = this.state;

    if (data && nextIndex) {
      piece = { ...piece, ...data };
      index = nextIndex;
    }

    if (nextIndex === lastQuestion) {
      this.props.onConfirm(piece);
    } else {
      this.setState(ps => ({ ...ps, piece, index }));
      this.props.upScroll();
    }
  }

  addOperation(operations, selected, nextIndex) {
    let { piece } = this.state;
    let result = operations.filter(t => selected.includes(t._id));

    result.forEach(t => {
      piece.operations.push({ ...t, amount: t.unit === 'u' ? 1 : piece.size });
    });

    this.next({ operations: piece.operations }, nextIndex);
  }

  addAddicionalOperations(operations, selected, nextIndex) {
    let { piece } = this.state;
    let result = operations.filter(t => selected.includes(t._id));

    result.forEach(t => {
      piece.additionalOperations.push({
        ...t,
        amount: t.unit === 'u' ? 1 : piece.size
      });
    });

    this.next({ additionalOperations: piece.additionalOperations }, nextIndex);
  }

  render() {
    let { piece, coatingSurfaces, index } = this.state;

    return (
      <div>
        {index === 1 ? (
          <QuestionCheckbox
            question="Modifications of the coating"
            required={false}
            options={[
              { label: 'Floor', value: 'sol' },
              { label: 'Wall', value: 'mur' },
              { label: 'Ceiling', value: 'plafond' }
            ]}
            onConfirm={data => {
              this.setState({ coatingSurfaces: data });
              let nextIndex = data.includes('sol')
                ? 2
                : data.includes('mur')
                ? 3
                : data.includes('plafond')
                ? 5
                : 7;
              this.next({}, nextIndex);
            }}
          />
        ) : null}

        {index === 2 ? (
          <QuestionCheckbox
            question="What kind of coating do you want to have in the floors?"
            required={false}
            options={coatingFloors.map(t => {
              return { label: t.text, value: t._id };
            })}
            onConfirm={data => {
              let nextIndex = coatingSurfaces.includes('mur')
                ? 3
                : coatingSurfaces.includes('plafond')
                ? 5
                : 7;
              this.addOperation(coatingFloors, data, nextIndex);
            }}
          />
        ) : null}

        {index === 3 ? (
          <QuestionRadio
            question="The coating of the walls (painting or other)"
            options={[
              {
                label: 'My walls are in very good condition',
                value: { wallStatus: 'veryGood' }
              },
              {
                label: 'My walls are in good condition',
                value: { wallStatus: 'good' }
              },
              {
                label: 'My walls are in poor condition',
                value: { wallStatus: 'poor' }
              },
              { label: "I don't know", value: { wallStatus: 'notKnown' } }
            ]}
            onConfirm={data => this.next(data, 4)}
          />
        ) : null}

        {index === 4 ? (
          <QuestionCheckbox
            question="For my walls I wish"
            required={true}
            options={coatingWalls
              .filter(element => element.wallStatus.includes(piece.wallStatus))
              .map(t => {
                return { label: t.text, value: t._id };
              })}
            onConfirm={data => {
              let nextIndex = coatingSurfaces.includes('plafond') ? 5 : 7;
              this.addOperation(coatingWalls, data, nextIndex);
            }}
          />
        ) : null}

        {index === 5 ? (
          <QuestionRadio
            question="The coating of the ceilings (painting or other)"
            options={[
              {
                label: 'My ceilings are in very good condition',
                value: { ceilingStatus: 'veryGood' }
              },
              {
                label: 'My ceilings are in good condition',
                value: { ceilingStatus: 'good' }
              },
              {
                label: 'My ceilings are in poor condition',
                value: { ceilingStatus: 'poor' }
              },
              { label: "I don't know", value: { ceilingStatus: 'notKnown' } }
            ]}
            onConfirm={data => this.next(data, 6)}
          />
        ) : null}

        {index === 6 ? (
          <QuestionCheckbox
            question="What type of cladding do you want to put in the ceilings?"
            required={true}
            options={coatingCeilings
              .filter(element =>
                element.ceilingStatus.includes(piece.ceilingStatus)
              )
              .map(t => {
                return { label: t.text, value: t._id };
              })}
            onConfirm={data => this.addOperation(coatingCeilings, data, 7)}
          />
        ) : null}

        {index === 7 ? (
          <QuestionCheckbox
            question="Creating or modifying walls"
            required={false}
            options={wallOperations.map(t => {
              return { label: t.text, value: t._id };
            })}
            onConfirm={data => this.addOperation(wallOperations, data, 8)}
          />
        ) : null}

        {index === 8 ? (
          <QuestionCheckbox
            question="Discount to the electrical standard"
            required={false}
            options={electriqueOperations.map(t => {
              return { label: t.text, value: t._id };
            })}
            onConfirm={data => this.addOperation(electriqueOperations, data, 9)}
          />
        ) : null}

        {index === 9 ? (
          <QuestionCheckbox
            question="Plumbing recovery"
            required={false}
            options={plumbingOperations.map(t => {
              return { label: t.text, value: t._id };
            })}
            onConfirm={data => this.addOperation(plumbingOperations, data, 10)}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(IncPieceInformationCard));
