import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Button, Col, Row } from 'reactstrap';

class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      slides: [],
      slidesShow: []
    };
  }

  componentWillReceiveProps({ slides = [], id }) {
    let slidesShow = [];
    slides.forEach((s, i) => {
      let key = 'slide-' + id + '-' + i;
      slidesShow[key] = i === 0 ? true : false;
    });
    this.setState(ps => {
      return { ...ps, slides, id, slidesShow };
    });
  }

  componentWillMount() {
    let { slides = [], id } = this.props;
    let slidesShow = [];
    slides.forEach((s, i) => {
      let key = 'slide-' + id + '-' + i;
      slidesShow[key] = i === 0 ? true : false;
    });
    this.setState(ps => {
      return { ...ps, slides, id, slidesShow };
    });
  }

  changeSlide(index) {
    let { slides, slidesShow, id } = this.state;
    slides.forEach((s, i) => {
      let key = 'slide-' + id + '-' + i;
      slidesShow[key] = i === index ? true : false;
    });
    this.setState(ps => {
      return { ...ps, slidesShow };
    });
  }

  render() {
    let { slides, id, slidesShow } = this.state;

    return (
      <Row>
        {slides.map((s, i) => {
          let key = 'slide-' + id + '-' + i;

          return (
            <Col
              key={key}
              className={slidesShow[key] ? 'd-online-block' : 'd-none'}
            >
              {s}
              <Button
                disabled={i === 0 ? true : false}
                onClick={() => this.changeSlide(i - 1)}
                size={'sm'}
                className={'slider-prev-' + id + ' slider-prev-button'}
              >
                <i className={'now-ui-icons arrows-1_minimal-left'} />
              </Button>
              <Button
                disabled={i === slides.length - 1 ? true : false}
                onClick={() => this.changeSlide(i + 1)}
                size={'sm'}
                className={'slider-next-' + id + '  slider-next-button'}
              >
                <i className={'now-ui-icons arrows-1_minimal-right'} />
              </Button>
            </Col>
          );
        })}
      </Row>
    );
  }
}

export default connect()(translate('translations-fr')(Slider));
