import { baseUrl, get, createQueryString } from './util';
import clients from './clients';
import pros from './pros';
import projects from './projects';
import materials from './materials';
import operations from './operations';
import providers from './providers';
import supervisors from './supervisors';
import pieces from './pieces';
import surfaces from './surfaces';
import packages from './packages';
import reports from './reports';
import leroyMerlin from './leroymerlin';
import documents from './documents';
import partners from './partners';
import sellers from './sellers';
import users from './users';

export const Clients = clients;
export const Projects = projects;
export const Materials = materials;
export const Operations = operations;
export const Pros = pros;
export const Providers = providers;
export const Supervisors = supervisors;
export const Pieces = pieces;
export const Surfaces = surfaces;
export const Packages = packages;
export const Reports = reports;
export const LeroyMerlin = leroyMerlin;
export const Documents = documents;
export const Partners = partners;
export const Sellers = sellers;
export const Users = users;
export const UserData = () => get(`${baseUrl}/user`);

export default {
  Clients,
  Projects,
  Materials,
  Operations,
  Pros,
  Providers,
  Supervisors,
  Pieces,
  Surfaces,
  Packages,
  Reports,
  LeroyMerlin,
  Documents,
  Partners,
  Sellers,
  Users,
  Security: () => get(`${baseUrl}/login`),
  Board: {
    Stats: () => get(`${baseUrl}/pro/board`),
    Notifications: params =>
      get(`${baseUrl}/pro/notifications${createQueryString(params)}`)
  },
  UserData
};
