import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import QuestionInput from './components/QuestionInput';
import ClientForm from './ClientForm';

class SetClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      step: 1,
      lastStep: 2
    };
  }

  next(data) {
    let { client, step, lastStep } = this.state;
    client = { ...client, ...data };

    if (step === lastStep) {
      this.props.onConfirm({ client });
    } else {
      this.setState(ps => ({ ...ps, client, step: ps.step + 1 }));
      this.props.upScroll();
    }
  }

  render() {
    let { client, step } = this.state;

    return (
      <div>
        {step === 1 ? (
          <QuestionInput
            question="What is the client's email?"
            inputProps={{
              type: 'email',
              placeholder: 'email@gmail.com',
              required: 'required'
            }}
            onConfirm={data => this.next({ email: data })}
          />
        ) : null}

        {step === 2 ? (
          <ClientForm
            email={client.email}
            defaultAddress={this.props.defaultAddress}
            onConfirm={data => this.next(data)}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(SetClient));
