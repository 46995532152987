import Dashboard from '../views/Dashboard/Dashboard';
import Estimation from '../views/Estimation/index';
import MyProjects from '../layouts/MyProjects/MyProjects';

let dashRoutes = [
  // {
  //   path: '/questionnaire',
  //   name: 'Questionnaire',
  //   icon: 'shopping_basket',
  //   component: Questionnaire
  // },
  {
    path: '/estimation',
    name: 'Estimation',
    icon: 'shopping_basket',
    component: Estimation
  },
  {
    id: 'projects',
    path: '/my-projects',
    name: 'My Projects',
    icon: 'design-2_ruler-pencil',
    component: MyProjects,
    origin: null
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/estimation',
    name: 'Estimation'
  },
  {
    path: '*',
    component: Dashboard
  }
];
export default dashRoutes;
