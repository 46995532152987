import React from 'react';
import { Trans, translate } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { Collapse, Nav } from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import PerfectScrollbar from 'perfect-scrollbar';
import { connect } from 'react-redux';
import { Button } from 'components';
import logo from '../../assets/img/ps-logo-simple.png';
import defaultImage from '../../assets/img/default-avatar.png';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';

let ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute('/components') !== '' ? true : false,
      openForms: this.activeRoute('/forms') !== '' ? true : false,
      openTables: this.activeRoute('/tables') !== '' ? true : false,
      //openMaps: this.activeRoute("/maps") !== "" ? true : false,
      openPages: this.activeRoute('/pages') !== '' ? true : false
    };
    this.activeRoute.bind(this);
    this.minimizeSidebar = this.minimizeSidebar.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    let { location } = this.props;
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  minimizeSidebar() {
    var message = 'Sidebar mini ';
    if (document.body.classList.contains('sidebar-mini')) {
      message += 'deactivated...';
    } else {
      message += 'activated...';
    }
    document.body.classList.toggle('sidebar-mini');
    var options = {};
    options = {
      place: 'tr',
      message: message,
      type: 'info',
      icon: 'now-ui-icons ui-1_bell-53',
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  logout() {
    localStorage.setItem('token', null);
    this.props.firebase.logout();
  }

  render() {
    let { auth, userRoutes } = this.props;

    return (
      <div>
        <NotificationAlert ref="notificationAlert" />
        <div className="sidebar" data-color="custom-green">
          <div className="logo">
            <a href="/" className="simple-text logo-mini">
              <div className="logo-img">
                <img src={logo} alt="react-logo" />
              </div>
            </a>
            <a href="/" className="simple-text logo-normal">
              Placeshaker
            </a>
            <div className="navbar-minimize">
              <Button
                simple
                neutral
                icon
                round
                id="minimizeSidebar"
                onClick={this.minimizeSidebar}
              >
                <i className="now-ui-icons text_align-center visible-on-sidebar-regular" />
                <i className="now-ui-icons design_bullet-list-67 visible-on-sidebar-mini" />
              </Button>
            </div>
          </div>

          <div className="sidebar-wrapper" ref="sidebar">
            <div className="user">
              <div
                className="photo avatar-image"
                style={{
                  backgroundImage: `url('${
                    auth && auth.photoURL ? auth.photoURL : defaultImage
                  }')`
                }}
              />
              <div className="info">
                <Link
                  to={'#'}
                  data-toggle="collapse"
                  aria-expanded={this.state.openAvatar}
                  onClick={() =>
                    this.setState({ openAvatar: !this.state.openAvatar })
                  }
                >
                  <span>
                    {auth.displayName}
                    <b className="caret" />
                  </span>
                </Link>
                <Collapse isOpen={this.state.openAvatar}>
                  <ul className="nav mt-1">
                    {userRoutes.map((route, index) => (
                      <li key={index}>
                        <Link to={route.path}>
                          <span className="sidebar-mini-icon">
                            <i className={`now-ui-icons ${route.icon}`} />
                          </span>
                          <span className="sidebar-normal">
                            <Trans>{route.name}</Trans>
                          </span>
                        </Link>
                      </li>
                    ))}
                    <li>
                      <Link
                        to={'/logout'}
                        color={'link'}
                        onClick={e => e.preventDefault() || this.logout()}
                      >
                        <span className="sidebar-mini-icon">
                          <i className="now-ui-icons media-1_button-power" />
                        </span>
                        <span className="sidebar-normal">
                          <Trans>Logout</Trans>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </div>

            <Nav>
              {this.props.routes.map((prop, key) => {
                if (prop.redirect) return null;
                if (prop.collapse) {
                  var st = {};
                  st[prop['state']] = !this.state[prop.state];
                  return (
                    <li className={this.activeRoute(prop.path)} key={key}>
                      <Link
                        to={prop.path}
                        data-toggle="collapse"
                        aria-expanded={this.state[prop.state]}
                        onClick={() => this.setState(st)}
                      >
                        <i className={'now-ui-icons ' + prop.icon} />
                        <p>
                          <Trans>{prop.name}</Trans>
                          <b className="caret" />
                        </p>
                      </Link>
                      <Collapse isOpen={this.state[prop.state]}>
                        <ul className="nav">
                          {prop.views.map((prop, key) => {
                            if (prop.redirect) return null;
                            return (
                              <li
                                className={this.activeRoute(prop.path)}
                                key={key}
                              >
                                <NavLink
                                  to={prop.path}
                                  activeClassName="active"
                                >
                                  <span className="sidebar-mini-icon">
                                    <Trans>{prop.mini}</Trans>
                                  </span>
                                  <span className="sidebar-normal">
                                    <Trans>{prop.name}</Trans>
                                  </span>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  );
                }
                return (
                  <li className={this.activeRoute(prop.path)} key={key}>
                    <NavLink
                      to={prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={'now-ui-icons ' + prop.icon} />
                      <p>
                        <Trans>{prop.name}</Trans>
                      </p>
                    </NavLink>
                  </li>
                );
              })}
            </Nav>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { auth: state.fb.auth, firebase: state.fb };
};

export default compose(
  connect(mapStateToProps),
  translate('translations-fr'),
  firebaseConnect()
)(Sidebar);
