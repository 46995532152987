import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {
  deleteElements,
  deleteFloors,
  addFloors,
  deleteCoverings,
  addCoverings,
  repaintCeilings,
  demolishWall,
  addElements,
  reviewVentilation,
  changeElectricalInstallation
} from '../../../variables/estimation';
import QuestionRadio from '../components/QuestionRadio';
import QuestionCheckbox from '../components/QuestionCheckbox';
import QuestionInput from '../components/QuestionInput';
import QuestionCheckboxNumber from '../components/QuestionCheckboxNumber';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project,
      entity: {
        piece: {
          key: 'bathroom',
          piece: 'bathroom',
          name: this.props.t('Bathroom'),
          size: 6,
          wallStatus: null,
          ceilingStatus: null,
          operations: [],
          additionalOperations: []
        }
      },
      index: 1,
      questionHistory: [1],
      lastQuestion: 20
    };
  }

  next(data, nextIndex) {
    let { entity, index, questionHistory, lastQuestion } = this.state;

    if (data && nextIndex) {
      questionHistory.push(nextIndex);
      entity = { ...entity, ...data };
      index = nextIndex;
    }

    if (nextIndex === lastQuestion) {
      this.props.onConfirm({ pieces: [entity.piece] });
    } else {
      this.setState(ps => ({ ...ps, entity, index, questionHistory }));
      this.props.upScroll();
    }
  }

  addUnitOperation(operations, selected, nextIndex) {
    let { entity } = this.state;
    let result = operations.filter(t => selected.includes(t._id));

    result.forEach(t => {
      entity.piece.operations.push({ ...t, amount: 1 });
    });

    this.next({ piece: entity.piece }, nextIndex);
  }

  setPieceSize(data, nextIndex) {
    let { entity } = this.state;
    entity.piece.size = data;

    this.next({ piece: entity.piece }, nextIndex);
  }

  addOperationByAmount(operations, selected, nextIndex) {
    let { entity } = this.state;

    selected.forEach(op => {
      let index = operations.findIndex(t => t._id === op._id);

      if (index !== -1) {
        entity.piece.operations.push({
          ...operations[index],
          amount: op.amount
        });
      }
    });

    this.next({ piece: entity.piece }, nextIndex);
  }

  render() {
    let { index, entity } = this.state;

    return (
      <div>
        {index === 1 ? (
          <QuestionRadio
            question="Would you like to remove items from your bathroom?"
            options={[
              { label: 'Yes', value: {}, nextIndex: 2 },
              { label: 'No', value: {}, nextIndex: 3 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 2 ? (
          <QuestionCheckbox
            question="What elements do you want to remove?"
            required={true}
            options={deleteElements.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data => this.addUnitOperation(deleteElements, data, 3)}
          />
        ) : null}

        {index === 3 ? (
          <QuestionInput
            question="What is the approximate area of the floor in m²?"
            inputProps={{
              type: 'number',
              step: 0.1,
              required: 'required'
            }}
            default={6}
            onConfirm={data => this.setPieceSize(data, 4)}
          />
        ) : null}

        {index === 4 ? (
          <QuestionRadio
            question="Do you want to remove the flooring?"
            options={[
              { label: 'Yes', value: {}, nextIndex: 5 },
              { label: 'No', value: {}, nextIndex: 7 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 5 ? (
          <QuestionRadio
            question="What is the current coating?"
            options={deleteFloors.map(element => {
              return {
                label: element.text,
                value: element._id
              };
            })}
            onConfirm={data => this.addUnitOperation(deleteFloors, [data], 6)}
          />
        ) : null}

        {index === 6 ? (
          <QuestionRadio
            question="What new coating do you want?"
            options={addFloors.map(element => {
              return {
                label: element.text,
                value: element._id
              };
            })}
            onConfirm={data => this.addUnitOperation(addFloors, [data], 7)}
          />
        ) : null}

        {index === 7 ? (
          <QuestionRadio
            question="Would you like to modify-renovate the wall coverings?"
            options={[
              { label: 'Yes', value: {}, nextIndex: 8 },
              { label: 'No', value: {}, nextIndex: 11 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 8 ? (
          <QuestionRadio
            question="What is the current coating?"
            options={deleteCoverings.map(element => {
              return {
                label: element.text,
                value: element._id
              };
            })}
            onConfirm={data =>
              this.addUnitOperation(deleteCoverings, [data], 9)
            }
          />
        ) : null}

        {index === 9 ? (
          <QuestionRadio
            question="What is the overall condition of the walls?"
            options={[
              { label: 'Bon état', value: { wallStatus: 'veryGood' } },
              { label: 'État moyen', value: { wallStatus: 'good' } },
              { label: 'Mauvais état', value: { wallStatus: 'poor' } }
            ]}
            onConfirm={data =>
              this.next({ piece: { ...entity.piece, ...data } }, 10)
            }
          />
        ) : null}

        {index === 10 ? (
          <QuestionRadio
            question="What new coating do you want?"
            options={addCoverings
              .filter(element =>
                element.wallStatus.includes(entity.piece.wallStatus)
              )
              .map(t => {
                return {
                  label: t.text,
                  value: t._id
                };
              })}
            onConfirm={data => this.addUnitOperation(addCoverings, [data], 11)}
          />
        ) : null}

        {index === 11 ? (
          <QuestionRadio
            question="Would you like to repaint your ceilings?"
            options={[
              { label: 'Yes', value: {}, nextIndex: 12 },
              { label: 'No', value: {}, nextIndex: 13 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 12 ? (
          <QuestionRadio
            question="What is the state of your ceilings?"
            options={[
              { label: 'Bon état', value: { ceilingStatus: 'veryGood' } },
              { label: 'État moyen', value: { ceilingStatus: 'good' } },
              { label: 'Mauvais état', value: { ceilingStatus: 'poor' } }
            ]}
            onConfirm={data => {
              this.setState(
                { entity: { ...entity, piece: { ...entity.piece, ...data } } },
                () =>
                  this.addUnitOperation(
                    repaintCeilings,
                    repaintCeilings
                      .filter(t => t.ceilingStatus.includes(data.ceilingStatus))
                      .map(t => {
                        return t._id;
                      }),
                    13
                  )
              );
            }}
          />
        ) : null}

        {index === 13 ? (
          <QuestionRadio
            question="Do you want to demolish or open a partition or wall?"
            options={[
              { label: 'Yes', value: {}, nextIndex: 14 },
              { label: 'No', value: {}, nextIndex: 15 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 14 ? (
          <QuestionInput
            question="What is the wall length in meters?"
            inputProps={{
              type: 'number',
              step: 0.1,
              required: 'required'
            }}
            onConfirm={data =>
              this.addOperationByAmount(
                demolishWall,
                [{ _id: demolishWall[0]._id, amount: data }],
                15
              )
            }
          />
        ) : null}

        {index === 15 ? (
          <QuestionRadio
            question="Would you like to add new equipment for the bathroom? (Shower, bath, toilet, washbasin ...)"
            options={[
              { label: 'Yes', value: {}, nextIndex: 16 },
              { label: 'No', value: {}, nextIndex: 17 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 16 ? (
          <QuestionCheckbox
            question="What equipment do you want to add?"
            required={true}
            options={addElements.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data => this.addUnitOperation(addElements, data, 17)}
          />
        ) : null}

        {index === 17 ? (
          <QuestionCheckbox
            question="Should we review the ventilation - air extractor? (It is important to ventilate your bathroom to avoid the appearance of moisture on the walls and ceilings)"
            required={true}
            options={reviewVentilation.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data =>
              this.addUnitOperation(reviewVentilation, data, 18)
            }
          />
        ) : null}

        {index === 18 ? (
          <QuestionRadio
            question="Would you like to change the electrical installation (sockets, lighting, washing machine connection)?"
            options={[
              { label: 'Yes', value: {}, nextIndex: 19 },
              { label: 'No', value: {}, nextIndex: 20 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 19 ? (
          <QuestionCheckboxNumber
            question="What are your electrical needs?"
            options={changeElectricalInstallation.map(element => {
              return {
                label: element.text,
                value: 0,
                id: element._id,
                unique: element.unique
              };
            })}
            minValid={1}
            onConfirm={data => {
              let selected = data.filter(t => t.value > 0);
              this.addOperationByAmount(
                changeElectricalInstallation,
                selected.map(t => {
                  return { _id: t.id, amount: t.value };
                }),
                20
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(index));
