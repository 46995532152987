import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import PieceInformationCard from './PieceInformationCard';

class PieceInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pieces: this.props.pieces,
      currentPiece: 0
    };
  }

  nextPiece(data) {
    let { pieces, currentPiece } = this.state;
    pieces[currentPiece] = data;

    if (currentPiece === pieces.length - 1) {
      this.props.onConfirm({ pieces });
    } else {
      this.setState(ps => ({
        ...ps,
        pieces,
        currentPiece: ps.currentPiece + 1
      }));
      this.props.upScroll();
    }
  }

  render() {
    let { pieces, currentPiece } = this.state;

    return (
      <div>
        <Row>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>
                In the complete renovation of my room I confirm that I wish to
                modify
              </Trans>
            </h5>
          </Col>
          <Col xs={12} className={'py-2'}>
            <div className="float-left point-icon">
              <i
                className={`fas
                          ${
                            pieces[currentPiece].piece === 'fullHousing'
                              ? 'fa-home '
                              : ''
                          }
                          ${
                            pieces[currentPiece].piece === 'kitchen'
                              ? 'fa-utensils '
                              : ''
                          }
                          ${
                            pieces[currentPiece].piece === 'bathroom'
                              ? 'fa-bath '
                              : ''
                          }
                          ${
                            pieces[currentPiece].piece === 'principalBedroom' ||
                            pieces[currentPiece].piece === 'secondaryBedroom'
                              ? 'fa-bed '
                              : ''
                          }
                          ${
                            pieces[currentPiece].piece === 'livingRoom'
                              ? 'fa-couch '
                              : ''
                          }
                          ${
                            pieces[currentPiece].piece === 'toilet'
                              ? 'fa-toilet '
                              : ''
                          }
                          ${
                            pieces[currentPiece].piece === 'other'
                              ? 'fa-cube '
                              : ''
                          }
                          `}
              />
            </div>
            <div className="float-left ml-1 dialog-card-container">
              <div className="dialog-card">
                <h6 className={'text-capitalize my-1'}>
                  {pieces[currentPiece].name}
                </h6>
              </div>
            </div>
          </Col>
          <Col xs={12}>
            <PieceInformationCard
              onePieceType={this.props.onePieceType}
              piece={pieces[currentPiece]}
              upScroll={() => this.props.upScroll()}
              onConfirm={data => this.nextPiece(data)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(PieceInformation));
