export function calcOpPriceHT(op) {
  return op.unit_price * op.amount;
}

export function calcOpProPriceHT(op, percent = null) {
  if (op.pro_price) return op.pro_price * op.amount;
  else if (op.unit_price && percent)
    return ((op.unit_price - op.unit_price * percent) * op.amount).toFixed(2);
  else if (op.unit_price)
    return ((op.unit_price - op.unit_price * 0.8) * op.amount).toFixed(2);
  else return 0;
}

export function calcOpPriceTTC(op) {
  return calcOpPriceHT(op) + ((op.tva || 10) / 100) * calcOpPriceHT(op);
}

export function calcOpProPriceTTC(op, percent = null) {
  return (
    calcOpProPriceHT(op, percent) +
    ((op.tva || 10) / 100) * calcOpProPriceHT(op, percent)
  );
}

export function calcMaterialPrix(m, opTVA = null, ht = false) {
  let priceHT = m.unit_price * m.cant;
  return ht ? priceHT : priceHT + priceHT * ((opTVA || 10) / 100);
}

export function calcOpPriceHTWithMaterial(op, pro = false, percent = null) {
  let materialsPrice = 0;
  if (op.materials) {
    op.materials.forEach(m => {
      materialsPrice += calcMaterialPrix(m, op.tva, true);
    });
  }
  if (pro) {
    return materialsPrice + calcOpProPriceHT(op, percent);
  }
  return materialsPrice + calcOpPriceHT(op);
}

export function calcOpPriceTTCWithMaterial(op, pro = false, percent = null) {
  let materialsPrice = 0;
  if (op.materials) {
    op.materials.forEach(m => {
      materialsPrice += calcMaterialPrix(m, op.tva);
    });
  }
  if (pro) {
    return materialsPrice + calcOpProPriceTTC(op, percent);
  }
  return materialsPrice + calcOpPriceTTC(op);
}

export function devisPriceTTC(devis) {
  let price = 0;
  if (devis.groups) {
    devis.groups.forEach(g => {
      g.items.forEach(i => {
        price += calcOpPriceTTC(i);
      });
    });
  }
  return price;
}

export function devisPriceTTCMaterials(devis) {
  let price = 0;
  if (devis.groups) {
    devis.groups.forEach(g => {
      g.items.forEach(i => {
        price += calcOpPriceTTCWithMaterial(i);
      });
    });
  }
  return price;
}

export function devisPriceHT(devis) {
  let price = 0;
  if (devis.groups) {
    devis.groups.forEach(g => {
      g.items.forEach(i => {
        price += calcOpPriceHT(i);
      });
    });
  }
  return price;
}

export function devisProPriceTTC(devis, percent = null) {
  let price = 0;
  devis.groups.forEach(g => {
    g.items.forEach(i => {
      price += calcOpProPriceTTC(i, percent);
    });
  });
  return price;
}

export function devisProPriceHT(devis, percent = null) {
  let price = 0;
  devis.groups.forEach(g => {
    g.items.forEach(i => {
      price += calcOpProPriceHT(i, percent);
    });
  });
  return price;
}

export function devisProPriceHTMaterials(devis, percent = null) {
  let price = 0;
  devis.groups.forEach(g => {
    g.items.forEach(i => {
      price += calcOpPriceHTWithMaterial(i, true, percent);
    });
  });
  return price;
}

export function getProPrice(provider, unitPrice) {
  let proPrice = '';

  if (unitPrice) {
    if (provider && provider.proPercent)
      proPrice = ((unitPrice * provider.proPercent) / 100).toFixed(2);
    else proPrice = (unitPrice * 0.8).toFixed(2);
  }

  return proPrice;
}

export function getDefaultProPrice(unitPrice) {
  return (unitPrice * 0.8).toFixed(2);
}
