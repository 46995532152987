import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Col, Row, Input } from 'reactstrap';
import { Button } from '../../components';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import _ from 'lodash';
import get from 'lodash/get';
import { all } from '../../helpers/actions/users';

class ClientForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {
        email: this.props.email
      }
    };
  }

  componentWillMount() {
    let { client } = this.state,
      { dispatch, defaultAddress } = this.props;

    if (client.email) {
      dispatch(all({ limit: 1, search: client.email }))
        .then(({ data }) => {
          if (data.length) {
            client = data[0];

            if (client.roles && client.roles.length) {
              if (!_.find(client.roles, t => t === 'client')) {
                client.roles.push('client');
              }
            } else {
              client.roles = ['client'];
            }
          } else
            client = {
              ...client,
              address: defaultAddress,
              phone: {},
              client: { origin: 'provider' },
              roles: ['client']
            };

          this.setState(ps => ({ ...ps, client }));
        })
        .catch(err => {});
    }
  }

  onChange(name, value) {
    let { client } = this.state;

    if (name === 'mobile' || name === 'landline' || name === 'work') {
      this.setState({
        client: { ...client, phone: { ...client.phone, [name]: value } }
      });
    } else {
      if (name === 'firstname' || name === 'lastname') {
        this.setState({
          client: { ...client, client: { ...client.client, [name]: value } }
        });
      } else {
        this.setState({ client: { ...client, [name]: value } });
      }
    }
  }

  isValid() {
    let firstname = this.firstname;
    let lastname = this.lastname;
    let address = this.address;
    let mobile = this.mobile;
    return (
      firstname.validity.valid &&
      lastname.validity.valid &&
      address.validity.valid &&
      mobile.validity.valid
    );
  }

  async confirm() {
    if (this.isValid()) {
      let { client } = this.state;

      await geocodeByAddress(client.address)
        .then(results => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          client.lat = lat;
          client.lng = lng;
        })
        .catch(() => {
          client.lat = null;
          client.lng = null;
        });

      this.props.onConfirm({ ...client });
    }
  }

  render() {
    let { client } = this.state;

    return (
      <div>
        <Row>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>My information</Trans>
            </h5>
          </Col>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>First Name</Trans>
            </h5>
            <Row>
              <Col xs={12}>
                <Input
                  type="text"
                  innerRef={node => (this.firstname = node)}
                  className="p-3 mb-2 bg-white rounded options-radios-card"
                  value={get(client, 'client.firstname', '')}
                  required="required"
                  // readOnly={!!client._id}
                  onChange={event =>
                    this.onChange('firstname', event.target.value)
                  }
                />
              </Col>
            </Row>

            <h5 className={'text-left'}>
              <Trans>Last Name</Trans>
            </h5>
            <Row>
              <Col xs={12}>
                <Input
                  type="text"
                  innerRef={node => (this.lastname = node)}
                  className="p-3 mb-2 bg-white rounded options-radios-card"
                  value={get(client, 'client.lastname', '')}
                  required="required"
                  // readOnly={!!client._id}
                  onChange={event =>
                    this.onChange('lastname', event.target.value)
                  }
                />
              </Col>
            </Row>

            <h5 className={'text-left'}>
              <Trans>Address</Trans>
            </h5>
            <Row>
              <Col xs={12}>
                <PlacesAutocomplete
                  value={client.address || ''}
                  onChange={address => this.onChange('address', address)}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                    <div>
                      <input
                        ref={node => (this.address = node)}
                        required="required"
                        // readOnly={!!client._id}
                        {...getInputProps({
                          className:
                            'form-control p-3 mb-2 bg-white rounded options-radios-card'
                        })}
                      />
                      <ul
                        className={
                          'dropdown-menu inner ' +
                          (suggestions.length === 0 ? '' : 'show')
                        }
                      >
                        {suggestions.map(suggestion => (
                          <li {...getSuggestionItemProps(suggestion)}>
                            <a href="/#" className="dropdown-item">
                              <span className="text">
                                {suggestion.description}
                              </span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>

            <h5 className={'text-left'}>
              <Trans>Phone</Trans>
              {' - '}
              <Trans>Mobile</Trans>
            </h5>
            <Row>
              <Col xs={12}>
                <Input
                  type="text"
                  innerRef={node => (this.mobile = node)}
                  className="p-3 mb-2 bg-white rounded options-radios-card"
                  value={get(client, 'phone.mobile', '')}
                  required="required"
                  // readOnly={!!client._id}
                  onChange={event =>
                    this.onChange('mobile', event.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            <Button
              className="float-right"
              color="default"
              onClick={() => this.confirm()}
            >
              <Trans>Confirm</Trans>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(ClientForm));
