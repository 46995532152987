import React from 'react';
import { Trans, translate } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Button } from '../../components';

class End extends React.Component {
  render() {
    return (
      <div className="text-center">
        <Row>
          <Col xs={12}>
            {this.props.success ? (
              <div className="success-animation-container">
                <div className="success-animation-1"></div>
                <span className="success-animation-2"></span>
                <span className="success-animation-3"></span>
                <div className="success-animation-4"></div>
                <div className="success-animation-5"></div>
                <div className="success-animation-6"></div>
              </div>
            ) : (
              <div className="error-animation-container">
                <span className="error-animation-1">
                  <span className="error-animation-2"></span>
                  <span className="error-animation-3"></span>
                </span>
              </div>
            )}
          </Col>
          <Col xs={12}>
            {this.props.success ? (
              <h5>
                <Trans>Your project has been created successfully</Trans>
              </h5>
            ) : (
              <h5>
                <Trans>Sorry, your project could not be saved</Trans>
              </h5>
            )}
          </Col>
          <Col xs={12}>
            <Button
              className={'text-uppercase'}
              color="default"
              size="lg"
              onClick={() => this.props.end()}
            >
              <Trans>Create New Project</Trans>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default translate('translations-fr')(End);
