import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import range from 'lodash/range';

export default ({
  page,
  maxPerPage,
  count,
  className,
  onPageChange = console.log
}) => {
  let totalPages = Math.ceil(count / maxPerPage);

  if (totalPages <= 1) {
    return null;
  }

  let firstLink = (
    <PaginationLink onClick={() => onPageChange(1)}>
      <i className="now-ui-icons arrows-1_minimal-left" />
    </PaginationLink>
  );

  let lastLink = (
    <PaginationLink onClick={() => onPageChange(totalPages)}>
      <i className="now-ui-icons arrows-1_minimal-right" />
    </PaginationLink>
  );

  let firstItem = <PaginationItem>{firstLink}</PaginationItem>;

  let lastItem =
    page === totalPages ? (
      <PaginationItem disabled>{lastLink}</PaginationItem>
    ) : (
      <PaginationItem>{lastLink}</PaginationItem>
    );

  let links = [];

  links.push(firstItem);

  // link if page is bigger than 5
  if (page > 5) {
    links.push(
      <PaginationItem key={page - 5}>
        <PaginationLink onClick={() => onPageChange(page - 5, maxPerPage)}>
          ...
        </PaginationLink>
      </PaginationItem>
    );
  }

  // links from page - 3 tp page -1
  range(page - 3, page)
    .filter(p => p >= 1)
    .map(p =>
      links.push(
        <PaginationItem key={p}>
          <PaginationLink onClick={() => onPageChange(p, maxPerPage)}>
            {p}
          </PaginationLink>
        </PaginationItem>
      )
    );

  links.push(
    <PaginationItem key={page} active disabled>
      <PaginationLink active>{page}</PaginationLink>
    </PaginationItem>
  );

  // links from page + 1 to page + 3
  range(page + 1, page + 4)
    .filter(p => p <= totalPages)
    .map(p =>
      links.push(
        <PaginationItem key={p}>
          <PaginationLink onClick={() => onPageChange(p, maxPerPage)}>
            {p}
          </PaginationLink>
        </PaginationItem>
      )
    );

  if (totalPages + 1 - 5 > page) {
    links.push(
      <PaginationItem key={page + 5}>
        <PaginationLink onClick={() => onPageChange(page + 5, maxPerPage)}>
          ...
        </PaginationLink>
      </PaginationItem>
    );
  }

  links.push(lastItem);

  return <Pagination className={className}>{links}</Pagination>;
};
