import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { sizeEstimation } from '../../../variables/estimation';
import QuestionRadio from '../components/QuestionRadio';
import QuestionCheckboxNumber from '../components/QuestionCheckboxNumber';
import QuestionInput from '../components/QuestionInput';
import PiecesOperations from './PiecesOperations';
import IncPiecesOperations from './IncPiecesOperations';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project,
      entity: {
        pieces: []
      },
      index: 1,
      questionHistory: [1],
      lastQuestion: 11
    };
  }

  next(data, nextIndex) {
    let { entity, index, questionHistory, lastQuestion } = this.state;

    if (data && nextIndex) {
      questionHistory.push(nextIndex);
      entity = { ...entity, ...data };
      index = nextIndex;
    }

    if (nextIndex === lastQuestion) {
      this.props.onConfirm({ ...entity });
    } else {
      this.setState(ps => ({ ...ps, entity, index, questionHistory }));
      this.props.upScroll();
    }
  }

  createIncompletePiece(data, nextIndex) {
    let { entity } = this.state,
      { t } = this.props;

    if (entity.propertySize) {
      let size =
        sizeEstimation.findIndex(t => t._id === data.propertySize) !== -1
          ? sizeEstimation.find(t => t._id === data.propertySize).size
          : 0;

      entity.pieces.push({
        key: 'fullHousing',
        piece: 'fullHousing',
        name: t('Full housing'),
        size,
        coatingSurfaces: [],
        wallStatus: null,
        ceilingStatus: null,
        operations: [],
        additionalOperations: []
      });

      this.next({ ...data, pieces: entity.pieces }, nextIndex);
    }
  }

  createCompletePiece(data, nextIndex) {
    let { entity } = this.state,
      { t } = this.props;

    entity.pieces.push({
      key: 'fullHousing',
      piece: 'fullHousing',
      name: t('Full housing'),
      size: data,
      wallStatus: null,
      ceilingStatus: null,
      operations: [],
      additionalOperations: []
    });

    this.next({ pieces: entity.pieces }, nextIndex);
  }

  addPieces(pieces, nextIndex) {
    let { entity, project } = this.state,
      { t } = this.props;
    let estimation = sizeEstimation.find(t => t._id === entity.propertySize);

    pieces.forEach(piece => {
      if (piece.value > 0) {
        let size = null;

        if (project.type !== 'one-piece' && estimation) {
          size =
            estimation.pieces.findIndex(t => t.piece === piece.id) !== -1
              ? estimation.pieces.find(t => t.piece === piece.id).size
              : null;
        }

        for (let i = 1; i <= piece.value; i++) {
          entity.pieces.push({
            key: `${piece.id}${i}`,
            piece: piece.id,
            name: piece.value > 1 ? `${t(piece.label)} ${i}` : t(piece.label),
            size,
            wallStatus: null,
            ceilingStatus: null,
            operations: [],
            additionalOperations: []
          });
        }
      }
    });

    this.next({ pieces: entity.pieces }, nextIndex);
  }

  render() {
    let { index, project, entity } = this.state,
      { t } = this.props;
    let onePieceType = project && project.type && project.type === 'one-piece';

    return (
      <div>
        {index === 1 ? (
          <QuestionRadio
            question="Are you going to buy the property?"
            options={[
              { label: 'Yes', value: { buying: true }, nextIndex: 2 },
              {
                label: 'No',
                value: { buying: false },
                nextIndex: onePieceType ? 8 : 3
              }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 2 ? (
          <QuestionRadio
            question="Where are you in the buying process?"
            options={[
              {
                label: 'I am visiting property',
                value: { buyingProcess: t('I am visiting property') }
              },
              {
                label: 'I have an offer in progress',
                value: { buyingProcess: t('I have an offer in progress') }
              },
              {
                label: 'I signed my compromise',
                value: { buyingProcess: t('I signed my compromise') }
              }
            ]}
            onConfirm={data => this.next(data, onePieceType ? 8 : 3)}
          />
        ) : null}

        {index === 3 ? (
          <QuestionRadio
            question="What is the area of housing?"
            options={sizeEstimation.map(t => {
              return { label: t.text, value: { propertySize: t._id } };
            })}
            onConfirm={data => this.next(data, 4)}
          />
        ) : null}

        {index === 4 ? (
          <QuestionRadio
            question="Should we renovate a room entirely?"
            options={[
              { label: 'Yes', value: { completePiece: true }, nextIndex: 5 },
              { label: 'No', value: { completePiece: false }, nextIndex: 10 }
            ]}
            onConfirm={(data, nextIndex) => {
              if (data.completePiece) {
                this.next(data, nextIndex);
              } else {
                this.createIncompletePiece(data, nextIndex);
              }
            }}
          />
        ) : null}

        {index === 5 ? (
          <QuestionRadio
            question="Which parts are concerned?"
            options={[
              { label: 'Full housing', value: {}, nextIndex: 6 },
              { label: 'Some rooms', value: {}, nextIndex: 8 }
              // { label: "Full housing", value: {completeRenovation: true}, nextIndex: 6 },
              // { label: "Some rooms", value: {completeRenovation: false}, nextIndex: 8 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 6 ? (
          <QuestionInput
            question="What is the area of full housing?"
            inputProps={{
              type: 'number',
              placeholder: `${t('Example')}: 40 (m²)`,
              step: 0.1,
              required: 'required'
            }}
            onConfirm={data => this.createCompletePiece(data, 7)}
          />
        ) : null}

        {index === 7 ? (
          <QuestionCheckboxNumber
            question="How many pieces do you have?"
            options={[
              { label: 'Kitchen', value: 1, id: 'kitchen', unique: false },
              { label: 'Bathrooms', value: 1, id: 'bathroom', unique: false },
              { label: 'Toilet', value: 1, id: 'toilet', unique: false }
            ]}
            minValid={1}
            onConfirm={data => this.addPieces(data, 9)}
          />
        ) : null}

        {index === 8 ? (
          <QuestionCheckboxNumber
            question="How many pieces do you have?"
            options={[
              { label: 'Kitchen', value: 0, id: 'kitchen', unique: false },
              { label: 'Bathrooms', value: 0, id: 'bathroom', unique: false },
              {
                label: 'Principal Bedroom',
                value: 0,
                id: 'principalBedroom',
                unique: false
              },
              {
                label: 'Secondary Bedroom',
                value: 0,
                id: 'secondaryBedroom',
                unique: false
              },
              {
                label: 'Living room',
                value: 0,
                id: 'livingRoom',
                unique: false
              },
              { label: 'Toilet', value: 0, id: 'toilet', unique: false },
              { label: 'Other', value: 0, id: 'other', unique: true }
            ]}
            minValid={1}
            onConfirm={data => this.addPieces(data, 9)}
          />
        ) : null}

        {index === 9 ? (
          <PiecesOperations
            onePieceType={onePieceType}
            pieces={entity.pieces}
            upScroll={() => this.props.upScroll()}
            onConfirm={data => this.next(data, 11)}
          />
        ) : null}

        {index === 10 ? (
          <IncPiecesOperations
            pieces={entity.pieces}
            upScroll={() => this.props.upScroll()}
            onConfirm={data => this.next(data, 11)}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(index));
