import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AlertConfirm,
  Spinner,
  TableCustom,
  GalleryModal,
  GalleryCanvas
} from '../../../components';
import {
  loadNotes,
  removeNote,
  saveNote,
  addNoteMedia,
  updateNoteMedia
} from '../../../helpers/actions/projects';
import { dateFormatter } from '../../../helpers/formatters';
import NoteModal from './NoteModal';
import SendEmail from '../SendEmails/SendEmail';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';

const enhance = connect(
  // Map redux state to component props
  ({ firebase, fb: { auth, profile } }) => ({
    firebase,
    auth,
    profile
  })
);

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      user: null,
      notes: [],
      noteEdit: null,
      sendByEmail: null,
      noteDelete: null,
      noteGallery: null,
      medias: [],
      images: []
    };
    this.buttonsStyle = {
      color: 'black',
      background: 'transparent',
      border: 'none',
      boxShadow: 'none'
    };
  }

  componentWillMount() {
    this.setState({ loading: true });
    this.setState({ user: this.props.userId }, () => this.load());
  }

  load() {
    this.setState(ps => ({ ...ps, loading: true }));
    let { dispatch, projectId } = this.props;

    dispatch(loadNotes(projectId))
      .then(notes => this.setState(ps => ({ ...ps, notes, loading: false })))
      .catch(() => this.setState(ps => ({ ...ps, loading: false })));
  }

  showAdd() {
    this.setState(ps => ({ ...ps, noteEdit: { completed: false } }));
  }

  openGallery(images, noteGallery) {
    let medias = images.map(media => {
      return {
        id: media._id,
        valide: media.valide,
        label: media.label ? media.label : '',
        name: media.name,
        src: media.source,
        points: media.points,
        elements: media.elements || [],
        isSelected: false
      };
    });
    this.setState(ps => ({
      ...ps,
      gallery: true,
      medias,
      images: cloneDeep(images),
      noteGallery
    }));
  }

  getNotesData() {
    let { notes, user } = this.state,
      { t } = this.props;

    return notes.map((note, key) => {
      return {
        className: '',
        data: [
          { className: '', value: note.title },
          { className: '', fixed: true, value: t(note.type) },
          {
            className: 'text-center',
            fixed: false,
            value: note.completed ? <i className="fa fa fa-check" /> : null
          },
          {
            className: '',
            value: note.user && note.user[0] ? note.user[0].displayName : ''
          },
          {
            className: '',
            fixed: false,
            value: dateFormatter(note.createdAt)
          },
          {
            className: 'text-center',
            fixed: false,
            value: note.send ? <i className="fa fa fa-check" /> : null
          },
          {
            className: '',
            fixed: false,
            properties: { width: '35%' },
            value: <div dangerouslySetInnerHTML={{ __html: note.text }} />
          },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            value: [
              <Button
                key={`noteImages-${key}`}
                id={'note-images-' + note._id}
                className={'ml-1 btn-icon'}
                color="info"
                size="sm"
                onClick={() =>
                  this.openGallery(note.images ? note.images : [], note)
                }
              >
                <i className="fa fa-image icon-action" />
              </Button>,
              <UncontrolledTooltip
                key={`noteImagesTooltip-${key}`}
                placement="left"
                target={'note-images-' + note._id}
                delay={0}
              >
                <Trans>Images</Trans>
              </UncontrolledTooltip>
            ].concat(
              note.user && note.user.length && note.user[0]._id === user
                ? [
                    <Button
                      key={`sendEmail-${key}`}
                      id={'send_by_email' + note._id}
                      className={'ml-1 btn-icon'}
                      color="info"
                      size="sm"
                      onClick={() =>
                        this.setState(ps => ({ ...ps, sendByEmail: note._id }))
                      }
                    >
                      <i className="fa fa-envelope-o icon-action" />
                    </Button>,
                    <UncontrolledTooltip
                      key={`sendEmailTooltip-${key}`}
                      placement="left"
                      target={'send_by_email' + note._id}
                      delay={0}
                    >
                      <Trans>Send by email</Trans>
                    </UncontrolledTooltip>,
                    <Button
                      key={`edit-${key}`}
                      id={'edit' + note._id}
                      className={'ml-1 btn-icon'}
                      color="info"
                      size="sm"
                      onClick={() => this.setState({ noteEdit: note })}
                    >
                      <i className="fa fa-pencil icon-action" />
                    </Button>,
                    <UncontrolledTooltip
                      key={`editTooltip-${key}`}
                      placement="left"
                      target={'edit' + note._id}
                      delay={0}
                    >
                      <Trans>Edit</Trans>
                    </UncontrolledTooltip>,
                    <Button
                      key={`delete-${key}`}
                      id={'delete' + note._id}
                      className={'ml-1 btn-icon'}
                      color="danger"
                      size="sm"
                      onClick={() => this.setState({ noteDelete: note._id })}
                    >
                      <i className="fa fa-trash icon-action" />
                    </Button>,
                    <UncontrolledTooltip
                      key={`deleteTooltip-${key}`}
                      placement="left"
                      target={'delete' + note._id}
                      delay={0}
                    >
                      <Trans>Delete</Trans>
                    </UncontrolledTooltip>
                  ]
                : []
            )
          }
        ]
      };
    });
  }

  async saveNote(note, files) {
    try {
      this.setState(ps => {
        return { ...ps, loading: true };
      });
      let { dispatch, projectId } = this.props;
      delete note.user;

      let n = await dispatch(saveNote(projectId, note));

      if (note._id) n = note;

      if (files.length) await dispatch(addNoteMedia(projectId, n._id, files));

      this.load();
    } catch (err) {
      this.load();
    }
  }

  remove() {
    this.setState({ loading: true });
    let { noteDelete } = this.state,
      { dispatch, projectId } = this.props;

    dispatch(removeNote(projectId, noteDelete))
      .then(() => this.setState({ noteDelete: null }, () => this.load()))
      .catch(() => this.setState({ noteDelete: null, loading: false }));
  }

  removeSendByEmail() {
    this.setState({ sendByEmail: null });
  }

  updateImages() {
    let { medias, images, noteGallery } = this.state;
    let { dispatch, projectId } = this.props;
    let promises = [];

    images.forEach((img, index) => {
      if (!isEqual(img.points, medias[index].points)) {
        images[index].points = medias[index].points;
        promises.push(
          dispatch(
            updateNoteMedia(projectId, noteGallery._id, {
              _id: img._id,
              points: images[index].points
            })
          )
        );
      }
    });

    Promise.all(promises)
      .then(res => {
        this.setState(ps => ({ ...ps, medias: [], images: [] }));
        this.load();
      })
      .catch(err => {});
  }

  savePoints(data, selected) {
    let { medias } = this.state;
    // search selected image
    let i = medias.findIndex(i => i.id === selected);
    // update image
    if (i !== -1) {
      medias[i].elements = data;
      this.setState(ps => ({ ...ps, medias }));
    } else {
      alert('Image not found');
    }
  }

  closeModal() {
    let { medias, images, noteGallery } = this.state;
    let { dispatch, projectId } = this.props;
    let promises = [];

    images.forEach((img, index) => {
      if (!isEqual(img.elements, medias[index].elements)) {
        images[index].elements = medias[index].elements;
        promises.push(
          dispatch(
            updateNoteMedia(projectId, noteGallery._id, {
              _id: img._id,
              elements: images[index].elements
            })
          )
        );
      }
    });

    Promise.all(promises)
      .then(() => {
        this.setState(ps => ({ ...ps, medias: [], images: [] }));
        this.load();
      })
      .catch(() => {});
  }

  render() {
    let { loading, noteEdit, sendByEmail, noteDelete, medias } = this.state,
      { t, created_at } = this.props;
    let rows = this.getNotesData();
    let newGallery = moment(created_at).isAfter('2019-09-15');

    return (
      <div>
        <Card className={'card-plain'} style={{ position: 'relative' }}>
          {loading ? <Spinner inside={true} /> : null}
          <CardHeader className={'pt-0 text-right'}>
            <Button size="sm" color={'info'} onClick={() => this.showAdd()}>
              <Trans>Add</Trans>
            </Button>
            <Button
              className={'ml-2'}
              size="sm"
              color={'default'}
              onClick={() => this.load()}
            >
              <i className="now-ui-icons arrows-1_refresh-69" />
            </Button>
          </CardHeader>
          <CardBody>
            <TableCustom
              minWidth={767}
              className={'devi-item-material-table'}
              accordion={true}
              heads={[
                { value: t('Title') },
                { value: t('Type') },
                { value: t('Completed'), className: 'text-center' },
                { value: t('User') },
                { value: t('Date') },
                { value: t('Sent'), className: 'text-center' },
                { value: t('Description') },
                { value: '', className: 'text-right' }
              ]}
              rows={rows}
              emptyMessage={t('No notes found')}
            />
          </CardBody>
        </Card>

        {!!noteEdit ? (
          <NoteModal
            show={!!noteEdit}
            note={noteEdit}
            projectId={this.props.projectId}
            onCancel={() => this.setState(ps => ({ ...ps, noteEdit: null }))}
            onConfirm={(note, files) =>
              this.setState(
                ps => ({ ...ps, noteEdit: null }),
                () => this.saveNote(note, files)
              )
            }
          />
        ) : null}

        {medias.length && newGallery ? (
          <GalleryCanvas
            next={{
              icon: <i className="fas fa-caret-square-right fa-2x"></i>,
              styles: this.buttonsStyle
            }}
            prev={{
              icon: <i className="fas fa-caret-square-left fa-2x"></i>,
              styles: this.buttonsStyle
            }}
            close={{
              icon: <i className="fas fa-window-close fa-2x"></i>,
              styles: this.buttonsStyle
            }}
            resize={{
              icon: <i className="fas fa-compress-arrows-alt fa-2x"></i>,
              styles: this.buttonsStyle
            }}
            savePointButton={{
              icon: <i className="fas fa-save fa-2x"></i>,
              styles: {}
            }}
            deletePointButton={{
              icon: <i className="fas fa-trash-alt fa-2x"></i>,
              styles: {}
            }}
            closePointButton={{
              icon: <i className="fas fa-window-close fa-2x"></i>,
              styles: {}
            }}
            images={medias}
            selected={medias[0].id}
            closeModal={() => this.closeModal()}
            savePoints={(data, selected) => this.savePoints(data, selected)}
            crudPoints={true}
          />
        ) : null}

        {medias.length && !newGallery ? (
          <GalleryModal
            images={medias}
            selected={medias[0].id}
            closeModal={() => this.updateImages()}
          />
        ) : null}

        {sendByEmail ? (
          <SendEmail
            hide={() => this.removeSendByEmail()}
            projectId={this.props.projectId}
            itemId={sendByEmail}
            itemType={'note'}
          />
        ) : null}

        {noteDelete ? (
          <AlertConfirm
            message={'The note cannot be recovered'}
            onCancel={() => this.setState({ noteDelete: null })}
            onConfirm={() => this.remove()}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(enhance(Notes)));
