import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  CardHeader,
  TabPane
} from 'reactstrap';
import { PanelHeader, Spinner } from '../../components';
import { one } from '../../helpers/actions/projects';
import AssignClient from '../Projects/Client/AssignClient';
import AssignSupervisor from '../Projects/Supervisor/AssignSupervisor';
import AssignPro from '../Projects/Professional/AssignPro';
import Medias from '../Projects/Medias/Medias';
import Events from '../Projects/Events/Events';
import Documents from '../Projects/Documents/Documents';
import Notes from '../Projects/Notes/Notes';
/* import Tasks from '../Projects/Tasks/Tasks'; */
import Devis from '../Projects/Devis/DevisList';
import Maps from '../Projects/Map3D/Maps';
import Maps2D from '../Projects/Map2D/Maps';
import { UserData } from '../../helpers/api';

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      project: {
        operations: [],
        notes: [],
        medias: [],
        history: [],
        events: []
      },
      hTabs: 'notes',
      isOpen: false,
      history: null,
      userId: null
    };
  }

  getProject(projectId) {
    this.setState({ loading: true });
    let { dispatch } = this.props;

    dispatch(
      one(projectId, {
        include: ['client', 'pro', 'supervisors', 'devis'],
        fields: [
          'client',
          'type',
          'pro',
          'supervisors',
          'devis',
          'name',
          'status',
          'plans',
          'address',
          'created_at'
        ]
      })
    )
      .then(project => {
        this.setState({ project, loading: false });
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentWillMount() {
    let { match } = this.props;
    UserData()
      .then(({ user }) => {
        this.setState({ userId: user._id }, () =>
          this.getProject(match.params.id)
        );
      })
      .catch(err => {});
  }

  render() {
    let { loading, project, hTabs, userId } = this.state;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content" style={{ position: 'relative' }}>
          {loading ? <Spinner /> : null}
          <Row>
            <Col>
              <Card key={'project-details'}>
                <CardHeader className={'text-left mb-3'}>
                  <h5>{project.name}</h5>
                  <span className="text-left text-uppercase font-weight-bold">
                    <small>
                      <Trans>Status</Trans> :
                    </small>{' '}
                    <span className={'status-button'}>
                      <Trans>{project.status}</Trans>
                    </span>
                  </span>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={4} className="equal-height mb-4">
              <AssignClient project={project} />
            </Col>
            <Col xs={12} md={4} className="equal-height mb-4">
              <AssignSupervisor project={project} />
            </Col>
            <Col xs={12} md={4} className="equal-height mb-4">
              <AssignPro project={project} />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mb-4">
              {project && project._id ? (
                <Devis project={project} basePath={'my-projects'} />
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Nav pills className="nav-pills-info project-pills">
                    <NavItem>
                      <NavLink
                        className={hTabs === 'notes' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'notes' })}
                      >
                        <Trans>Notes</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'medias' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'medias' })}
                      >
                        <Trans>Medias</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'documents' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'documents' })}
                      >
                        <Trans>Documents</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'events' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'events' })}
                      >
                        <Trans>Events</Trans>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={hTabs === 'maps' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'maps' })}
                      >
                        <Trans>Maps</Trans>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={hTabs === 'tasks' ? 'active' : ''}
                        onClick={() => this.setState({ hTabs: 'tasks' })}
                      >
                        <Trans>Tasks</Trans>
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                  <hr />
                  <TabContent activeTab={hTabs}>
                    <TabPane tabId="medias">
                      {project && project._id ? (
                        <Medias
                          projectId={project._id}
                          created_at={project.created_at}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="documents">
                      {project && project._id ? (
                        <Documents userId={userId} projectId={project._id} />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="notes">
                      {project && project._id ? (
                        <Notes
                          userId={userId}
                          projectId={project._id}
                          created_at={project.created_at}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="events">
                      {project && project._id ? (
                        <Events
                          userId={userId}
                          projectId={project._id}
                          projAddress={project.address}
                        />
                      ) : null}
                    </TabPane>
                    <TabPane tabId="maps">
                      {project && project._id && project.documents ? (
                        <Maps2D
                          projectId={project._id}
                          class={'card-plain user-data'}
                        />
                      ) : null}
                    </TabPane>
                    {/* <TabPane tabId="tasks">
                      {project && project._id ? (
                        <Tasks projectId={project._id} />
                      ) : null}
                    </TabPane> */}
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {project && project._id && project.maps ? (
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Maps
                      projectId={project._id}
                      class={'card-plain user-data'}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Details));
