import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Carousel, CarouselItem } from 'reactstrap';

import { carouselImages } from '../../../variables/estimation';

class CarouselImg extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    };
  }

  next() {
    let { activeIndex } = this.state;
    const nextIndex =
      activeIndex === carouselImages.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    let { activeIndex } = this.state;
    const nextIndex =
      activeIndex === 0 ? carouselImages.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  render() {
    let { activeIndex } = this.state;

    return (
      <Carousel
        interval={20000}
        activeIndex={activeIndex}
        ride={'carousel'}
        next={() => this.next()}
        previous={() => this.previous()}
      >
        {carouselImages.map(item => {
          return (
            <CarouselItem key={item.src}>
              <div
                className="bg-carousel-image"
                style={{
                  backgroundImage: `url('${item.src}')`
                }}
              />
            </CarouselItem>
          );
        })}
      </Carousel>
    );
  }
}

export default connect()(translate('translations-fr')(CarouselImg));
