import React, { Component } from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { firebaseConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import AppContext from './components/Context';
import indexRoutes from './routes/index.jsx';
import { PWAInstall, /* Notifications, */ UpdateToken } from './components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { authUser } from './helpers/actions/index';
import { auth } from 'firebase';

import Auth from './layouts/Auth/Auth';

class App extends Component {
  onError(err) {
    this.refs.notify.notificationAlert({
      type: 'danger',
      message: err,
      autoDismiss: 7
    });
  }

  deleteLoading() {
    let loading = document.getElementById('init-spinner');
    if (loading) loading.remove();
  }

  getUserToken() {
    let token = localStorage.getItem('token');
    if (token === '' || token === undefined || token === 'null') {
      this.setUserToken();
    } else {
      this.props.dispatch(
        authUser('FIREBASE_TOKEN', { token: localStorage.getItem('token') })
      );
    }
  }

  setUserToken() {
    return new Promise((resolve, reject) => {
      auth()
        .currentUser.getIdToken(true)
        .then(token => {
          localStorage.setItem('token', token);
          this.props.dispatch(authUser('FIREBASE_TOKEN', { token }));
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  render() {
    let { user, token } = this.props;
    if (!isLoaded(user)) return null;

    if (
      !isEmpty(user) &&
      (token === '' || token === undefined || token === 'null')
    ) {
      this.getUserToken();
      return null;
    }

    this.deleteLoading();

    return (
      <AppContext.Provider
        value={{ user, authenticated: true, notify: this.onError }}
      >
        <PWAInstall message={''} accept={''} close={''} show={false} />

        <Router>
          <Switch>
            {!isEmpty(user)
              ? indexRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      key={key}
                      component={prop.component}
                    />
                  );
                })
              : [
                  <Route
                    key={'login-component'}
                    path="/login"
                    component={Auth}
                  />,
                  <Route
                    key={'reset-component'}
                    path="/password-reset"
                    component={Auth}
                  />,
                  <Redirect key={'redirect-login'} to={'/login'} />
                ]}
          </Switch>
        </Router>
        {/* !isEmpty(user) ? <Notifications /> : null */}
        <UpdateToken />
      </AppContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  let t = localStorage.getItem('token');
  if (t === '' || t === undefined || t === 'null') t = state.auth.token;
  return { user: state.fb.auth, token: t };
};

export default compose(
  connect(mapStateToProps),
  firebaseConnect()
)(App);
