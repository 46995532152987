const auth = (state = null, action) => {
  switch (action.type) {
    case 'FIREBASE_TOKEN':
      state = { ...action.payload };
      break;

    case 'USER_INFO':
      state = { ...action.payload };
      break;

    default:
      state = { token: '', role: '' };
      break;
  }

  return state;
};

export default auth;
