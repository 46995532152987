const statusColors = [
  '#69bffb',
  '#ff6383',
  '#4ac1c0',
  '#ffcd56',
  '#36a2eb',
  '#ccb8f5',
  '#f9b8c3',
  '#fbd3aa',
  '#ff776b',
  '#8460f7',
  '#008e8c',
  '#ff7641',
  '#9a57d2',
  '#4ca2a2'
];

const modelSystems = ['SketchUp', 'MagicPlan'];

const units = ['m²', 'ml', 'u', 'sem', 'ens', 'h'];

const imageTypes = ['other', 'profile', 'project', 'material'];

const noteTypes = ['Note', 'Problem', 'Important'];

const eventTypes = ['delivery', 'construction', 'other', 'visit'];

const documentKind = [
  'rib',
  'kbis',
  'cni_passport',
  'insurance_civil',
  'insurance_decennial',
  'indicating_kitchen_bathroom',
  'fir_law_form',
  'partnership_agreement'
];

const documentStatus = ['ok', 'warning', 'expired'];

const businessTypes = ['Contractor', 'EIRL', 'SARL', 'EURL', 'SASU', 'SAS'];

const specialities = [
  'kitchen',
  'bathroom',
  'general_renovation',
  'garden',
  'attics',
  'swimming_pool'
];

const skills = [
  'electricity',
  'plumbing',
  'carpentry',
  'masonry',
  'painting',
  'garden',
  'locksmith',
  'frame',
  'insulation'
];

const projectSorts = [
  { value: '_id', label: 'Created' },
  { value: 'client.displayName', label: 'Client' },
  { value: 'surface', label: 'Surface' },
  { value: 'visits.date', label: 'Visits' }
];
const orders = [{ value: 1, label: 'Asc' }, { value: -1, label: 'Desc' }];

const projectType = ['one-piece', 'full'];

const documentTypes = [
  'Plans',
  'Devis',
  'Devis Professional',
  'Documents',
  'Other'
];

const evaluateProStatus = ['WORK_FINISH', 'PAID_PLACE', 'PAID_PRO', 'END'];

const orderResponsible = ['client', 'pro', 'placeshaker'];
const orderStatus = [
  'to-do',
  'valid',
  'ordered',
  'late',
  'received',
  'on-site'
];

const mediasLabels = [
  'Kitchen',
  'Dining room',
  'Lobby',
  'Main bedroom',
  'Bedroom',
  'Other bedroom',
  'Main bathroom',
  'Secondary bathroom',
  'Cupboard',
  'Office',
  'Music room',
  'Balcony',
  'Garage',
  'Corridor',
  'Washing room',
  'Game room',
  'Cellar',
  'Workshop',
  'Staircase',
  'Toilets',
  'Buanderie',
  'Patio',
  'Terrace',
  'Entrance',
  'Basement',
  'Attic',
  'Other',
  'Customer plan',
  '3D plan',
  '2D plan'
];

export {
  statusColors,
  modelSystems,
  units,
  imageTypes,
  noteTypes,
  eventTypes,
  documentKind,
  documentStatus,
  businessTypes,
  specialities,
  skills,
  projectSorts,
  orders,
  projectType,
  documentTypes,
  evaluateProStatus,
  orderResponsible,
  orderStatus,
  mediasLabels
};
