import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {
  specialKitchen,
  disassembleKitchen,
  editWallsKitchen,
  deleteFloorsKitchen,
  addFloorsKitchen,
  deleteCoveringsKitchen,
  addCoveringsKitchen,
  repaintCeilingsKitchen,
  levelKitchen,
  appliances,
  specialElements,
  electricalInstallationKitchen,
  plumbingInstallationKitchen
} from '../../../variables/estimation';
import QuestionRadio from '../components/QuestionRadio';
import QuestionInput from '../components/QuestionInput';
import QuestionCheckbox from '../components/QuestionCheckbox';
import QuestionCheckboxNumber from '../components/QuestionCheckboxNumber';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: this.props.project,
      entity: {
        piece: {
          key: 'kitchen',
          piece: 'kitchen',
          name: this.props.t('Kitchen'),
          size: null,
          wallStatus: null,
          ceilingStatus: null,
          operations: [],
          additionalOperations: []
        }
      },
      index: 1,
      questionHistory: [1],
      lastQuestion: 24
    };
  }

  next(data, nextIndex) {
    let { entity, index, questionHistory, lastQuestion } = this.state;

    if (data && nextIndex) {
      questionHistory.push(nextIndex);
      entity = { ...entity, ...data };
      index = nextIndex;
    }

    if (nextIndex === lastQuestion) {
      this.props.onConfirm({ pieces: [entity.piece] });
    } else {
      this.setState(ps => ({ ...ps, entity, index, questionHistory }));
      this.props.upScroll();
    }
  }

  setPieceSize(data, nextIndex) {
    let { entity } = this.state;
    entity.piece = { ...entity.piece, ...data };

    this.next({ piece: entity.piece }, nextIndex);
  }

  addUnitOperation(operations, selected, nextIndex) {
    let { entity } = this.state;
    let result = operations.filter(t => selected.includes(t._id));

    result.forEach(t => {
      entity.piece.operations.push({ ...t, amount: 1 });
    });

    this.next({ piece: entity.piece }, nextIndex);
  }

  addOperationByAmount(operations, selected, nextIndex) {
    let { entity } = this.state;

    selected.forEach(op => {
      let index = operations.findIndex(t => t._id === op._id);

      if (index !== -1) {
        entity.piece.operations.push({
          ...operations[index],
          amount: op.amount
        });
      }
    });

    this.next({ piece: entity.piece }, nextIndex);
  }

  render() {
    let { index, entity } = this.state;

    return (
      <div>
        {index === 1 ? (
          <QuestionInput
            question="What is the square footage of the room to be created or renovated?"
            inputProps={{
              type: 'number',
              step: 0.1,
              required: 'required'
            }}
            onConfirm={data => this.setPieceSize({ size: data }, 2)}
          />
        ) : null}

        {index === 2 ? (
          <QuestionRadio
            question="Is it necessary to dismantle a kitchen equipped (and its furniture) demanding?"
            options={[
              {
                label: 'Yes',
                value: { equippedKitchen: true, operation: 'OSK001' },
                nextIndex: 3
              },
              { label: 'No', value: { equippedKitchen: false }, nextIndex: 4 }
            ]}
            onConfirm={(data, nextIndex) => {
              if (data.equippedKitchen)
                this.addUnitOperation(
                  specialKitchen,
                  [data.operation],
                  nextIndex
                );
              else this.next({}, nextIndex);
            }}
          />
        ) : null}

        {index === 3 ? (
          <QuestionInput
            question="What is the ml surface to deposit?"
            inputProps={{
              type: 'number',
              step: 0.1,
              required: 'required'
            }}
            onConfirm={data =>
              this.addOperationByAmount(
                disassembleKitchen,
                [{ _id: disassembleKitchen[0]._id, amount: data }],
                4
              )
            }
          />
        ) : null}

        {index === 4 ? (
          <QuestionRadio
            question="Would you demolish modify or open a partition or wall?"
            options={[
              {
                label: 'Yes',
                value: { equippedKitchen: true, operation: 'OSK002' },
                nextIndex: 5
              },
              { label: 'No', value: { equippedKitchen: false }, nextIndex: 7 }
            ]}
            onConfirm={(data, nextIndex) => {
              if (data.equippedKitchen)
                this.addUnitOperation(
                  specialKitchen,
                  [data.operation],
                  nextIndex
                );
              else this.next({}, nextIndex);
            }}
          />
        ) : null}

        {index === 5 ? (
          <QuestionInput
            question="What is the wall surface concerned in m²?"
            inputProps={{
              type: 'number',
              step: 0.1,
              required: 'required'
            }}
            onConfirm={data => this.setPieceSize({ sizeMur: data }, 6)}
          />
        ) : null}

        {index === 6 ? (
          <QuestionCheckbox
            question="What do you want to do?"
            required={true}
            options={editWallsKitchen.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data =>
              this.addOperationByAmount(
                editWallsKitchen,
                data.map(t => {
                  return {
                    _id: t,
                    amount: entity.piece.sizeMur ? entity.piece.sizeMur : 0
                  };
                }),
                7
              )
            }
          />
        ) : null}

        {index === 7 ? (
          <QuestionRadio
            question="Would you like to modify-renovate the floor covering?"
            options={[
              {
                label: 'Yes',
                value: { editFloor: true, operation: 'OSK003' },
                nextIndex: 8
              },
              { label: 'No', value: { editFloor: false }, nextIndex: 10 }
            ]}
            onConfirm={(data, nextIndex) => {
              if (data.editFloor)
                this.addUnitOperation(
                  specialKitchen,
                  [data.operation],
                  nextIndex
                );
              else this.next({}, nextIndex);
            }}
          />
        ) : null}

        {index === 8 ? (
          <QuestionRadio
            question="What is the current coating?"
            options={deleteFloorsKitchen.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data =>
              this.addUnitOperation(deleteFloorsKitchen, [data], 9)
            }
          />
        ) : null}

        {index === 9 ? (
          <QuestionRadio
            question="What new coating do you want?"
            options={addFloorsKitchen.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data =>
              this.addUnitOperation(addFloorsKitchen, [data], 10)
            }
          />
        ) : null}

        {index === 10 ? (
          <QuestionRadio
            question="Would you like to modify-renovate existing wall coverings? (Excluding credence)"
            options={[
              { label: 'Yes', value: {}, nextIndex: 11 },
              { label: 'No', value: {}, nextIndex: 14 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 11 ? (
          <QuestionRadio
            question="What is the current coating?"
            options={deleteCoveringsKitchen.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data =>
              this.addUnitOperation(deleteCoveringsKitchen, [data], 12)
            }
          />
        ) : null}

        {index === 12 ? (
          <QuestionRadio
            question="What is the overall condition of the walls?"
            options={[
              { label: 'Bon état', value: { wallStatus: 'veryGood' } },
              { label: 'État moyen', value: { wallStatus: 'good' } },
              { label: 'Mauvais état', value: { wallStatus: 'poor' } },
              { label: 'Je ne sais pas', value: { wallStatus: 'notKnown' } }
            ]}
            onConfirm={data =>
              this.next({ piece: { ...entity.piece, ...data } }, 13)
            }
          />
        ) : null}

        {index === 13 ? (
          <QuestionRadio
            question="What new coating do you want?"
            options={addCoveringsKitchen
              .filter(element =>
                element.wallStatus.includes(entity.piece.wallStatus)
              )
              .map(t => {
                return {
                  label: t.text,
                  value: t._id
                };
              })}
            onConfirm={data =>
              this.addUnitOperation(addCoveringsKitchen, [data], 14)
            }
          />
        ) : null}

        {index === 14 ? (
          <QuestionRadio
            question="Would you like to repaint your ceilings?"
            options={[
              { label: 'Yes', value: {}, nextIndex: 15 },
              { label: 'No', value: {}, nextIndex: 16 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 15 ? (
          <QuestionRadio
            question="What is the state of your ceilings?"
            options={[
              { label: 'Bon état', value: { ceilingStatus: 'veryGood' } },
              { label: 'État moyen', value: { ceilingStatus: 'good' } },
              { label: 'Mauvais état', value: { ceilingStatus: 'poor' } },
              { label: 'Je ne sais pas', value: { ceilingStatus: 'notKnown' } }
            ]}
            onConfirm={data => {
              this.setState(
                { entity: { ...entity, piece: { ...entity.piece, ...data } } },
                () =>
                  this.addUnitOperation(
                    repaintCeilingsKitchen,
                    repaintCeilingsKitchen
                      .filter(t => t.ceilingStatus.includes(data.ceilingStatus))
                      .map(t => {
                        return t._id;
                      }),
                    16
                  )
              );
            }}
          />
        ) : null}

        {index === 16 ? (
          <QuestionInput
            question="What is the area in ml of the kitchen to create or renovate?"
            inputProps={{
              type: 'number',
              step: 0.1,
              required: 'required'
            }}
            onConfirm={data => this.setPieceSize({ sizeKitchen: data }, 17)}
          />
        ) : null}

        {index === 17 ? (
          <QuestionRadio
            question="What level of range do you want?"
            options={levelKitchen.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data =>
              this.addOperationByAmount(
                levelKitchen,
                [
                  {
                    _id: data,
                    amount: entity.piece.sizeKitchen
                      ? entity.piece.sizeKitchen
                      : 0
                  }
                ],
                18
              )
            }
          />
        ) : null}

        {index === 18 ? (
          <QuestionCheckboxNumber
            question="Select your needs in appliances"
            options={appliances.map(element => {
              return {
                label: element.text,
                value: 0,
                id: element._id,
                unique: element.unique
              };
            })}
            minValid={1}
            onConfirm={data => {
              let selected = data.filter(t => t.value > 0);
              this.addOperationByAmount(
                appliances,
                selected.map(t => {
                  return { _id: t.id, amount: t.value };
                }),
                19
              );
            }}
          />
        ) : null}

        {index === 19 ? (
          <QuestionCheckbox
            question="Do you have special elements"
            required={false}
            options={specialElements.map(element => {
              return {
                label: element.text,
                value: { id: element._id, unit: element.unit }
              };
            })}
            onConfirm={data =>
              this.addOperationByAmount(
                specialElements,
                data.map(t => {
                  return {
                    _id: t.id,
                    amount:
                      t.unit === 'u'
                        ? 1
                        : entity.piece.sizeKitchen
                        ? entity.piece.sizeKitchen
                        : 0
                  };
                }),
                20
              )
            }
          />
        ) : null}

        {index === 20 ? (
          <QuestionRadio
            question="Is it necessary to modify the electrical installation (sockets, lighting, ...)?"
            options={[
              { label: 'Yes / Maybe', value: {}, nextIndex: 21 },
              { label: 'No', value: {}, nextIndex: 22 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 21 ? (
          <QuestionCheckboxNumber
            question=""
            options={electricalInstallationKitchen.map(element => {
              return {
                label: element.text,
                value: 0,
                id: element._id,
                unique: element.unique
              };
            })}
            minValid={1}
            onConfirm={data => {
              let selected = data.filter(t => t.value > 0);
              this.addOperationByAmount(
                electricalInstallationKitchen,
                selected.map(t => {
                  return { _id: t.id, amount: t.value };
                }),
                22
              );
            }}
          />
        ) : null}

        {index === 22 ? (
          <QuestionRadio
            question="Is it necessary to modify the plumbing installation (Sink, ML, LL, ...)?"
            options={[
              { label: 'Yes / Maybe', value: {}, nextIndex: 23 },
              { label: 'No', value: {}, nextIndex: 24 }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {index === 23 ? (
          <QuestionCheckboxNumber
            question=""
            options={plumbingInstallationKitchen.map(element => {
              return {
                label: element.text,
                value: 0,
                id: element._id,
                unique: element.unique
              };
            })}
            minValid={1}
            onConfirm={data => {
              let selected = data.filter(t => t.value > 0);
              this.addOperationByAmount(
                plumbingInstallationKitchen,
                selected.map(t => {
                  return { _id: t.id, amount: t.value };
                }),
                24
              );
            }}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(index));
