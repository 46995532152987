import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  UncontrolledTooltip
} from 'reactstrap';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import {
  clientName,
  dateFormatter,
  moneyFormatter,
  providerName
} from '../../helpers/formatters';
import { isMobileOnly } from 'react-device-detect';

const statusIcon = {
  NEW_PROJECT: { icon: 'flaticon-add', color: 'default' },
  CLIENT_TO_CONTACT: { icon: 'flaticon-hospital', color: 'default' },
  CLIENT_TO_REVIVE: { icon: 'flaticon-emergency-call', color: 'default' },
  UNREACHABLE_CLIENT: { icon: 'flaticon-emergency-call-1', color: 'default' },
  SCHEDULED_VISIT: { icon: 'flaticon-address', color: 'default' },
  DEVIS_IN_PROGRESS: { icon: 'flaticon-document', color: 'default' },
  DEVIS_PENDING: { icon: 'flaticon-schedule', color: 'default' },
  DEVIS_ACCEPTED: { icon: 'flaticon-logistics', color: 'default' },
  ORDER_VALIDATED: { icon: 'flaticon-box', color: 'default' },
  WAITING_FOR_PRO: { icon: 'flaticon-appointment', color: 'default' },
  ASSIGNED_PRO: { icon: 'flaticon-followers', color: 'default' },
  FIXED_START_DATE: { icon: 'flaticon-calendar', color: 'default' },
  DELIVERY_ERROR: { icon: 'flaticon-delivery', color: 'default' },
  WORK_IN_PROGRESS: { icon: 'flaticon-support', color: 'default' },
  WORK_FINISH: { icon: 'flaticon-check-1', color: 'default' },
  PAID_PLACE: { icon: 'flaticon-bank', color: 'default' },
  PAID_PRO: { icon: 'flaticon-salary', color: 'default' },
  END: { icon: 'flaticon-check', color: 'success' },
  CLIENT_REJECTED: { icon: 'flaticon-user', color: 'danger' },
  ABANDONED_PROJECT: { icon: 'flaticon-cancel', color: 'danger' }
};

class ProjectCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deployed: false
    };
  }

  componentWillReceiveProps() {
    this.setState({ deployed: false });
  }

  getProjectPrice() {
    let { project } = this.props;
    let price = 0;

    if (project && project.devis && project.devis.length) {
      project.devis.map(
        d => (price += get(d, 'price.ttc', 0) + get(d, 'price.materials', 0))
      );
    } else if (project && project.devisBase) {
      price =
        get(project.devisBase, 'price.ttc', 0) +
        get(project.devisBase, 'price.materials', 0);
    }

    return price;
  }

  render() {
    let { deployed } = this.state,
      { project, t } = this.props;

    return (
      <Card
        className={`card-pricing card-selectable ${deployed ? 'selected' : ''}`}
      >
        <CardHeader
          className={'text-center'}
          onClick={() => this.setState({ deployed: !deployed })}
        >
          <h6 className="card-title my-1">
            <Link to={`/my-projects/${project._id}/details`}>
              {project.name}
            </Link>
          </h6>
          <div
            className={`card-icon icon-${statusIcon[get(project, 'status', '')].color}`}
          >
            <i
              id={`status-project-${project._id}`}
              className={`status-icon now-ui-icons
               ${statusIcon[get(project, 'status', '')].icon}
               `}
            />
          </div>
          <UncontrolledTooltip
            placement="right"
            target={`status-project-${project._id}`}
            delay={0}
          >
            <strong>
              <Trans>Status</Trans>
            </strong>
            {': '}
            <Trans>{get(project, 'status', '')}</Trans>
          </UncontrolledTooltip>
          <h5 className="card-title my-1">
            {moneyFormatter(this.getProjectPrice())}
          </h5>
          <h3 className="card-category my-1">{clientName(project.client)}</h3>
          <hr />
        </CardHeader>
        <CardBody
          className={'text-left py-0'}
          style={{ display: deployed ? 'block' : 'none' }}
        >
          <p>
            <strong>
              <Trans>Description</Trans>
            </strong>
            {`: ${get(project, 'description', '-')}`}
          </p>
          <p>
            <strong>
              <Trans>Type</Trans>
            </strong>
            {`: ${t(get(project, 'type', '-'))}`}
          </p>
          <p>
            <strong>
              <Trans>Creation Date</Trans>
            </strong>
            {`: ${dateFormatter(project.created_at)}`}
          </p>
          <p>
            <strong>
              <Trans>Provider</Trans>
            </strong>
            {`: ${providerName(project.provider)}`}
          </p>
          <p>
            <strong>
              <Trans>Address</Trans>
            </strong>
            {': '}
            {project.lat && project.lng ? (
              isMobileOnly ? (
                <a
                  href={`https://www.waze.com/ul?ll=${project.lat}%2C${project.lng}&zoom=17`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {get(project, 'address', '-')}
                </a>
              ) : (
                <a
                  href={`https://www.google.com/maps/place/${get(
                    project,
                    'address',
                    ''
                  )}/@=${project.lat},${project.lng}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {get(project, 'address', '-')}
                </a>
              )
            ) : (
              get(project, 'address', '-')
            )}
          </p>
          <p>
            <strong>
              <Trans>Surface</Trans>
            </strong>
            {`: ${get(project, 'surface', '-')} m`}
            <sup>2</sup>
          </p>
          <hr />
        </CardBody>
        <CardFooter className={'pt-0 text-center'}>
          <Link
            to={`/my-projects/${project._id}/details`}
            className="btn btn-sm btn-default mr-1"
          >
            <Trans>Details</Trans>
          </Link>
        </CardFooter>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(ProjectCard));
