import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { withFirebase } from 'react-redux-firebase';
import get from 'lodash/get';
import { save } from '../../helpers/actions/projects';
import { save as saveClient } from '../../helpers/actions/users';
import { UserData } from '../../helpers/api';
import {
  clientName,
  moneyFormatter,
  sellerName
} from '../../helpers/formatters';
import {
  estimationTypes,
  sizeEstimation,
  levels
} from '../../variables/estimation';
import QuestionRadio from './components/QuestionRadio';
import QuestionRadioIcon from './components/QuestionRadioIcon';
import QuestionInputAddress from './components/QuestionInputAddress';
import QuestionInputOption from './components/QuestionInputOption';
import Welcome from './Welcome';
import Other from './Other/index';
import Bathroom from './Bathroom/index';
import Kitchen from './Kitchen/index';
import WithoutEstimation from './WithoutEstimation/index';
import EstimationCard from './EstimationCard';
import SetClient from './SetClient';
import End from './End';

const enhance = connect(
  // Map redux state to component props
  ({ firebase, fb: { auth, profile } }) => ({
    firebase,
    auth,
    profile
  })
);

class Estimation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seller: {},
      project: {},
      index: 0,
      questionHistory: [],
      lastQuestion: 13,
      success: false
    };
  }

  componentDidMount() {
    UserData()
      .then(({ user }) => {
        this.setState(ps => ({
          ...ps,
          seller: { ...user }
        }));
      })
      .catch(err => {
        console.log(err);
      });
  }

  next(data, nextIndex) {
    let { project, index, questionHistory, lastQuestion } = this.state;

    if (data && index) {
      questionHistory.push(nextIndex);
      project = { ...project, ...data };
      index = nextIndex;
    }

    if (nextIndex === lastQuestion) {
      this.createProject(project);
    } else {
      this.setState(ps => ({ ...ps, project, index, questionHistory }));
      this.props.upScroll();
    }
  }

  createProject(project) {
    let { seller } = this.state,
      { dispatch } = this.props;
    let note = this.createNote(project);

    let entitySave = {
      client: project.client._id ? project.client._id : null,
      provider: get(seller, 'seller.provider'),
      sellers: seller && seller._id ? [seller._id] : [],
      name: `${clientName(project.client, '')}-${
        project.propertySize ? project.propertySize : project.propertyType
      }-${sellerName(seller, '')}`,
      notes: [note],
      address: project.address,
      status: 'NEW_PROJECT',
      type: project.type,
      lat: project.lat,
      lng: project.lng
    };

    if (!project.client._id) {
      dispatch(saveClient(project.client))
        .then(client => {
          entitySave.client = client._id;

          dispatch(save(entitySave))
            .then(() => {
              this.lastIndex(true);
            })
            .catch(() => {
              this.lastIndex(false);
            });
        })
        .catch(() => {
          this.lastIndex(false);
        });
    } else {
      Promise.all([
        dispatch(save(entitySave)),
        dispatch(saveClient(project.client))
      ])
        .then(() => {
          this.lastIndex(true);
        })
        .catch(() => {
          this.lastIndex(false);
        });
    }
  }

  lastIndex(success) {
    let { lastQuestion } = this.state;
    this.setState({ index: lastQuestion, success });
    this.props.upScroll();
  }

  createNote(project) {
    let { seller } = this.state,
      { t } = this.props;

    let text = `<p><strong>Le projet concerne</strong>: ${
      project.propertyType ? project.propertyType : ''
    }${project.subtype ? ' - ' + project.subtype : ''}</p>`;

    if (project.buying !== null) {
      text = `${text} 
        <p><strong>Le client est en train d'acheter le bien</strong>: ${
          !project.buying ? 'Non' : 'Oui'
        }</p>`;

      if (project.buyingProcess) {
        text = `${text} 
            <p><strong>Dans le processus d'achat</strong>: ${
              project.buyingProcess ? project.buyingProcess : ''
            }</p>`;
      }
    }

    if (project.propertySize) {
      let index = sizeEstimation.findIndex(t => t._id === project.propertySize);
      if (index !== -1) {
        text = `${text} <p><strong>Surfaçe du logement</strong>: ${sizeEstimation[index].text}</p>`;
      }
    }

    text = `${text} <p><strong>Rénover une pièce entièrement</strong>: ${
      !project.completePiece ? 'Non' : 'Oui'
    }</p>`;

    if (project.pieces && project.pieces.length > 0) {
      text = `${text} <p><strong>Pièces</strong>:</p><ul>`;

      project.pieces.forEach(piece => {
        text = `${text} <li><span>${piece.name}</span>`;

        if (piece.operations && piece.operations.length) {
          text = `${text} <p>Opérations</p><ul>`;

          piece.operations.forEach(op => {
            text = `${text} <li>${op.text} - ${op.description}</li>`;
          });

          text = `${text} </ul>`;
        }

        if (piece.additionalOperations && piece.additionalOperations.length) {
          text = `${text} <p>Donnée supplémentaire</p><ul>`;

          piece.additionalOperations.forEach(op => {
            text = `${text} <li>${op.text} - ${op.description}</li>`;
          });

          text = `${text} </ul>`;
        }

        if (piece.wallStatus) {
          text = `${text} <p>Etat des murs: ${t(piece.wallStatus)}</p>`;
        }
        if (piece.ceilingStatus) {
          text = `${text} <p>Etat du plafond: ${t(piece.ceilingStatus)}</p>`;
        }

        text = `${text} </li>`;
      });

      text = `${text} </ul>`;
    }

    if (project.need) {
      text = `${text} <p><strong>Ton besoin</strong>: ${project.need}</p>`;
    }

    if (project.standing) {
      text = `${text} <p><strong>Niveau de standing</strong>: ${project.standing.text}</p>`;
    }

    if (project.budget) {
      text = `${text} <p><strong>Budget pose + fournitures</strong>: ${project.budget}</p>`;
    }

    if (project.appointment) {
      text = `${text} <p><strong>Je veux un rendez-vous avec un expert travaux</strong></p>`;
    }

    if (project.minEstimation) {
      text = `${text} <p><strong>Prix minimum sans matériaux</strong>: ${moneyFormatter(
        project.minEstimation
      )}</p>`;
    }

    if (project.maxEstimation) {
      text = `${text} <p><strong>Prix maximum sans matériaux</strong>: ${moneyFormatter(
        project.maxEstimation
      )}</p>`;
    }

    if (project.minEstimationMat) {
      text = `${text} <p><strong>Prix minimum avec matériaux</strong>: ${moneyFormatter(
        project.minEstimationMat
      )}</p>`;
    }

    if (project.maxEstimationMat) {
      text = `${text} <p><strong>Prix maximum avec matériaux</strong>: ${moneyFormatter(
        project.maxEstimationMat
      )}</p>`;
    }

    return {
      title: 'Informations sur la création du projet',
      text,
      completed: false,
      user: seller._id,
      type: 'Note'
    };
  }

  end() {
    this.setState(ps => ({
      ...ps,
      project: {},
      index: 0,
      questionHistory: []
    }));
  }

  render() {
    let { index, project, success } = this.state,
      { t } = this.props;

    return (
      <div className={'seller-estimation p-2 p-md-5'}>
        {/* welcome message */}
        {index === 0 ? (
          <Welcome start={() => this.setState({ index: 1 })} />
        ) : null}

        {/* select estimation type */}
        {index === 1 ? (
          <QuestionRadioIcon
            question="Your project concerns"
            options={estimationTypes}
            onConfirm={(data, nextIndex) => this.next(data, nextIndex)}
          />
        ) : null}

        {/* componente for kitchen estimation */}
        {index === 2 ? (
          <Kitchen
            project={project}
            upScroll={() => this.props.upScroll()}
            onConfirm={data => this.next(data, 6)}
          />
        ) : null}

        {/* componente for bathroom estimation */}
        {index === 3 ? (
          <Bathroom
            project={project}
            upScroll={() => this.props.upScroll()}
            onConfirm={data => this.next(data, 6)}
          />
        ) : null}

        {/* componente for property estimation */}
        {index === 4 ? (
          <Other
            project={project}
            upScroll={() => this.props.upScroll()}
            onConfirm={data => this.next(data, 6)}
          />
        ) : null}

        {/* componente for property without-estimate */}
        {index === 5 ? (
          <WithoutEstimation
            upScroll={() => this.props.upScroll()}
            onConfirm={data => this.next(data, 6)}
          />
        ) : null}

        {/* project address */}
        {index === 6 ? (
          <QuestionInputAddress
            question="What is the address of the accommodation?"
            inputProps={{
              placeholder: t('Address'),
              required: 'required'
            }}
            onConfirm={data => this.next(data, 7)}
          />
        ) : null}

        {/* select additional information about the client's needs */}
        {index === 7 ? (
          <QuestionRadio
            question="Could we know more about your need?"
            options={[
              {
                label: 'I have not found a business yet',
                value: { need: t('I have not found a business yet') }
              },
              {
                label:
                  "I already have a business and I'm looking for another one",
                value: {
                  need: t(
                    "I already have a business and I'm looking for another one"
                  )
                }
              },
              {
                label: 'I need a quote for my bank',
                value: { need: t('I need a quote for my bank') }
              },
              {
                label: 'I need an architect',
                value: { need: t('I need an architect') }
              }
            ]}
            onConfirm={data =>
              this.next(
                data,
                project.estimation === 'without-estimation' ? 11 : 8
              )
            }
          />
        ) : null}

        {/* select the level */}
        {index === 8 ? (
          <QuestionRadio
            question="What level of standing do you have of your renovation?"
            options={levels.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data =>
              this.next({ standing: levels.find(t => t._id === data) }, 9)
            }
          />
        ) : null}

        {/* establish a budget */}
        {index === 9 ? (
          <QuestionInputOption
            question="What is my budget pose + supplies?"
            inputProps={{
              type: 'number',
              placeholder: '...?',
              step: 0.1,
              required: 'required'
            }}
            option={{ label: 'I do not know', value: null }}
            onConfirm={data => this.next({ budget: data }, 10)}
          />
        ) : null}

        {/* show estimation */}
        {index === 10 ? (
          <EstimationCard
            project={project}
            onConfirm={data => this.next(data, 11)}
          />
        ) : null}

        {/* create client */}
        {index === 11 ? (
          <SetClient
            defaultAddress={project.address}
            upScroll={() => this.props.upScroll()}
            onConfirm={data => this.next(data, 12)}
          />
        ) : null}

        {index === 12 ? (
          <QuestionRadio
            question="Do you want an appointment with an expert works?"
            options={[
              { label: 'Yes (Paying)', value: { appointment: true } },
              { label: 'No', value: { appointment: false } }
            ]}
            onConfirm={data => this.next(data, 13)}
          />
        ) : null}

        {/* end message */}
        {index === 13 ? <End success={success} end={() => this.end()} /> : null}
      </div>
    );
  }
}

export default connect()(
  translate('translations-fr')(enhance(withFirebase(Estimation)))
);
