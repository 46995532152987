import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import QuestionRadio from '../components/QuestionRadio';

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entity: {
        pieces: []
      },
      index: 1,
      questionHistory: [1],
      lastQuestion: 2
    };
  }

  next(data, nextIndex) {
    let { entity, index, questionHistory, lastQuestion } = this.state;

    if (data && nextIndex) {
      questionHistory.push(nextIndex);
      entity = { ...entity, ...data };
      index = nextIndex;
    }

    if (nextIndex === lastQuestion) {
      this.props.onConfirm({ ...entity });
    } else {
      this.setState(ps => ({ ...ps, entity, index, questionHistory }));
      this.props.upScroll();
    }
  }

  render() {
    let { index } = this.state;

    return (
      <div>
        {index === 1 ? (
          <QuestionRadio
            question="For which room do you want to be contacted by an expert?"
            options={[
              {
                label: 'Cuisine',
                value: { type: 'one-piece', subtype: 'Cuisine' }
              },
              { label: 'SDB', value: { type: 'one-piece', subtype: 'SDB' } },
              { label: 'A house', value: { type: 'full', subtype: 'A house' } },
              {
                label: 'An apartment',
                value: { type: 'full', subtype: 'An apartment' }
              }
            ]}
            onConfirm={data => this.next(data, 2)}
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(index));
