import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Button, StatisticsCustom } from '../../components';
import { moneyIntFormatter } from '../../helpers/formatters';
import { packages, materialPrice } from '../../variables/estimation';

class EstimationCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minEstimation: null,
      maxEstimation: null,
      minEstimationMat: null,
      maxEstimationMat: null
    };
  }

  componentWillMount() {
    let { project } = this.props;
    console.log('project ', this.props.project);
    let minEstimation = project.priceBase ? project.priceBase : 0;
    let maxEstimation = project.priceBase ? project.priceBase : 0;

    if (project && project.pieces && project.pieces.length > 0) {
      project.pieces.forEach(piece => {
        if (
          project.completePiece &&
          (piece.piece === 'fullHousing' ||
            piece.piece === 'kitchen' ||
            piece.piece === 'bathroom' ||
            piece.piece === 'toilet')
        ) {
          let index = packages.findIndex(t => t.piece === piece.piece);

          if (index !== -1) {
            let packMinPrice =
              packages[index].unit === 'u'
                ? packages[index].min
                : packages[index].min * piece.size;
            let packMaxPrice =
              packages[index].unit === 'u'
                ? packages[index].max
                : packages[index].max * piece.size;
            minEstimation = minEstimation + packMinPrice;
            maxEstimation = maxEstimation + packMaxPrice;
          }
        } else {
          if (piece && piece.operations && piece.operations.length > 0) {
            piece.operations.forEach(op => {
              let opMinPrice = op.min * op.amount;
              let opMaxPrice = op.max * op.amount;
              minEstimation = minEstimation + opMinPrice;
              maxEstimation = maxEstimation + opMaxPrice;
            });
          }
        }
      });
    }

    if (project.standing && project.standing.type === 'discount') {
      let minDiscount = (minEstimation * project.standing.percent) / 100;
      let maxDiscount = (maxEstimation * project.standing.percent) / 100;

      minEstimation = (minEstimation - minDiscount).toFixed(2);
      maxEstimation = (maxEstimation - maxDiscount).toFixed(2);
    } else if (project.standing && project.standing.type === 'increase') {
      let minDiscount = (minEstimation * project.standing.percent) / 100;
      let maxDiscount = (maxEstimation * project.standing.percent) / 100;

      minEstimation = (minEstimation + minDiscount).toFixed(2);
      maxEstimation = (maxEstimation + maxDiscount).toFixed(2);
    }

    let minEstimationMat = minEstimation;
    let maxEstimationMat = maxEstimation;

    if (project.estimation) {
      let index = materialPrice.findIndex(
        t => t.estimation === project.estimation
      );

      if (index !== -1) {
        minEstimationMat = minEstimation * materialPrice[index].multiple;
        maxEstimationMat = maxEstimation * materialPrice[index].multiple;
      }
    }

    this.setState(ps => ({
      ...ps,
      minEstimation: minEstimation,
      maxEstimation: maxEstimation,
      minEstimationMat: minEstimationMat,
      maxEstimationMat: maxEstimationMat
    }));
  }

  confirm() {
    let {
      minEstimation,
      maxEstimation,
      minEstimationMat,
      maxEstimationMat
    } = this.state;
    this.props.onConfirm({
      minEstimation,
      maxEstimation,
      minEstimationMat,
      maxEstimationMat
    });
  }

  render() {
    let {
        minEstimation,
        maxEstimation,
        minEstimationMat,
        maxEstimationMat
      } = this.state,
      { t } = this.props;

    return (
      <div>
        <Row>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>The estimated price without materials is</Trans>
              {':'}
            </h5>
          </Col>
          <Col xs={12}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={6}>
                    <StatisticsCustom
                      icon="fa fa-hand-o-down"
                      iconState="danger"
                      title={moneyIntFormatter(minEstimation)}
                      subtitle={t('MIN PRICE')}
                    />
                  </Col>
                  <Col xs={6}>
                    <StatisticsCustom
                      icon="fa fa-hand-o-up"
                      iconState="success"
                      title={moneyIntFormatter(maxEstimation)}
                      subtitle={t('MAX PRICE')}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>The estimated price with materials is</Trans>
              {':'}
            </h5>
          </Col>
          <Col xs={12}>
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={6}>
                    <StatisticsCustom
                      icon="fa fa-hand-o-down"
                      iconState="danger"
                      title={moneyIntFormatter(minEstimationMat)}
                      subtitle={t('MIN PRICE')}
                    />
                  </Col>
                  <Col xs={6}>
                    <StatisticsCustom
                      icon="fa fa-hand-o-up"
                      iconState="success"
                      title={moneyIntFormatter(maxEstimationMat)}
                      subtitle={t('MAX PRICE')}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12}>
            <Button
              className="float-right"
              color="default"
              onClick={() => this.confirm()}
            >
              <Trans>Confirm</Trans>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(EstimationCard));
