import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem
} from 'reactstrap';
import { Breadcrumbs } from '../../components';
import dashboardRoutes from '../../routes/dashboard.jsx';
import projectsRoutes from '../../routes/myprojects';
import userRoutes from '../../routes/user';

let internalRoutes = [projectsRoutes, userRoutes];

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      updatesOpen: false,
      color: 'transparent'
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
  }

  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent'
      });
    } else {
      this.setState({
        color: 'white'
      });
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  updatesToggle(e) {
    this.setState({
      updatesOpen: !this.state.updatesOpen
    });
  }

  getBrandInternal(path) {
    let name = null;

    internalRoutes.map((file, key) => {
      let basePath = file.find(t => t.redirect === true);

      if (basePath && basePath.path) {
        if (path.startsWith(basePath.path)) {
          path = path.slice(basePath.path.length);

          file.map((route, key) => {
            if (this.equalPath(path, route)) {
              name = route.name;
            }
            return null;
          });
        }
      } else {
        file.map((route, key) => {
          if (this.equalPath(path, route)) {
            name = route.name;
          }
          return null;
        });
      }

      return null;
    });

    return name;
  }

  equalPath(path, route) {
    path = path.split('/');
    route = route.path.split('/');

    let equal = true;

    if (path.length !== route.length) {
      equal = false;
    } else {
      route.map((part, key) => {
        if (!part.startsWith(':') && part !== path[key]) {
          equal = false;
        }
        return null;
      });
    }

    return equal;
  }

  getBrand() {
    let name = null;

    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });

    if (!name) name = this.getBrandInternal(this.props.location.pathname);

    return name ? name : 'Dashboard';
  }

  openSidebar() {
    document.documentElement.classList.toggle('nav-open');
    this.refs.sidebarToggle.classList.toggle('toggled');
  }

  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: 'white'
      });
    } else {
      this.setState({
        color: 'transparent'
      });
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateColor.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateColor.bind(this));
  }

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      this.refs.sidebarToggle.classList.toggle('toggled');
    }
  }

  onSearch(event) {
    let customEvent = new Event('header-search');
    customEvent.data = event.target.value;
    window.dispatchEvent(customEvent);
  }

  render() {
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <div>
        <Navbar
          color={
            this.props.location.pathname.indexOf('full-screen-maps') !== -1
              ? 'white'
              : this.state.color
          }
          expand="lg"
          className={
            this.props.location.pathname.indexOf('full-screen-maps') !== -1
              ? 'navbar-absolute fixed-top'
              : 'navbar-absolute fixed-top ' +
                (this.state.color === 'transparent'
                  ? 'navbar-transparent '
                  : '')
          }
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-toggle">
                <button
                  type="button"
                  ref="sidebarToggle"
                  className="navbar-toggler"
                  onClick={() => this.openSidebar()}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="/">
                <h4 className="m-0">
                  <Trans>{this.getBrand()}</Trans>
                </h4>
              </NavbarBrand>
            </div>
            <NavbarToggler onClick={this.toggle}>
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </NavbarToggler>
            <Collapse
              isOpen={this.state.isOpen}
              navbar
              className="justify-content-end"
            >
              <Nav navbar>
                <NavItem />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Breadcrumbs />
      </div>
    );
  }
}

export default connect(({ updates }) => ({ updates }))(
  translate('translations-fr')(Header)
);
