import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import Carousel from './components/Carousel';
import Estimation from './Estimation';

class index extends Component {
  constructor(props) {
    super(props);
    this.exampleRef = React.createRef();
    this.state = {};
  }

  upScroll() {
    this.exampleRef.current.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <div className="content m-0 p-0">
          <Row className={'m-0'}>
            <Col xs={12} lg={5} className={'px-0'}>
              <div ref={this.exampleRef} className={'col-form'}>
                <Estimation upScroll={() => this.upScroll()} />
              </div>
            </Col>
            <Col xs={12} lg={7} className={'p-0 d-none d-lg-block'}>
              <Carousel />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(index));
