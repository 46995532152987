import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AlertConfirm,
  Spinner,
  TableCustom,
  Button
} from '../../../components';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import { Link } from 'react-router-dom';
import { loadAllDevis, removeDevis } from '../../../helpers/actions/projects';
import get from 'lodash/get';
import { dateFormatter, moneyFormatter } from '../../../helpers/formatters';
import PreviewModal from './PreviewModal';
import { UserData } from '../../../helpers/api';

class DevisList extends Component {
  state = {
    loading: false,
    devis: [],
    user: null,
    devisPreview: null,
    devisDelete: null
  };

  load() {
    this.setState({ loading: true });

    let { dispatch, project } = this.props;

    dispatch(
      loadAllDevis(project._id, {
        include: ['user'],
        fields: ['title', 'created_at', 'user', 'status', 'base', 'price']
      })
    )
      .then(({ data }) => this.setState({ devis: data, loading: false }))
      .catch(() => this.setState({ loading: false }));
  }

  componentDidMount() {
    this.load();
    UserData()
      .then(({ user }) => {
        this.setState({ user: user._id });
      })
      .catch(err => {});
  }

  remove() {
    this.setState({ loading: true });

    let { dispatch, project } = this.props;

    dispatch(removeDevis(project._id, this.state.devisDelete))
      .then(() => this.setState({ devisDelete: null }, () => this.load()))
      .catch(() => this.setState({ devisDelete: null, loading: false }));
  }

  getDevisData() {
    let { devis } = this.state,
      { project, basePath, t } = this.props;

    return devis.map((d, key) => {
      return {
        className: '',
        data: [
          {
            className: '',
            fixed: true,
            value: (
              <Link to={`/${basePath}/${project._id}/devis/${d._id}/details`}>
                {get(d, 'title', '')}
              </Link>
            )
          },
          {
            className: '',
            fixed: true,
            value: dateFormatter(d.updated_at || d.created_at)
          },
          {
            className: '',
            fixed: false,
            value: get(d.user, 'displayName', '')
          },
          { className: '', fixed: false, value: t(get(d, 'status', '')) },
          {
            className: 'text-center',
            fixed: false,
            value: d.base ? <i className="fa fa fa-check" /> : null
          },
          {
            className: 'text-right',
            fixed: false,
            value: moneyFormatter(get(d, 'price.ttc', 0))
          },
          {
            className: 'text-right',
            fixed: false,
            value: moneyFormatter(
              get(d, 'price.ttc', 0) + get(d, 'price.materials', 0)
            )
          },
          {
            className: 'text-right',
            actions: true,
            fixed: true,
            value: [
              <Link
                key={`details-${key}`}
                id={`details-${key}`}
                className="btn btn-icon ml-1 btn-info btn-sm"
                to={`/${basePath}/${project._id}/devis/${d._id}/details`}
              >
                <i className="fa fa-eye icon-action" />
              </Link>,
              <UncontrolledTooltip
                key={`tooltip-details-${key}`}
                placement="left"
                target={`details-${key}`}
                delay={0}
              >
                <Trans>Details</Trans>
              </UncontrolledTooltip>,
              <UncontrolledDropdown key={`more-${key}`}>
                <DropdownToggle
                  color="info"
                  className="ml-1 btn-sm dropdown-icon"
                  caret
                >
                  <i className="fa fa-ellipsis-h icon-action mr-1" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={() => this.setState({ devisPreview: d._id })}
                  >
                    <Trans>Preview</Trans>
                  </DropdownItem>
                  <DropdownItem>
                    <Link
                      to={`/${basePath}/${project._id}/devis/${d._id}/print`}
                    >
                      <Trans>Print</Trans>
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            ]
          }
        ]
      };
    });
  }

  render() {
    let { loading, devisPreview, devisDelete } = this.state,
      { project, t } = this.props;
    let rows = this.getDevisData();

    return [
      <Card
        key="devisList"
        className={'card-flex'}
        style={{ position: 'relative' }}
      >
        {loading ? <Spinner inside={true} /> : null}
        <CardHeader>
          <h6>
            <Trans>Devis</Trans>
          </h6>
          {project.devis ? (
            <Button
              className="float-right"
              size="sm"
              color={'info'}
              href={`/my-projects/${project._id}/orders`}
            >
              <Trans>Orders</Trans>
            </Button>
          ) : null}
        </CardHeader>
        <CardBody>
          <TableCustom
            minWidth={767}
            className={'devi-item-material-table'}
            accordion={true}
            heads={[
              { value: t('Title') },
              { value: t('Date') },
              { value: t('User') },
              { value: t('Status') },
              { value: t('Base'), className: 'text-center' },
              { value: t('TTC'), className: 'text-right' },
              { value: t('TTC + Materials'), className: 'text-right' },
              { value: '', className: 'text-right' }
            ]}
            rows={rows}
            emptyMessage={t('No devis found')}
          />
        </CardBody>
      </Card>,
      !!devisPreview ? (
        <PreviewModal
          key={'previewModal'}
          show={!!devisPreview}
          project={project}
          devisId={devisPreview}
          dispatch={this.props.dispatch}
          onClose={() => this.setState({ devisPreview: null })}
        />
      ) : null,
      devisDelete ? (
        <AlertConfirm
          key={'alertConfirm'}
          message={'The devis cannot be recovered'}
          onCancel={() => this.setState({ devisDelete: null })}
          onConfirm={() => this.remove()}
        />
      ) : null
    ];
  }
}

export default connect()(translate('translations-fr')(DevisList));
