import Login from '../views/Auth/Login';
import ResetPassword from '../views/Auth/ResetPassword';

const authRoutes = [
  {
    path: '/login',
    name: 'Login',
    short: 'Login',
    mini: 'LP',
    icon: 'users_circle-08',
    component: Login
  },
  {
    path: '/password-reset/',
    name: 'Reset password',
    short: 'Password',
    mini: 'PP',
    icon: 'objects_key-25',
    component: ResetPassword
  },

  {
    redirect: true,
    from: '*',
    pathTo: '/login'
  }
];

export default authRoutes;
