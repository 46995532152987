import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { Button } from 'components';
import sumBy from 'lodash/sumBy';

class QuestionCheckboxNumber extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options
    };
  }

  onChangePiece(index, event) {
    let { options } = this.state;

    if (event.target.checked) {
      options[index].value = 1;
    } else {
      options[index].value = 0;
    }

    this.setState({ options });
  }

  onIncrement(index) {
    let { options } = this.state;
    options[index].value = options[index].value + 1;
    this.setState({ options });
  }

  onDecrement(index) {
    let { options } = this.state;
    options[index].value = options[index].value - 1;
    this.setState({ options });
  }

  isValid() {
    let { options } = this.props,
      { minValid, maxValid } = this.props;
    let total = sumBy(options, 'value');
    return (
      (minValid ? minValid <= total : true) &&
      (maxValid ? total <= maxValid : true)
    );
  }

  confirm() {
    if (this.isValid()) {
      let { options } = this.state;
      this.props.onConfirm(options);
    }
  }

  render() {
    let { options } = this.state;

    return (
      <div>
        <Row>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>{this.props.question}</Trans>
            </h5>
          </Col>
          <Col xs={12}>
            <ListGroup className={'bg-white rounded options-radios-card'}>
              {options.map((option, key) => {
                return (
                  <ListGroupItem key={key}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={option.value > 0}
                          onChange={event => this.onChangePiece(key, event)}
                        />
                        <span className="form-check-sign" />
                        <Trans>{option.label}</Trans>
                      </Label>
                      {option.unique === false ? (
                        <div className={'btn-options-group'}>
                          <Button
                            className={`btn-icon btn-sm ${
                              option.value > 0 ? '' : 'd-none'
                            }`}
                            onClick={() => this.onDecrement(key)}
                          >
                            <i className="fas fa-minus icon-action" />
                          </Button>
                          <span className="mx-1 btn-options-group-value font-weight-bold">
                            {option.value}
                          </span>
                          <Button
                            className={'btn-icon btn-sm'}
                            onClick={() => this.onIncrement(key)}
                          >
                            <i className="fas fa-plus icon-action" />
                          </Button>
                        </div>
                      ) : null}
                    </FormGroup>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </Col>
          <Col xs={12}>
            <Button
              className="float-right"
              color="default"
              onClick={() => this.confirm()}
            >
              <Trans>Confirm</Trans>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

QuestionCheckboxNumber.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object)
};

export default QuestionCheckboxNumber;
