import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import {
  renovationWall,
  renovationFloor,
  renovationCeiling,
  renovationElectrique,
  renovationPlomberie,
  renovationEquipment
} from '../../../variables/estimation';
import QuestionInputOption from '../components/QuestionInputOption';
import QuestionRadio from '../components/QuestionRadio';
import QuestionCheckbox from '../components/QuestionCheckbox';

class Question11 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      piece: this.props.piece,
      index:
        this.props.onePieceType || this.props.piece.piece === 'other' ? 1 : 2,
      lastQuestion: 10
    };
  }

  componentWillReceiveProps(nextProps, prevState) {
    this.setState({
      piece: nextProps.piece,
      index: nextProps.onePieceType || nextProps.piece.piece === 'other' ? 1 : 2
    });
  }

  next(data, nextIndex) {
    let { piece, index, lastQuestion } = this.state;

    if (data && nextIndex) {
      piece = { ...piece, ...data };
      index = nextIndex;
    }

    if (nextIndex === lastQuestion) {
      this.props.onConfirm(piece);
    } else {
      this.setState(ps => ({ ...ps, piece, index }));
      this.props.upScroll();
    }
  }

  addOperation(operations, selected, nextIndex) {
    let { piece } = this.state;
    let result = operations.filter(t => selected.includes(t._id));

    result.forEach(t => {
      piece.operations.push({ ...t, amount: t.unit === 'u' ? 1 : piece.size });
    });

    this.next({ operations: piece.operations }, nextIndex);
  }

  addAddicionalOperations(operations, selected, nextIndex) {
    let { piece } = this.state;
    let result = operations.filter(t => selected.includes(t._id));

    result.forEach(t => {
      piece.additionalOperations.push({
        ...t,
        amount: t.unit === 'u' ? 1 : piece.size
      });
    });

    this.next({ additionalOperations: piece.additionalOperations }, nextIndex);
  }

  render() {
    let { piece, index } = this.state,
      { t } = this.props;
    let plomberie = ['kitchen', 'bathroom', 'fullHousing'].includes(
      piece.piece
    );
    let equipment = ['kitchen', 'bathroom'].includes(piece.piece);

    return (
      <div>
        {index === 1 ? (
          <QuestionInputOption
            question="What is the surface of your room?"
            inputProps={{
              type: 'number',
              placeholder: `${t('Example')}: 40 (m²)`,
              step: 0.1,
              required: 'required'
            }}
            option={{ label: 'Less than 6m²', value: 6 }}
            onConfirm={data => this.next({ size: data }, 2)}
          />
        ) : null}

        {index === 2 ? (
          <QuestionRadio
            question="The coating of the walls (painting or other)"
            options={[
              {
                label: 'My walls are in very good condition',
                value: { wallStatus: 'veryGood' }
              },
              {
                label: 'My walls are in good condition',
                value: { wallStatus: 'good' }
              },
              {
                label: 'My walls are in poor condition',
                value: { wallStatus: 'poor' }
              },
              { label: "I don't know", value: { wallStatus: 'notKnown' } }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, 3)}
          />
        ) : null}

        {index === 3 ? (
          <QuestionRadio
            question="The coating of the ceilings (painting or other)"
            options={[
              {
                label: 'My ceilings are in very good condition',
                value: { ceilingStatus: 'veryGood' }
              },
              {
                label: 'My ceilings are in good condition',
                value: { ceilingStatus: 'good' }
              },
              {
                label: 'My ceilings are in poor condition',
                value: { ceilingStatus: 'poor' }
              },
              { label: "I don't know", value: { ceilingStatus: 'notKnown' } }
            ]}
            onConfirm={(data, nextIndex) => this.next(data, 4)}
          />
        ) : null}

        {index === 4 ? (
          <QuestionCheckbox
            question="For my walls I wish"
            required={true}
            options={renovationWall
              .filter(element => element.wallStatus.includes(piece.wallStatus))
              .map(t => {
                return { label: t.text, value: t._id };
              })}
            onConfirm={data => this.addOperation(renovationWall, data, 5)}
          />
        ) : null}

        {index === 5 ? (
          <QuestionCheckbox
            question="For my floor I wish"
            required={true}
            options={renovationFloor.map(element => {
              return { label: element.text, value: element._id };
            })}
            onConfirm={data => this.addOperation(renovationFloor, data, 6)}
          />
        ) : null}

        {index === 6 ? (
          <QuestionCheckbox
            question="For my ceiling I wish"
            required={true}
            options={renovationCeiling
              .filter(element =>
                element.ceilingStatus.includes(piece.ceilingStatus)
              )
              .map(t => {
                return { label: t.text, value: t._id };
              })}
            onConfirm={data => this.addOperation(renovationCeiling, data, 7)}
          />
        ) : null}

        {index === 7 ? (
          <QuestionCheckbox
            question="For my change the electrical elements of the ceiling piece"
            required={true}
            options={renovationElectrique
              .filter(
                element =>
                  element.pieces.includes(piece.piece) &&
                  element.sizeMin <= piece.size &&
                  piece.size <= element.sizeMax
              )
              .map(t => {
                return { label: t.text, value: t._id };
              })}
            onConfirm={data =>
              this.addOperation(
                renovationElectrique,
                data,
                plomberie ? 8 : equipment ? 9 : 10
              )
            }
          />
        ) : null}

        {index === 8 ? (
          <QuestionCheckbox
            question="If need resume plumbing items"
            required={true}
            options={renovationPlomberie
              .filter(element => element.pieces.includes(piece.piece))
              .map(t => {
                return { label: t.text, value: t._id };
              })}
            onConfirm={data =>
              this.addOperation(renovationPlomberie, data, equipment ? 9 : 10)
            }
          />
        ) : null}

        {index === 9 ? (
          <QuestionCheckbox
            question="For my furniture and equipment"
            required={true}
            options={renovationEquipment
              .filter(element => element.pieces.includes(piece.piece))
              .map(t => {
                return { label: t.text, value: t._id };
              })}
            onConfirm={data =>
              this.addAddicionalOperations(renovationEquipment, data, 10)
            }
          />
        ) : null}
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Question11));
