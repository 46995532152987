import carouselImage1 from 'assets/img/decorationImages/shutterstock_128130017.jpg';
import carouselImage2 from 'assets/img/decorationImages/shutterstock_537965452.jpg';
import carouselImage3 from 'assets/img/decorationImages/shutterstock_549055576.jpg';
import carouselImage4 from 'assets/img/decorationImages/shutterstock_689997859.jpg';
import carouselImage5 from 'assets/img/decorationImages/shutterstock_757818349.jpg';
import carouselImage6 from 'assets/img/decorationImages/shutterstock_1137523439.jpg';

// ##############################
// // // images for carousel in estimation
// #############################
const carouselImages = [
  { src: carouselImage1, caption: '' },
  { src: carouselImage2, caption: '' },
  { src: carouselImage3, caption: '' },
  { src: carouselImage4, caption: '' },
  { src: carouselImage5, caption: '' },
  { src: carouselImage6, caption: '' }
];

// ##############################
// // // estimation types
// #############################
const estimationTypes = [
  {
    label: 'Cuisine',
    icon: 'fas fa-utensils',
    value: {
      propertyType: 'Cuisine',
      estimation: 'kitchen',
      type: 'one-piece',
      completePiece: false,
      priceBase: 200
    },
    nextIndex: 2
  },
  {
    label: 'SDB',
    icon: 'fas fa-bath',
    value: {
      propertyType: 'SDB',
      estimation: 'bathroom',
      type: 'one-piece',
      completePiece: false,
      priceBase: 2500
    },
    nextIndex: 3
  },
  {
    label: 'A unique piece',
    icon: 'fas fa-door-open',
    value: {
      propertyType: 'A unique piece',
      estimation: 'one-piece',
      type: 'one-piece',
      completePiece: true,
      priceBase: 0
    },
    nextIndex: 4
  },
  {
    label: 'A house',
    icon: 'fas fa-home',
    value: {
      propertyType: 'A house',
      estimation: 'house',
      type: 'full',
      completePiece: false,
      priceBase: 0
    },
    nextIndex: 4
  },
  {
    label: 'An apartment',
    icon: 'fas fa-building',
    value: {
      propertyType: 'An apartment',
      estimation: 'apartment',
      type: 'full',
      completePiece: false,
      priceBase: 0
    },
    nextIndex: 4
  },
  {
    label: 'A commercial local',
    icon: 'fas fa-store-alt',
    value: {
      propertyType: 'A commercial local',
      estimation: 'commercial-local',
      type: 'full',
      completePiece: false,
      priceBase: 0
    },
    nextIndex: 4
  },
  {
    label: 'Sans estimation',
    icon: 'fas fa-comment-slash',
    value: {
      propertyType: 'sans estimation',
      estimation: 'without-estimation',
      type: null,
      completePiece: false,
      priceBase: 0
    },
    nextIndex: 5
  }
];

// ##############################
// // // size of pieces estimated according to type of property
// #############################
const sizeEstimation = [
  {
    _id: 'T1_T3',
    text: 'Moins de 50m²',
    size: 50,
    pieces: [
      { piece: 'kitchen', size: 8 },
      { piece: 'bathroom', size: 3 },
      { piece: 'toilet', size: 1 },
      { piece: 'principalBedroom', size: 12 },
      { piece: 'secondaryBedroom', size: 9 },
      { piece: 'livingRoom', size: 21 },
      { piece: 'fullHousing', size: 50 },
      { piece: 'other', size: null }
    ]
  },
  {
    _id: 'T3_T4',
    text: 'Entre 50 et 80m²',
    size: 80,
    pieces: [
      { piece: 'kitchen', size: 8 },
      { piece: 'bathroom', size: 4 },
      { piece: 'toilet', size: 1 },
      { piece: 'principalBedroom', size: 12 },
      { piece: 'secondaryBedroom', size: 9 },
      { piece: 'livingRoom', size: 31 },
      { piece: 'fullHousing', size: 65 },
      { piece: 'other', size: null }
    ]
  },
  {
    _id: 'T5_T6',
    text: 'Entre 80 et 120m²',
    size: 120,
    pieces: [
      { piece: 'kitchen', size: 8 },
      { piece: 'bathroom', size: 3 },
      { piece: 'toilet', size: 1 },
      { piece: 'principalBedroom', size: 12 },
      { piece: 'secondaryBedroom', size: 9 },
      { piece: 'livingRoom', size: 54 },
      { piece: 'fullHousing', size: 100 },
      { piece: 'other', size: null }
    ]
  },
  {
    _id: 'T7',
    text: 'Supérieur à 120m²',
    size: 120,
    pieces: [
      { piece: 'kitchen', size: 8 },
      { piece: 'bathroom', size: 4 },
      { piece: 'toilet', size: 1 },
      { piece: 'principalBedroom', size: 14 },
      { piece: 'secondaryBedroom', size: 10 },
      { piece: 'livingRoom', size: 58 },
      { piece: 'fullHousing', size: 120 },
      { piece: 'other', size: null }
    ]
  }
];

// ##############################
// // // operations - operations for the renovation of walls in a complete piece
// #############################
const renovationWall = [
  {
    _id: 'RMUR001',
    text: 'De la peinture (fourniture comprise)',
    description: '',
    min: 28,
    max: 28,
    unit: 'm²',
    wallStatus: ['veryGood', 'notKnown']
  },
  {
    _id: 'RMUR002',
    text: 'De la peinture (fourniture comprise)',
    description: '',
    min: 35,
    max: 35,
    unit: 'm²',
    wallStatus: ['good']
  },
  {
    _id: 'RMUR003',
    text: 'De la peinture (fourniture comprise)',
    description: '',
    min: 40,
    max: 40,
    unit: 'm²',
    wallStatus: ['poor']
  },
  {
    _id: 'RMUR004',
    text: 'De la toile de verre (fourniture comprise)',
    description: '',
    min: 22,
    max: 31,
    unit: 'm²',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  },
  {
    _id: 'RMUR005',
    text: 'Du carrelage',
    description: '',
    min: 65,
    max: 75,
    unit: 'm²',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  },
  {
    _id: 'RMUR006',
    text: 'Peinture + carrelage',
    description: '',
    min: 65,
    max: 75,
    unit: 'm²',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  },
  {
    _id: 'RMUR007',
    text: 'Je souahite démolir une cloison',
    description: '',
    min: 550,
    max: 950,
    unit: 'u',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  },
  {
    _id: 'RMUR008',
    text: 'Je souahite ajouter une cloison',
    description: '',
    min: 1200,
    max: 1500,
    unit: 'u',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  },
  {
    _id: 'RMUR009',
    text: 'Je souahite instalelr une verrière',
    description: '',
    min: 700,
    max: 900,
    unit: 'u',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  },
  {
    _id: 'RMUR010',
    text: 'Je ne sais pas',
    description: '',
    min: 0,
    max: 0,
    unit: 'u',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  }
];

// ##############################
// // // operations - operations for the renovation of floors in a complete piece
// #############################
const renovationFloor = [
  {
    _id: 'RSOL001',
    text: 'Poser du carrelage',
    description: '',
    min: 55,
    max: 60,
    unit: 'm²'
  },
  {
    _id: 'RSOL002',
    text: 'Poser du parquet en pose collé',
    description: '',
    min: 40,
    max: 50,
    unit: 'm²'
  },
  {
    _id: 'RSOL003',
    text: 'Poser du parquet en pose flottante',
    description: '',
    min: 30,
    max: 35,
    unit: 'm²'
  },
  {
    _id: 'RSOL004',
    text: 'Poser un isolant entre ma chape et mon parquet / carrelage',
    description: '',
    min: 9,
    max: 13,
    unit: 'm²'
  },
  {
    _id: 'RSOL005',
    text: 'Remplacer mes plinthes',
    description: '',
    min: 9,
    max: 14,
    unit: 'm²'
  },
  {
    _id: 'RSOL006',
    text: 'Je ne sais pas',
    description: '',
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - operations for the renovation of ceilings in a complete piece
// #############################
const renovationCeiling = [
  {
    _id: 'RPLAF001',
    text: 'De la peinture',
    description: '',
    min: 35,
    max: 35,
    unit: 'm²',
    ceilingStatus: ['veryGood', 'notKnown']
  },
  {
    _id: 'RPLAF002',
    text: 'De la peinture',
    description: '',
    min: 37,
    max: 37,
    unit: 'm²',
    ceilingStatus: ['good']
  },
  {
    _id: 'RPLAF003',
    text: 'De la peinture',
    description: '',
    min: 45,
    max: 45,
    unit: 'm²',
    ceilingStatus: ['poor']
  },
  {
    _id: 'RPLAF004',
    text: 'De la toile de verre',
    description: '',
    min: 27,
    max: 38,
    unit: 'm²',
    ceilingStatus: ['veryGood', 'good', 'poor', 'notKnown']
  },
  {
    _id: 'RPLAF005',
    text: 'Je ne sais pas',
    description: '',
    min: 0,
    max: 0,
    unit: 'u',
    ceilingStatus: ['veryGood', 'good', 'poor', 'notKnown']
  }
];

// ##############################
// // // operations - operations for the electrique renovation in a complete piece
// #############################
const renovationElectrique = [
  {
    _id: 'RELEC001',
    text: 'Je souhaite uniquement installer quelques prises et interrupteurs',
    description: '',
    pieces: [
      'kitchen',
      'bathroom',
      'toilet',
      'principalBedroom',
      'secondaryBedroom',
      'livingRoom',
      'fullHousing',
      'other'
    ],
    sizeMin: 0,
    sizeMax: 99999999999999999999999,
    min: 75,
    max: 89,
    unit: 'u'
  },
  {
    _id: 'RELEC002',
    text: 'Je souhaite reprendre toute mon installation électrque par pièce',
    description: '',
    pieces: [
      'kitchen',
      'bathroom',
      'toilet',
      'principalBedroom',
      'secondaryBedroom',
      'livingRoom',
      'other'
    ],
    sizeMin: 0,
    sizeMax: 99999999999999999999999,
    min: 1500,
    max: 1900,
    unit: 'u'
  },
  {
    _id: 'RELEC003',
    text:
      "Je souhaite reprendre toute mon installation électrque de l'appartement",
    description: '',
    pieces: ['fullHousing'],
    sizeMin: 0,
    sizeMax: 50,
    min: 6000,
    max: 6000,
    unit: 'u'
  },
  {
    _id: 'RELEC004',
    text:
      "Je souhaite reprendre toute mon installation électrque de l'appartement",
    description: '',
    pieces: ['fullHousing'],
    sizeMin: 51,
    sizeMax: 80,
    min: 8000,
    max: 8000,
    unit: 'u'
  },
  {
    _id: 'RELEC005',
    text:
      "Je souhaite reprendre toute mon installation électrque de l'appartement",
    description: '',
    pieces: ['fullHousing'],
    sizeMin: 81,
    sizeMax: 120,
    min: 9500,
    max: 9500,
    unit: 'u'
  },
  {
    _id: 'RELEC006',
    text:
      "Je souhaite reprendre toute mon installation électrque de l'appartement",
    description: '',
    pieces: ['fullHousing'],
    sizeMin: 121,
    sizeMax: 99999999999999999999999,
    min: 13000,
    max: 13000,
    unit: 'u'
  },
  {
    _id: 'RELEC007',
    text: 'Je ne sais pas',
    description: '',
    pieces: [
      'kitchen',
      'bathroom',
      'toilet',
      'principalBedroom',
      'secondaryBedroom',
      'livingRoom',
      'fullHousing',
      'other'
    ],
    sizeMin: 0,
    sizeMax: 99999999999999999999999,
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - operations for the plomberie renovation in a complete piece
// #############################
const renovationPlomberie = [
  {
    _id: 'RPLOM001',
    text:
      "Je ne connais pas l'état de ma plomberie mais mon immeuble / maison est ancien",
    description: '',
    pieces: ['kitchen', 'bathroom', 'fullHousing'],
    min: 1400,
    max: 1800,
    unit: 'u'
  },
  {
    _id: 'RPLOM002',
    text:
      'Je souhaite faire la plomberie uniquement en lien avec mes nouveaux equipements',
    description: '',
    pieces: ['kitchen', 'bathroom', 'fullHousing'],
    min: 450,
    max: 700,
    unit: 'u'
  },
  {
    _id: 'RPLOM003',
    text:
      'Je souhaite reprendre intégralement la plomberie de mon appartement/maison',
    description: '',
    pieces: ['fullHousing'],
    min: 3500,
    max: 5000,
    unit: 'u'
  },
  {
    _id: 'RPLOM004',
    text: 'Je ne sais pas',
    description: '',
    pieces: ['kitchen', 'bathroom', 'fullHousing'],
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - operations for the equipment renovation in a complete piece
// #############################
const renovationEquipment = [
  {
    _id: 'REQUI001',
    text: 'Je souhaites changer tous les meubles',
    description: '',
    pieces: ['kitchen']
  },
  {
    _id: 'REQUI002',
    text: 'Je souhaite ajouter de nouveaux electroménagers',
    description: '',
    pieces: ['kitchen']
  },
  {
    _id: 'REQUI003',
    text: "Je modifie l'emplacement des arrivées d'eaux et évacuations",
    description: '',
    pieces: ['kitchen']
  },
  {
    _id: 'REQUI004',
    text: "Je souhaites remplacer à l'identique",
    description: '',
    pieces: ['bathroom']
  },
  {
    _id: 'REQUI005',
    text: "Je souhaite remplacer une douche par une baignoire ou l'inverse",
    description: '',
    pieces: ['bathroom']
  },
  {
    _id: 'REQUI006',
    text: 'Je souhaite remplacer les meubles et vasques',
    description: '',
    pieces: ['bathroom']
  },
  {
    _id: 'REQUI007',
    text: 'Je ne sais pas',
    description: '',
    pieces: ['kitchen', 'bathroom']
  }
];

// ##############################
// // // operations - operations for create or edit walls in a no complete piece
// #############################
const wallOperations = [
  {
    _id: 'WALLOP001',
    text: 'Creation de verrière',
    description: 'Installer une verrière',
    min: 700,
    max: 900,
    unit: 'u'
  },
  {
    _id: 'WALLOP002',
    text: 'Démolition/ ajout de cloison',
    description: '',
    min: 1200,
    max: 1500,
    unit: 'u'
  },
  {
    _id: 'WALLOP003',
    text: 'Modification sur mur porteur',
    description: '',
    min: 500,
    max: 700,
    unit: 'u'
  },
  {
    _id: 'WALLOP004',
    text: 'Je ne sais pas',
    description: '',
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - operations for electrique renovation in a no complete piece
// #############################
const electriqueOperations = [
  {
    _id: 'ELECOP001',
    text: 'Mise à la terre des prises',
    description: '',
    min: 4500,
    max: 6000,
    unit: 'u'
  },
  {
    _id: 'ELECOP002',
    text: 'Remplament tableau électrique',
    description: '',
    min: 1300,
    max: 1800,
    unit: 'u'
  },
  {
    _id: 'ELECOP003',
    text: 'Je ne sais pas',
    description: '',
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - operations for plumbing renovation in a no complete piece
// #############################
const plumbingOperations = [
  {
    _id: 'PLUMOP001',
    text: 'Plomberie à reprendre complètement',
    description: '',
    min: 3500,
    max: 5000,
    unit: 'u'
  },
  {
    _id: 'PLUMOP002',
    text: "Fuite d'eau recherche de fuite",
    description: '',
    min: 1400,
    max: 1800,
    unit: 'u'
  },
  {
    _id: 'PLUMOP003',
    text: 'Je ne sais pas',
    description: '',
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - operations for coating floors renovation in a no complete piece
// #############################
const coatingFloors = [
  {
    _id: 'CFLOO001',
    text: 'Carrelage',
    description: '',
    min: 55,
    max: 60,
    unit: 'm²'
  },
  {
    _id: 'CFLOO002',
    text: 'Parquet collé',
    description: '',
    min: 40,
    max: 50,
    unit: 'm²'
  },
  {
    _id: 'CFLOO003',
    text: 'Parquet flottant',
    description: '',
    min: 30,
    max: 35,
    unit: 'm²'
  },
  {
    _id: 'CFLOO004',
    text: 'Lino PVC',
    description: '',
    min: 12,
    max: 15,
    unit: 'm²'
  },
  {
    _id: 'CFLOO005',
    text: 'Je ne sais pas',
    description: '',
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - operations for coating walls renovation in a no complete piece
// #############################
const coatingWalls = [
  {
    _id: 'CWALL001',
    text: 'Peinture',
    description: '',
    wallStatus: ['veryGood', 'notKnown'],
    min: 28,
    max: 28,
    unit: 'm²'
  },
  {
    _id: 'CWALL002',
    text: 'Peinture',
    description: '',
    wallStatus: ['good'],
    min: 35,
    max: 35,
    unit: 'm²'
  },
  {
    _id: 'CWALL003',
    text: 'Peinture',
    description: '',
    wallStatus: ['poor'],
    min: 40,
    max: 40,
    unit: 'm²'
  },
  {
    _id: 'CWALL004',
    text: 'Papier peint',
    description: '',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown'],
    min: 20,
    max: 25,
    unit: 'm²'
  },
  {
    _id: 'CWALL005',
    text: 'Toîle de verre',
    description: '',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown'],
    min: 22,
    max: 31,
    unit: 'm²'
  },
  {
    _id: 'CWALL006',
    text: 'Je ne sais pas',
    description: '',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown'],
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - operations for coating ceilings renovation in a no complete piece
// #############################
const coatingCeilings = [
  {
    _id: 'CCEIL001',
    text: 'Peinture',
    description: '',
    ceilingStatus: ['veryGood', 'notKnown'],
    min: 35,
    max: 35,
    unit: 'm²'
  },
  {
    _id: 'CCEIL002',
    text: 'Peinture',
    description: '',
    ceilingStatus: ['good'],
    min: 37,
    max: 37,
    unit: 'm²'
  },
  {
    _id: 'CCEIL003',
    text: 'Peinture',
    description: '',
    ceilingStatus: ['poor'],
    min: 45,
    max: 45,
    unit: 'm²'
  },
  {
    _id: 'CCEIL004',
    text: 'Toîle de verre',
    description: '',
    ceilingStatus: ['veryGood', 'good', 'poor'],
    min: 27,
    max: 31,
    unit: 'm²'
  },
  {
    _id: 'CCEIL005',
    text: 'Je ne sais pas',
    description: '',
    ceilingStatus: ['veryGood', 'good', 'poor', 'notKnown'],
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - elements to be removed in a bathroom
// #############################
const deleteElements = [
  {
    _id: 'DE001',
    text: 'Suppression douche encastrée',
    description: '',
    min: 117,
    max: 143,
    unit: 'u'
  },
  {
    _id: 'DE002',
    text: 'Suppression cabine douche',
    description: '',
    min: 225.9,
    max: 276.1,
    unit: 'u'
  },
  {
    _id: 'DE003',
    text: 'Suppression de baignoire',
    description: '',
    min: 225.9,
    max: 276.1,
    unit: 'u'
  },
  {
    _id: 'DE004',
    text: 'Suppression de lavabo',
    description: '',
    min: 53.1,
    max: 64.9,
    unit: 'u'
  },
  {
    _id: 'DE005',
    text: 'Suppression meuble de salle bain',
    description: '',
    min: 98.1,
    max: 119.9,
    unit: 'u'
  },
  {
    _id: 'DE006',
    text: 'Suppression WC',
    description: '',
    min: 155.7,
    max: 190.3,
    unit: 'u'
  },
  {
    _id: 'DE007',
    text: 'Suppression WC suspendus',
    description: '',
    min: 155.7,
    max: 190.3,
    unit: 'u'
  },
  {
    _id: 'DE008',
    text: 'Suppression radiateur électrique',
    description: '',
    min: 80.1,
    max: 97.9,
    unit: 'u'
  },
  {
    _id: 'DE008',
    text: 'Suppression radiateur à eau',
    description: '',
    min: 124.2,
    max: 151.8,
    unit: 'u'
  }
];

// ##############################
// // // operations - floors to be removed in a bathroom
// #############################
const deleteFloors = [
  {
    _id: 'DF001',
    text: 'Carrelage',
    description: '',
    min: 224.2,
    max: 273.9,
    unit: 'u'
  },
  {
    _id: 'DF002',
    text: 'Parquet',
    description: '',
    min: 134.1,
    max: 163.9,
    unit: 'u'
  },
  {
    _id: 'DF003',
    text: 'Lino / PVC',
    description: '',
    min: 107.1,
    max: 130.9,
    unit: 'u'
  }
];

// ##############################
// // // operations - floors to add in a bathroom
// #############################
const addFloors = [
  {
    _id: 'AF001',
    text: 'Lino / PVC',
    description: '',
    min: 296.1,
    max: 361.9,
    unit: 'u'
  },
  {
    _id: 'AF002',
    text: 'Carrelage',
    description: '',
    min: 476.1,
    max: 581.9,
    unit: 'u'
  },
  {
    _id: 'AF003',
    text: 'Parquet',
    description: '',
    min: 476.1,
    max: 581.9,
    unit: 'u'
  }
];

// ##############################
// // // operations - coverings to be removed in a bathroom
// #############################
const deleteCoverings = [
  {
    _id: 'DC001',
    text: 'Carrelage',
    description: '',
    min: 296.1,
    max: 361.9,
    unit: 'u'
  },
  {
    _id: 'DC002',
    text: 'Peinture + Carrelage',
    description: '',
    min: 314.1,
    max: 383.9,
    unit: 'u'
  },
  {
    _id: 'DC003',
    text: 'Peinture',
    description: '',
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - coverings to add in a bathroom
// #############################
const addCoverings = [
  {
    _id: 'AC001',
    text: 'Carrelage',
    description: '',
    min: 764.1,
    max: 933.9,
    unit: 'u',
    wallStatus: ['veryGood', 'good', 'poor']
  },
  {
    _id: 'AC002',
    text: 'Peinture + Carrelage',
    description: '',
    min: 495,
    max: 605,
    unit: 'u',
    wallStatus: ['veryGood', 'good', 'poor']
  },
  {
    _id: 'AC003',
    text: 'Peinture',
    description: '',
    min: 440.1,
    max: 537.9,
    unit: 'u',
    wallStatus: ['veryGood']
  },
  {
    _id: 'AC004',
    text: 'Peinture',
    description: '',
    min: 440.1,
    max: 537.9,
    unit: 'u',
    wallStatus: ['good']
  },
  {
    _id: 'AC005',
    text: 'Peinture',
    description: '',
    min: 440.1,
    max: 537.9,
    unit: 'u',
    wallStatus: ['poor']
  }
];

// ##############################
// // // operations - repaint your ceilings in a bathroom
// #############################
const repaintCeilings = [
  {
    _id: 'RC001',
    text: 'Peinture',
    description: '',
    min: 107.1,
    max: 130.9,
    unit: 'u',
    ceilingStatus: ['veryGood']
  },
  {
    _id: 'RC002',
    text: 'Peinture',
    description: '',
    min: 170.1,
    max: 207.9,
    unit: 'u',
    ceilingStatus: ['good']
  },
  {
    _id: 'RC003',
    text: 'Peinture',
    description: '',
    min: 233.1,
    max: 284.9,
    unit: 'u',
    ceilingStatus: ['poor']
  }
];

// ##############################
// // // operations - demolish or open a partition or wall in a bathroom
// #############################
const demolishWall = [
  {
    _id: 'DW001',
    text: 'Démolir ou ouvrir une cloison ou un mur',
    description: '',
    min: 80.1,
    max: 97.9,
    unit: 'm²'
  }
];

// ##############################
// // // operations - elements to be add in a bathroom
// #############################
const addElements = [
  {
    _id: 'AE001',
    text: 'Cabine de douche',
    description: '',
    min: 337.5,
    max: 412.5,
    unit: 'u'
  },
  {
    _id: 'AE002',
    text: 'Douche large vitrée',
    description: '',
    min: 427.5,
    max: 522.5,
    unit: 'u'
  },
  {
    _id: 'AE003',
    text: "Douche à l'italiene",
    description: '',
    min: 1080,
    max: 1320,
    unit: 'u'
  },
  {
    _id: 'AE004',
    text: 'Baignoire',
    description: '',
    min: 367.2,
    max: 448.8,
    unit: 'u'
  },
  {
    _id: 'AE005',
    text: "Baignoire d'angle",
    description: '',
    min: 585,
    max: 715,
    unit: 'u'
  },
  {
    _id: 'AE006',
    text: 'Baignoire Balnéo',
    description: '',
    min: 990,
    max: 1210,
    unit: 'u'
  },
  {
    _id: 'AE007',
    text: 'Lavabo posé',
    description: '',
    min: 233.1,
    max: 284.9,
    unit: 'u'
  },
  {
    _id: 'AE008',
    text: 'Lavabo suspendu',
    description: '',
    min: 323.1,
    max: 394.9,
    unit: 'u'
  },
  {
    _id: 'AE009',
    text: 'Lavabo double vasque',
    description: '',
    min: 323.1,
    max: 394.9,
    unit: 'u'
  },
  {
    _id: 'AE010',
    text: 'WC à poser',
    description: '',
    min: 323.1,
    max: 394.9,
    unit: 'u'
  },
  {
    _id: 'AE011',
    text: 'WC suspendu',
    description: '',
    min: 503.1,
    max: 614.9,
    unit: 'u'
  },
  {
    _id: 'AE012',
    text: 'Sèche serviette',
    description: '',
    min: 112.5,
    max: 137.5,
    unit: 'u'
  }
];

// ##############################
// // // operations - review the ventilation in a bathroom
// #############################
const reviewVentilation = [
  {
    _id: 'RV001',
    text: 'Je souhaite seulement nettoyer ma ventilation',
    description: '',
    min: 80.1,
    max: 97.9,
    unit: 'u'
  },
  {
    _id: 'RV002',
    text: 'Poser une nouvelle ventilation',
    description: '',
    min: 170.1,
    max: 207.9,
    unit: 'u'
  },
  {
    _id: 'RV003',
    text: 'On ne touche pas à la ventilation',
    description: '',
    min: 0,
    max: 0,
    unit: 'u'
  }
];

// ##############################
// // // operations - change electrique installation in a bathroom
// #############################
const changeElectricalInstallation = [
  {
    _id: 'CEI001',
    text: 'Prises électrique',
    description: '',
    min: 112.32,
    max: 137.28,
    unit: 'u',
    unique: false
  },
  {
    _id: 'CIE002',
    text: 'Éclairage',
    description: '',
    min: 112.32,
    max: 137.28,
    unit: 'u',
    unique: false
  },
  {
    _id: 'CIE003',
    text: 'Alimentation machine à laver',
    description: '',
    min: 112.32,
    max: 137.28,
    unit: 'u',
    unique: true
  }
];

// ##############################
// // // levels
// #############################
const levels = [
  {
    _id: 'level1',
    text:
      'Niveau 1: Je vous fais totalement confiance sur le haut niveau de finitions',
    type: 'discount',
    percent: 10
  },
  {
    _id: 'level2',
    text:
      'Niveau 2: Je vous fais confiance pour me proposer le meilleur rapport qualité prix conforme à mon niveau d’exigence',
    type: 'neutral',
    percent: 0
  },
  {
    _id: 'level3',
    text:
      'Niveau 3: Je vous fais confiance pour me proposer le meilleur prix et  j’envisage de participer à la réalisation des finitions',
    type: 'increase',
    percent: 20
  }
];

// ##############################
// // // prices packages
// #############################
const packages = [
  {
    piece: 'fullHousing',
    min: 650,
    max: 800,
    unit: 'm²'
  },
  {
    piece: 'kitchen',
    min: 1000,
    max: 1500,
    unit: 'u'
  },
  {
    piece: 'bathroom',
    min: 5200,
    max: 7300,
    unit: 'u'
  },
  {
    piece: 'toilet',
    min: 950,
    max: 1200,
    unit: 'u'
  }
];

// ********************** Kitchen **********************

// ##############################
// // // operations - special operations in a kitchen
// #############################
const specialKitchen = [
  {
    _id: 'OSK001',
    text: 'Démonter une cuisine équipée (et ses meubles) exigeantes - Oui',
    description: '',
    min: 80.1,
    max: 97.9,
    unit: 'u'
  },
  {
    _id: 'OSK002',
    text: 'Démolir modifier ou ouvrir un cloison ou un mur - Oui',
    description: '',
    min: 71.1,
    max: 86.9,
    unit: 'u'
  },
  {
    _id: 'OSK003',
    text: 'Modifier-rénover le revêtement au sol - Oui',
    description: '',
    min: 71.1,
    max: 86.9,
    unit: 'u'
  }
];

// ##############################
// // // operations - disassemble kitchen
// #############################
const disassembleKitchen = [
  {
    _id: 'DK001',
    text: 'Démonter une cuisine équipée (et ses meubles) exigeantes',
    description: '',
    min: 62.1,
    max: 75.9,
    unit: 'm²'
  }
];

// ##############################
// // // operations - edit walls in a kitchen
// #############################
const editWallsKitchen = [
  {
    _id: 'EWK001',
    text: 'Démolir une cloison',
    description: '',
    min: 62.1,
    max: 75.9,
    unit: 'm²'
  },
  {
    _id: 'EWK002',
    text: 'Construire une cloison',
    description: '',
    min: 108,
    max: 132,
    unit: 'm²'
  },
  {
    _id: 'EWK003',
    text: 'Poser une verriere',
    description: '',
    min: 540,
    max: 660,
    unit: 'm²'
  }
];

// ##############################
// // // operations - floors to be removed in a kitchen
// #############################
const deleteFloorsKitchen = [
  {
    _id: 'DFK001',
    text: 'Carrelage',
    description: '',
    min: 314.1,
    max: 383.9,
    unit: 'u'
  },
  {
    _id: 'DFK002',
    text: 'Parquet',
    description: '',
    min: 134.1,
    max: 163.9,
    unit: 'u'
  },
  {
    _id: 'DFK003',
    text: 'Parquet flotant / Lino / PVC / Moquette',
    description: '',
    min: 107.1,
    max: 130.9,
    unit: 'u'
  },
  {
    _id: 'DFK004',
    text: 'Autre',
    description: '',
    min: 314.1,
    max: 383.9,
    unit: 'u'
  }
];

// ##############################
// // // operations - floors to be added in a kitchen
// #############################
const addFloorsKitchen = [
  {
    _id: 'DFK001',
    text: 'Carrelage',
    description: '',
    min: 476.1,
    max: 581.9,
    unit: 'u'
  },
  {
    _id: 'DFK002',
    text: 'Parquet',
    description: '',
    min: 476.1,
    max: 581.9,
    unit: 'u'
  },
  {
    _id: 'DFK003',
    text: 'Parquet flotant / Lino / PVC',
    description: '',
    min: 296.1,
    max: 361.9,
    unit: 'u'
  }
];

// ##############################
// // // operations - coverings to remove in a kitchen
// #############################
const deleteCoveringsKitchen = [
  {
    _id: 'DCK001',
    text: 'Carrelage',
    description: '',
    min: 296.1,
    max: 361.9,
    unit: 'u'
  },
  {
    _id: 'DCK002',
    text: 'Peinture',
    description: '',
    min: 0,
    max: 0,
    unit: 'u'
  },
  {
    _id: 'DCK003',
    text: 'Paipier peint',
    description: '',
    min: 225,
    max: 275,
    unit: 'u'
  }
];

// ##############################
// // // operations - coverings to add in a kitchen
// #############################
const addCoveringsKitchen = [
  {
    _id: 'ACK001',
    text: 'Carrelage',
    description: '',
    min: 764.1,
    max: 933.9,
    unit: 'u',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  },
  {
    _id: 'ACK002',
    text: 'Peinture',
    description: '',
    min: 440.1,
    max: 537.9,
    unit: 'u',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  },
  {
    _id: 'ACK003',
    text: 'Mix peinture + Carrelage',
    description: '',
    min: 495,
    max: 605,
    unit: 'u',
    wallStatus: ['veryGood', 'good', 'poor', 'notKnown']
  }
];

// ##############################
// // // operations - repaint your ceilings in a kitchen
// #############################
const repaintCeilingsKitchen = [
  {
    _id: 'RCK001',
    text: 'Peinture',
    description: '',
    min: 107.1,
    max: 130.9,
    unit: 'u',
    ceilingStatus: ['veryGood']
  },
  {
    _id: 'RCK002',
    text: 'Peinture',
    description: '',
    min: 170.1,
    max: 207.9,
    unit: 'u',
    ceilingStatus: ['good']
  },
  {
    _id: 'RCK003',
    text: 'Peinture',
    description: '',
    min: 233.1,
    max: 284.9,
    unit: 'u',
    ceilingStatus: ['poor']
  },
  {
    _id: 'RCK004',
    text: 'Peinture',
    description: '',
    min: 233.1,
    max: 284.9,
    unit: 'u',
    ceilingStatus: ['notKnown']
  }
];

// ##############################
// // // operations - level of range in a kitchen
// #############################
const levelKitchen = [
  {
    _id: 'LK001',
    text: 'Entrée de gamme (entrée 2000€ et 5000€)',
    description: '',
    min: 190,
    max: 220,
    unit: 'm²'
  },
  {
    _id: 'LK002',
    text: 'Confort (5000€ et 10000€)',
    description: '',
    min: 220,
    max: 253,
    unit: 'm²'
  },
  {
    _id: 'LK003',
    text: 'Confort + (10000€ et 15000€)',
    description: '',
    min: 280,
    max: 322,
    unit: 'm²'
  },
  {
    _id: 'LK004',
    text: 'Haut de gamme + (+ de 15000€)',
    description: '',
    min: 342,
    max: 418,
    unit: 'm²'
  }
];

// ##############################
// // // operations - needs in appliances in a kitchen
// #############################
const appliances = [
  {
    _id: 'AEK001',
    text: 'Four',
    description: '',
    min: 75.6,
    max: 92.4,
    unit: 'u',
    unique: true
  },
  {
    _id: 'AEK002',
    text: 'Plaque de cuisson',
    description: '',
    min: 75.6,
    max: 92.4,
    unit: 'u',
    unique: true
  },
  {
    _id: 'AEK003',
    text: 'Gaziniere',
    description: '',
    min: 75.6,
    max: 92.4,
    unit: 'u',
    unique: true
  },
  {
    _id: 'AEK004',
    text: 'Hotte',
    description: '',
    min: 35.6,
    max: 52.4,
    unit: 'u',
    unique: true
  },
  {
    _id: 'AEK005',
    text: 'Réfrigérataur',
    description: '',
    min: 18.9,
    max: 23.1,
    unit: 'u',
    unique: true
  },
  {
    _id: 'AEK006',
    text: 'Lave-vaiselle',
    description: '',
    min: 18.9,
    max: 23.1,
    unit: 'u',
    unique: true
  },
  {
    _id: 'AEK007',
    text: 'Micro-onde',
    description: '',
    min: 18.9,
    max: 23.1,
    unit: 'u',
    unique: true
  },
  {
    _id: 'AEK008',
    text: 'Autre (LL, ...)',
    description: '',
    min: 18.9,
    max: 23.1,
    unit: 'u',
    unique: false
  }
];

// ##############################
// // // operations - special elements in a kitchen
// #############################
const specialElements = [
  {
    _id: 'SEK001',
    text: 'Plan de travail sur mesure ou plan de travail à carreler',
    description: '',
    min: 80.1,
    max: 97.9,
    unit: 'm²'
  },
  {
    _id: 'SEK002',
    text: 'Crédence carrelage',
    description: '',
    min: 65.7,
    max: 80.3,
    unit: 'm²'
  },
  {
    _id: 'SEK003',
    text: 'Crédence immitation bois',
    description: '',
    min: 37.8,
    max: 46.2,
    unit: 'm²'
  },
  {
    _id: 'SEK004',
    text: 'Crédence sur mesure Verre / Inox / Quartz',
    description: '',
    min: 71.1,
    max: 86.9,
    unit: 'm²'
  },
  {
    _id: 'SEK005',
    text: 'Fond de hotte',
    description: '',
    min: 71.1,
    max: 86.9,
    unit: 'u'
  },
  {
    _id: 'SEK006',
    text: 'Plan Snack',
    description: '',
    min: 134.1,
    max: 163.9,
    unit: 'u'
  }
];

// ##############################
// // // operations - change electrique installation in a kitchen
// #############################
const electricalInstallationKitchen = [
  {
    _id: 'EIK001',
    text: 'Prises électromager Four / Plaquet / Hotte / LV / LL / Mo',
    description: '',
    min: 117,
    max: 143,
    unit: 'u',
    unique: false
  },
  {
    _id: 'EIK002',
    text: 'Prises électrique sur plan de travail',
    description: '',
    min: 144,
    max: 176,
    unit: 'u',
    unique: false
  },
  {
    _id: 'EIK003',
    text: 'Éclairage',
    description: '',
    min: 135,
    max: 165,
    unit: 'u',
    unique: true
  },
  {
    _id: 'EIK004',
    text: 'Déplacer un radiateur électrique',
    description: '',
    min: 168,
    max: 198,
    unit: 'u',
    unique: true
  },
  {
    _id: 'EIK004',
    text: 'Je ne sais pas',
    description: '',
    min: 126,
    max: 154,
    unit: 'u',
    unique: true
  }
];

// ##############################
// // // operations - change plumbing installation in a kitchen
// #############################
const plumbingInstallationKitchen = [
  {
    _id: 'PIK001',
    text: 'Alimentation / Evacuation évier',
    description: '',
    min: 215,
    max: 285,
    unit: 'u',
    unique: false
  },
  {
    _id: 'PIK002',
    text: 'Alimentation / Evacuation Lave vaiselle / Lave ligne / Frigo',
    description: '',
    min: 247.5,
    max: 302.5,
    unit: 'u',
    unique: false
  },
  {
    _id: 'PIK003',
    text: 'Déplacer un radiateur à eau',
    description: '',
    min: 585,
    max: 715,
    unit: 'u',
    unique: true
  },
  {
    _id: 'PIK004',
    text: 'Remplacement ballon eau chaude',
    description: '',
    min: 565,
    max: 735,
    unit: 'u',
    unique: true
  },
  {
    _id: 'PIK005',
    text: 'Je ne sais pas',
    description: '',
    min: 126,
    max: 154,
    unit: 'u',
    unique: true
  }
];

// ##############################
// // // materials price - multiple for the price with materials
// #############################
const materialPrice = [
  {
    estimation: 'kitchen',
    multiple: 2.3
  },
  {
    estimation: 'bathroom',
    multiple: 1.33
  },
  {
    estimation: 'one-piece',
    multiple: 1.33
  },
  {
    estimation: 'house',
    multiple: 1.33
  },
  {
    estimation: 'apartment',
    multiple: 1.33
  },
  {
    estimation: 'commercial-local',
    multiple: 1.33
  },
  {
    estimation: 'without-estimation',
    multiple: 1.33
  }
];

export {
  carouselImages,
  estimationTypes,
  sizeEstimation,
  renovationWall,
  renovationFloor,
  renovationCeiling,
  renovationElectrique,
  renovationPlomberie,
  renovationEquipment,
  wallOperations,
  electriqueOperations,
  plumbingOperations,
  coatingFloors,
  coatingWalls,
  coatingCeilings,
  deleteElements,
  deleteFloors,
  addFloors,
  deleteCoverings,
  addCoverings,
  repaintCeilings,
  demolishWall,
  addElements,
  reviewVentilation,
  changeElectricalInstallation,
  levels,
  packages,
  specialKitchen,
  disassembleKitchen,
  editWallsKitchen,
  deleteFloorsKitchen,
  addFloorsKitchen,
  deleteCoveringsKitchen,
  addCoveringsKitchen,
  repaintCeilingsKitchen,
  levelKitchen,
  appliances,
  specialElements,
  electricalInstallationKitchen,
  plumbingInstallationKitchen,
  materialPrice
};
