import Dashboard from '../layouts/Dashboard/Dashboard.jsx';
import Auth from '../layouts/Auth/Auth';
import Sale from '../layouts/Sale/Sale';

const indexRoutes = [
  { path: '/login', name: 'Login', component: Auth },
  { path: '/register', name: 'Register', component: Auth },
  { path: '/password-reset', name: 'Reset Password', component: Auth },
  { path: '/estimation', name: 'Estimation', component: Sale },
  { path: '/', name: 'Home', component: Dashboard }
];

export default indexRoutes;
