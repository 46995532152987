import React from 'react';
import { Trans } from 'react-i18next';
import { Input, Col, Row } from 'reactstrap';
import { Button } from 'components';

class QuestionInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.default
    };
  }

  isValid() {
    let inputElement = this.inputElement;
    return inputElement.validity.valid;
    // let { value } = this.state;
    // return !!value;
  }

  confirm() {
    if (this.isValid()) {
      let { value } = this.state;
      this.props.onConfirm(value);
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>{this.props.question}</Trans>
            </h5>
          </Col>
          <Col xs={12}>
            <Input
              innerRef={node => (this.inputElement = node)}
              className="p-3 mb-2 bg-white rounded options-radios-card"
              {...this.props.inputProps}
              value={this.state.value || ''}
              onChange={event => this.setState({ value: event.target.value })}
            />
          </Col>
          <Col xs={12}>
            <Button
              className="float-right"
              color="default"
              onClick={() => this.confirm()}
            >
              <Trans>Confirm</Trans>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

QuestionInput.propTypes = {};

export default QuestionInput;
