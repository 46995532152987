import {
  baseUrl,
  createClient,
  createQueryString,
  get,
  getToken,
  post,
  postForm,
  put,
  del
} from './util';

const client = createClient('projects', {
  documents: null,
  events: null,
  maps: null,
  medias: null,
  notes: null,
  operations: null,
  payments: null,
  plans: null,
  visits: null,
  devis: null
});

/************ PROJECTS ************/
client.updateStatus = (id, data) =>
  post(`${baseUrl}/projects/${id}/status`, data);

client.history = id => get(`${baseUrl}/projects/${id}/history`);

client.postulate = id => post(`${baseUrl}/projects/${id}/postulate`);

client.postulatedUsers = id => get(`${baseUrl}/projects/${id}/postulates`);

client.market = params =>
  get(`${baseUrl}/projects/market${createQueryString(params)}`);

client.statuses = () => get(`${baseUrl}/projects/status`);

client.location = params =>
  get(`${baseUrl}/projects/location${createQueryString(params)}`);

client.updateSupervisors = (pid, data) =>
  post(`${baseUrl}/projects/${pid}/supervisors`, data);

client.updateSellers = (pid, data) =>
  post(`${baseUrl}/projects/${pid}/sellers`, data);

client.chatNotification = (pid, text) =>
  get(
    `${baseUrl}/projects/${pid}/chatNotification${createQueryString({ text })}`
  );

client.reportProjectsStatusUser = params =>
  get(`${baseUrl}/reports/projects/status${createQueryString(params)}`);

client.report = (id, data) => post(`${baseUrl}/reports/project/${id}`, data);

client.sendReport = (id, docId) =>
  get(`${baseUrl}/reports/project/${id}/send/${docId}`);

client.children = params =>
  get(`${baseUrl}/projects/children${createQueryString(params)}`);

client.script = params =>
  get(`${baseUrl}/projectsScript/script${createQueryString(params)}`);
/************ PROJECTS ************/

/************ PROJECTS  DOCUMENTS************/
client.sendDocByEmail = (pid, id, data) =>
  post(`${baseUrl}/projects/${pid}/documents/${id}/send`, data);
/************ PROJECTS  DOCUMENTS************/

/************ PROJECTS  EVENTS************/
client.allEvents = params =>
  get(`${baseUrl}/projects/events${createQueryString(params)}`);

client.sendEventByEmail = (pid, id, data) =>
  post(`${baseUrl}/projects/${pid}/events/${id}/send`, data);
/************ PROJECTS  EVENTS************/

/************ PROJECTS  MAPS************/
/************ PROJECTS  MAPS************/

/************ PROJECTS  MEDIAS************/
/************ PROJECTS  MEDIAS************/

/************ PROJECTS  NOTES************/
client.allNotes = params =>
  get(`${baseUrl}/projects/notes${createQueryString(params)}`);

client.updateAllNotes = () => get(`${baseUrl}/projects/updateallnotes`);

client.sendNoteByEmail = (pid, id, data) =>
  post(`${baseUrl}/projects/${pid}/notes/${id}/send`, data);

client.addNoteMedia = (pid, id, data) =>
  postForm(`${baseUrl}/projects/${pid}/notes/${id}/images`, data);

client.deleteNoteMedia = (pid, nid, id) =>
  get(`${baseUrl}/projects/${pid}/notes/${nid}/images/${id}`);

client.updateNoteMedia = (pid, nid, data) =>
  put(`${baseUrl}/projects/${pid}/notes/${nid}/images/${data._id}`, data);
/************ PROJECTS  NOTES************/

/************ PROJECTS  OPERATIONS************/
client.createOperations = (id, data) =>
  post(`${baseUrl}/projects/${id}/operations/many`, data);

client.updateOperationStatus = (pid, id, data) =>
  put(`${baseUrl}/projects/${pid}/operations/${id}/status`, data);

client.estimateOperations = (id, data) =>
  post(`${baseUrl}/projects/${id}/operations/estimation`, data);
/************ PROJECTS  OPERATIONS************/

/************ PROJECTS  PAYMENTS************/
client.paymentPdfBills = (pid, id, data) =>
  post(`${baseUrl}/projects/${pid}/payments/${id}/bills`, data);

client.paymentPdfBill = (pid, id, params) =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/projects/${pid}/payments/${id}/bill${createQueryString(
        params
      )}`,
      httpHeaders: {
        Authorization: 'Bearer ' + token,
        'x-api-role': 'admin'
      },
      withCredentials: false
    })
  );
/************ PROJECTS  PAYMENTS************/

/************ PROJECTS  PLANS************/
/** PROJECTS  PLANS NOTES*/
client.getPlanNotes = (pid, planId) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/notes`);

client.getPlanNote = (pid, planId, id) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/notes/${id}`);

client.addPlanNote = (pid, planId, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/notes`, data);

client.savePlanNote = (pid, planId, id, data) =>
  put(`${baseUrl}/projects/${pid}/plans/${planId}/notes/${id}`, data);

client.deletePlanNote = (pid, planId, id) =>
  del(`${baseUrl}/projects/${pid}/plans/${planId}/notes/${id}`);

client.sendPlanNoteByEmail = (pid, planId, id, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/notes/${id}/send`, data);
/** PROJECTS  PLANS NOTES*/

/** PROJECTS  PLANS TASKS*/
client.getPlanTasks = (pid, planId) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/tasks`);

client.getPlanTask = (pid, planId, id) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/tasks/${id}`);

client.addPlanTask = (pid, planId, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/tasks`, data);

client.savePlanTask = (pid, planId, id, data) =>
  put(`${baseUrl}/projects/${pid}/plans/${planId}/tasks/${id}`, data);

client.deletePlanTask = (pid, planId, id) =>
  del(`${baseUrl}/projects/${pid}/plans/${planId}/tasks/${id}`);

client.sendPlanTaskByEmail = (pid, planId, id, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/tasks/${id}/send`, data);
/** PROJECTS  PLANS TASKS*/

/** PROJECTS  PLANS MEDIAS*/
client.getPlanImages = (pid, planId) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/images`);

client.getPlanImage = (pid, planId, id) =>
  get(`${baseUrl}/projects/${pid}/plans/${planId}/images/${id}`);

client.addPlanImage = (pid, planId, data) =>
  postForm(`${baseUrl}/projects/${pid}/plans/${planId}/images`, data);

client.savePlanImage = (pid, planId, id, data) =>
  put(`${baseUrl}/projects/${pid}/plans/${planId}/images/${id}`, data);

client.deletePlanImage = (pid, planId, id) =>
  del(`${baseUrl}/projects/${pid}/plans/${planId}/images/${id}`);

client.sendPlanImageByEmail = (pid, planId, id, data) =>
  post(`${baseUrl}/projects/${pid}/plans/${planId}/images/${id}/send`, data);
/** PROJECTS  PLANS MEDIAS*/
/************ PROJECTS  PLANS************/

/************ PROJECTS  VISITS************/
client.allVisits = params =>
  get(`${baseUrl}/projects/visits${createQueryString(params)}`);
/************ PROJECTS  VISITS************/

/************ PROJECTS  DEVIS************/
client.countDevisByProject = id => get(`${baseUrl}/projects/${id}/devis/count`);

client.duplicateDevis = (id, did) =>
  get(`${baseUrl}/projects/${id}/devis/duplicate/${did}`);

client.updateDevisMaterials = (id, did, data) =>
  put(`${baseUrl}/projects/${id}/devis/${did}/materials`, data);

client.devisPdfUrl = (id, did) =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/projects/${id}/devis/${did}/pdf`,
      httpHeaders: {
        Authorization: 'Bearer ' + token,
        'x-api-role': 'admin'
      },
      withCredentials: false
    })
  );

client.devisPdfUrlGet = (pid, did, params) =>
  get(
    `${baseUrl}/projects/${pid}/devis/${did}/pdfGet${createQueryString(params)}`
  );

client.processImages = medias =>
  post(`${baseUrl}/images/processImages`, { medias });

client.devisCompoundPdfUrl = id =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/projects/${id}/compound/devis/pdf`,
      httpHeaders: {
        Authorization: 'Bearer ' + token,
        'x-api-role': 'admin'
      },
      withCredentials: false
    })
  );

client.estimateDevis = (id, data) =>
  post(`${baseUrl}/projects/${id}/devis/estimation`, data);

/** PROJECTS  DEVIS DOCUMETS*/
client.allDevisDocuments = (pid, did) =>
  get(`${baseUrl}/projects/${pid}/devis/${did}/documents`);

client.oneDevisDocuments = (pid, did, id) =>
  get(`${baseUrl}/projects/${pid}/devis/${did}/documents/${id}`);

client.createDevisDocuments = (pid, did, data) =>
  postForm(`${baseUrl}/projects/${pid}/devis/${did}/documents`, data);

client.updateDevisDocuments = (pid, did, data) =>
  put(`${baseUrl}/projects/${pid}/devis/${did}/documents/${data._id}`, data);

client.delDevisDocuments = (pid, did, id) =>
  del(`${baseUrl}/projects/${pid}/devis/${did}/documents/${id}`);
/** PROJECTS  DEVIS DOCUMETS*/
/************ PROJECTS  DEVIS************/

/************ PROJECTS  TASKS************/
client.getTasksLists = pid => get(`${baseUrl}/projects/${pid}/tasksLists`);

client.getTasksList = (pid, id) =>
  get(`${baseUrl}/projects/${pid}/tasksLists/${id}`);

client.saveTasksList = (pid, data) =>
  data._id
    ? put(`${baseUrl}/projects/${pid}/tasksLists/${data._id}`, data)
    : post(`${baseUrl}/projects/${pid}/tasksLists`, data);

client.deleteTasksList = (pid, id) =>
  del(`${baseUrl}/projects/${pid}/tasksLists/${id}`);

client.saveTask = (pid, listId, data) =>
  data._id
    ? put(
        `${baseUrl}/projects/${pid}/tasksLists/${listId}/tasks/${data._id}`,
        data
      )
    : post(`${baseUrl}/projects/${pid}/tasksLists/${listId}/tasks`, data);

client.deleteTask = (pid, listId, id) =>
  del(`${baseUrl}/projects/${pid}/tasksLists/${listId}/tasks/${id}`);
/************ PROJECTS  TASKS************/

export default client;
