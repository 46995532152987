import React, { Component } from 'react';
import connect from 'react-redux/es/connect/connect';
import { Trans, translate } from 'react-i18next';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Button
} from 'reactstrap';
import { Spinner } from '../../../components/index';
import Select from 'react-select';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment/locale/fr';
import get from 'lodash/get';
import defaultImage from '../../../assets/img/image_placeholder.jpg';
import { all as allPieces } from '../../../helpers/actions/pieces';
import { all as allSurfaces } from '../../../helpers/actions/surfaces';
import Gallery from '../../Images/Gallery';
import { orderResponsible, orderStatus } from '../../../helpers/nomenclators';

class MaterialOrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      material: {
        availability_date: moment()
      },
      pieces: [],
      surfaces: [],
      showGallery: false
    };
  }

  componentWillMount() {
    let { projectType, dispatch } = this.props;
    this.setState({ loading: true });

    let type = projectType === 'compound-son' ? 'compound' : projectType;

    Promise.all([
      dispatch(allPieces({ type })),
      dispatch(allSurfaces({ type }))
    ])
      .then(([pieces, surfaces]) => {
        console.log(pieces);
        this.setState(ps => ({
          ...ps,
          pieces: pieces.data,
          surfaces: surfaces.data,
          loading: false
        }));
      })
      .catch(() => this.setState({ loading: false }));
  }

  closeModal() {
    this.props.onCancel();
  }

  onChange(name, nameValid, value, valid) {
    let { material } = this.state;

    this.setState({
      material: { ...material, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let { material } = this.state;
    let name = this.name;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      groupValid: material.group ? 'has-success' : 'has-danger',
      pieceValid: material.piece ? 'has-success' : 'has-danger'
    });

    return name.validity.valid && material.group && material.piece;
  }

  onSaveMaterial() {
    let { material } = this.state;
    if (this.validate()) {
      this.props.onConfirm(material);
    }
  }

  render() {
    let { loading, material, showGallery, pieces, surfaces } = this.state,
      { t } = this.props;

    return (
      <Modal size="lg" isOpen={true} toggle={() => this.closeModal()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.closeModal()}
        >
          <Trans>New Material</Trans>
        </ModalHeader>
        <ModalBody>
          {loading ? <Spinner /> : null}

          {material ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} md={6}>
                  <Row>
                    <Col
                      xs={12}
                      className={'display-flex justify-content-center'}
                    >
                      <div
                        className="avatar-image"
                        style={{
                          backgroundImage: `url('${get(
                            material,
                            'image',
                            defaultImage
                          )}')`
                        }}
                      />
                    </Col>
                    <Col
                      xs={12}
                      className={'display-flex justify-content-center'}
                    >
                      <Button
                        color="info"
                        onClick={() => this.setState({ showGallery: true })}
                      >
                        <Trans>Gallery</Trans>
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' + this.state.nameValid
                        }
                      >
                        <Label>
                          <Trans>Name</Trans>
                        </Label>
                        <Input
                          type="text"
                          innerRef={node => (this.name = node)}
                          namevalid="nameValid"
                          value={material.name || ''}
                          required="required"
                          onChange={event =>
                            this.onChange(
                              'name',
                              'nameValid',
                              event.target.value,
                              event.target.validity.valid
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' +
                          this.state.responsibleValid
                        }
                      >
                        <Label>Responsible</Label>
                        <Select
                          className="primary"
                          options={orderResponsible.map(res => ({
                            label: t(res),
                            value: res
                          }))}
                          value={material.responsible}
                          onChange={event =>
                            this.onChange(
                              'responsible',
                              'responsibleValid',
                              event ? event.value : null,
                              true
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup
                        className={
                          'has-label form-validation-40 ' +
                          this.state.availabilityDateValid
                        }
                      >
                        <Label>Availability Date</Label>
                        <Datetime
                          locale={'fr'}
                          value={moment(material.availability_date)}
                          inputProps={{
                            readOnly: true
                          }}
                          onChange={moment =>
                            this.onChange(
                              'availability_date',
                              'availabilityDateValid',
                              moment,
                              true
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.pieceValid
                    }
                  >
                    <Label>
                      <Trans>Piece</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={pieces.map(t => ({
                        label: t.name,
                        value: t.name
                      }))}
                      value={material.piece}
                      onChange={event =>
                        this.onChange(
                          'piece',
                          'pieceValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.groupValid
                    }
                  >
                    <Label>
                      <Trans>Surface</Trans>
                    </Label>
                    <Select
                      className="primary"
                      options={surfaces.map(t => ({
                        label: t.name,
                        value: t.name
                      }))}
                      value={material.group}
                      onChange={event =>
                        this.onChange(
                          'group',
                          'groupValid',
                          event ? event.value : null,
                          !!event
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.unitPriceValid
                    }
                  >
                    <Label>Unit Price</Label>
                    <Input
                      type="number"
                      innerRef={node => (this.unitPrice = node)}
                      namevalid="unitPriceValid"
                      value={material.unit_price || ''}
                      required="required"
                      step={0.01}
                      min={0.01}
                      onChange={event =>
                        this.onChange(
                          'unit_price',
                          'unitPriceValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.cantReceivedValid
                    }
                  >
                    <Label>Received Amount</Label>
                    <Input
                      type="number"
                      innerRef={node => (this.cantReceived = node)}
                      namevalid="unitPriceValid"
                      value={material.cant_received || ''}
                      required="required"
                      step={0.01}
                      min={0.01}
                      onChange={event =>
                        this.onChange(
                          'cant_received',
                          'cantReceivedValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.statusValid
                    }
                  >
                    <Label>Status</Label>
                    <Select
                      className="primary"
                      options={orderStatus.map(res => ({
                        label: t(res),
                        value: res
                      }))}
                      value={material.status}
                      onChange={event =>
                        this.onChange(
                          'status',
                          'statusValid',
                          event ? event.value : null,
                          true
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={6}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.codeValid
                    }
                  >
                    <Label>Code</Label>
                    <Input
                      type="number"
                      innerRef={node => (this.code = node)}
                      namevalid="codeValid"
                      value={material.code || ''}
                      required="required"
                      step={1}
                      min={0}
                      onChange={event =>
                        this.onChange(
                          'code',
                          'codeValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <hr />
            </Form>
          ) : null}

          {showGallery ? (
            <Gallery
              type={'material'}
              selectImage={url =>
                this.setState(prevState => {
                  return {
                    ...prevState,
                    showGallery: false,
                    material: {
                      ...prevState.material,
                      image: url
                    }
                  };
                })
              }
              closeGallery={() =>
                this.setState(prevState => {
                  return { ...prevState, showGallery: false };
                })
              }
            />
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button color="info" onClick={() => this.onSaveMaterial()}>
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(MaterialOrderModal));
