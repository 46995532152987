import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { Spinner, TableCustom } from '../../../components';
import { loadDocuments } from '../../../helpers/actions/projects';

class Map2D extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      documents: [],
      projectId: null,
      sendByEmail: null,
      uploadDoc: false,
      docDelete: null
    };
  }

  componentWillMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });
    let { dispatch, projectId } = this.props;

    dispatch(
      loadDocuments(projectId, { type: JSON.stringify({ $in: ['Plans'] }) })
    )
      .then(documents =>
        this.setState({ documents, loading: false, projectId })
      )
      .catch(() => this.setState({ loading: false }));
  }

  getDocumentsData() {
    let { documents } = this.state,
      { t } = this.props;

    return documents.map(document => {
      return {
        className: '',
        data: [
          {
            className: '',
            value: (
              <a
                href={document.source}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                {document.name}
              </a>
            )
          },
          { className: '', fixed: false, value: t(document.type) }
        ]
      };
    });
  }

  render() {
    let { loading } = this.state,
      { t } = this.props;
    let rows = this.getDocumentsData();

    return (
      <div>
        <Card className={'card-plain'} style={{ position: 'relative' }}>
          {loading ? <Spinner inside={true} /> : null}
          <CardBody>
            <TableCustom
              minWidth={767}
              className={'devi-item-material-table'}
              accordion={true}
              heads={[{ value: t('Name') }, { value: t('Type') }]}
              rows={rows}
              emptyMessage={t('No documents found')}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Map2D));
