import React from 'react';
import { Trans, translate } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { Button } from '../../components';

class Welcome extends React.Component {
  render() {
    return (
      <div className="text-center">
        <Row>
          <Col xs={12}>
            <h5>
              <Trans>
                Welcome to Placeshaker this tool will help you estimate the
                amount of renovation of your home
              </Trans>
            </h5>
          </Col>
          <Col xs={12}>
            <Button
              className={'text-uppercase'}
              color="default"
              size="lg"
              onClick={() => this.props.start()}
            >
              <Trans>Start</Trans>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default translate('translations-fr')(Welcome);
