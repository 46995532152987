import React, { Component } from 'react';

import { FormGroup, Input } from 'reactstrap';

class NumberField extends Component {
  parseNumber(value) {
    return isNaN(parseFloat(value)) ? 0 : parseFloat(value);
  }

  render() {
    let { value } = this.props;

    return (
      <div className={'numberfield'}>
        <button
          className="left"
          onClick={() => this.props.onIncDec(this.parseNumber(value) - 1)}
        >
          <i className="now-ui-icons ui-1_simple-delete" />
        </button>
        <FormGroup
          className={'has-label form-validation-10 ' + this.props.valid}
        >
          <Input
            type="number"
            innerRef={
              this.props.innerRef ? node => this.props.innerRef(node) : null
            }
            namevalid={this.props.namevalid}
            value={value || ''}
            step={0.01}
            min={1}
            required="required"
            onChange={event => this.props.onChange(event)}
          />
        </FormGroup>
        <button
          className="right"
          onClick={() => this.props.onIncDec(this.parseNumber(value) + 1)}
        >
          <i className="now-ui-icons ui-1_simple-add" />
        </button>
      </div>
    );
  }
}

export default NumberField;
