import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import auth from './auth';

/**
 * Notifications
 * @param state
 * @param action
 * @return {*[]}
 */
const notifications = (state = [], action) => {
  switch (action.type) {
    case 'NOTIFICATION_ADD': {
      state.push(action.payload);
      break;
    }

    case 'NOTIFICATION_REMOVE': {
      state.pop();
      break;
    }

    case 'CLEAN_NOTIFICATIONS':
      state = [];
      break;

    default:
      break;
  }
  return [...state];
};

export default combineReducers({
  notifications,
  fb: firebaseReducer,
  auth
});
