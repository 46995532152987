import React from 'react';
import { Trans } from 'react-i18next';
import { FormGroup, Input, Label, Col, Row } from 'reactstrap';
import { Button } from 'components';

class QuestionInputOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      selectOption: false
    };
  }

  isValid() {
    let { selectOption } = this.state;

    if (selectOption) {
      return true;
    } else {
      let inputElement = this.inputElement;
      return inputElement.validity.valid;
    }
    // let { value } = this.state;
    // return value != null;
  }

  confirm() {
    if (this.isValid()) {
      let { value } = this.state;
      this.props.onConfirm(value);
    }
  }

  render() {
    let { value, selectOption } = this.state,
      { option } = this.props;

    return (
      <div>
        <Row>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>{this.props.question}</Trans>
            </h5>
          </Col>
          <Col xs={12} md={6}>
            <FormGroup
              check
              className="form-check-radio p-3 mb-2 mt-0 bg-white rounded options-radios-card"
            >
              <Label check>
                <Input
                  type="radio"
                  name="radios-option"
                  checked={!!selectOption}
                  onChange={() =>
                    this.setState({ value: option.value, selectOption: true })
                  }
                />
                <span className="form-check-sign" />
                <Trans>{option.label}</Trans>
              </Label>
            </FormGroup>
          </Col>
          <Col xs={12} md={6}>
            <Input
              innerRef={node => (this.inputElement = node)}
              className="p-3 mb-2 bg-white rounded options-radios-card"
              {...this.props.inputProps}
              value={!selectOption ? value || '' : ''}
              onChange={event =>
                this.setState({
                  value: event.target.value,
                  selectOption: false
                })
              }
            />
          </Col>
          <Col xs={12}>
            <Button
              className="float-right"
              color="default"
              onClick={() => this.confirm()}
            >
              <Trans>Confirm</Trans>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

QuestionInputOption.propTypes = {};

export default QuestionInputOption;
