import MyProjects from '../views/MyProjects/MyProjects';
import Details from '../views/MyProjects/Details';
import Orders from '../views/Projects/Order/Orders';
import DevisDetails from '../views/Projects/Devis/DevisDetails/DevisDetails';
import DevisPrint from '../views/Projects/Devis/DevisPrintNew';

const myprojectRoutes = [
  {
    path: '/',
    name: 'My Projects',
    component: MyProjects
  },
  {
    id: 'projectDetails',
    path: '/:id/details',
    name: 'Project Details',
    component: Details,
    origin: 'projects'
  },
  {
    path: '/:id/orders',
    name: 'Orders',
    short: 'Orders',
    mini: 'OR',
    icon: 'business_money-coins',
    component: Orders
  },
  {
    id: 'projectDetailsDevis',
    path: '/:id/devis/:devisId/details',
    name: 'Devis',
    component: DevisDetails,
    origin: 'projectDetails'
  },
  {
    id: 'projectDevisPrint',
    path: '/:id/devis/:devisId/print',
    name: 'Print Devis',
    component: DevisPrint,
    origin: 'projectEditDevis'
  },
  {
    redirect: true,
    path: '/my-projects',
    pathTo: '/',
    name: 'My Projects'
  }
];

export default myprojectRoutes;
