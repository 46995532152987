import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import get from 'lodash/get';
import { proAvatar, proName } from '../../../helpers/formatters';

class AssignPro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      project: {}
    };
  }

  componentWillReceiveProps({ project }) {
    this.setState({ project });
  }

  componentWillMount() {
    let { project } = this.props;
    this.setState({ project });
  }

  render() {
    let { project } = this.state;

    return (
      <Card key={'assignPro'} className="card-flex user-data">
        <CardHeader className={'text-center'}>
          <h6>
            <Trans>Professional</Trans>
          </h6>
        </CardHeader>
        <CardBody>
          <div
            className="project-detail-avatar"
            style={{
              backgroundImage: `url('${proAvatar(project.pro)}')`
            }}
          />
          <CardTitle tag="h5" className={'text-center'}>
            {proName(project.pro)}
          </CardTitle>
          {project.pro ? (
            <div>
              <ul>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Email</Trans>:
                  </strong>
                  {get(project, 'pro.email', '') ? (
                    <a
                      href={`mailto:${get(
                        project,
                        'pro.email',
                        ''
                      )}?Subject=Placeshaker`}
                      target="_top"
                      rel="noopener noreferrer"
                    >
                      {get(project, 'pro.email', '')}
                    </a>
                  ) : null}
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Address</Trans>:
                  </strong>
                  {get(project, 'pro.lat', null) &&
                  get(project, 'pro.lng', null) ? (
                    <a
                      href={`https://www.google.com/maps/place/${get(
                        project,
                        'pro.address',
                        ''
                      )}/@=${project.pro.lat},${project.pro.lng}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {get(project, 'pro.address', '')}
                    </a>
                  ) : (
                    get(project, 'pro.address', '')
                  )}
                </li>
                <li>
                  <strong className={'mr-1'}>
                    <Trans>Mobile</Trans>:
                  </strong>
                  <a href={`tel:${get(project, 'pro.phone.mobile', '')}`}>
                    {get(project, 'pro.phone.mobile', '')}
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <p className="text-center">
                  <Trans>Assign this project to a professional</Trans>
                </p>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(AssignPro));
