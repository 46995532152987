import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  ListGroup,
  ListGroupItem
} from 'reactstrap';
import { Button } from 'components';

class QuestionCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: []
    };
  }

  addResult(event, option) {
    let { result } = this.state;

    if (event.target.checked) {
      result.push(option.value);
    } else {
      let index = result.findIndex(t => t === option.value);
      if (index !== -1) result.splice(index, 1);
    }

    this.setState(ps => ({ ...ps, result }));
  }

  isValid() {
    let { result } = this.state;
    return result && (this.props.required ? result.length > 0 : true);
  }

  confirm() {
    if (this.isValid()) {
      let { result } = this.state;
      this.props.onConfirm(result);
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>{this.props.question}</Trans>
            </h5>
          </Col>
          <Col xs={12}>
            <ListGroup className={'bg-white rounded options-radios-card'}>
              {this.props.options.map((option, key) => {
                return (
                  <ListGroupItem key={key}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          onChange={event => this.addResult(event, option)}
                        />
                        <span className="form-check-sign" />
                        {option.label}
                      </Label>
                    </FormGroup>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </Col>
          <Col xs={12}>
            <Button
              className="float-right"
              color="default"
              onClick={() => this.confirm()}
            >
              <Trans>Confirm</Trans>
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

QuestionCheckbox.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object)
};

export default connect()(translate('translations-fr')(QuestionCheckbox));
