import { notify } from './index';

/************ PROJECTS ************/
export const all = params => (dispatch, getState, { api }) =>
  api.Projects.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = (id, query = {}) => (dispatch, getState, { api }) =>
  api.Projects.one(id, query).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = p => (dispatch, getState, { api }) =>
  api.Projects.save(p)
    .then(response => {
      dispatch(notify('info', 'Project saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Projects.del(id)
    .then(response => {
      dispatch(notify('info', 'Project removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const updateStatus = (id, status) => (dispatch, getState, { api }) =>
  api.Projects.updateStatus(id, { status, force: true })
    .then(response => {
      dispatch(notify('info', 'Project status saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const loadHistory = id => (dispatch, getState, { api }) =>
  api.Projects.history(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const postulate = id => (dispatch, getState, { api }) =>
  api.Projects.postulate(id)
    .then(response => {
      dispatch(notify('info', 'Postulation saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const postulatedUsers = id => (dispatch, getState, { api }) =>
  api.Projects.postulatedUsers(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const market = params => (dispatch, getState, { api }) =>
  api.Projects.market(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const loadStatuses = () => (dispatch, getState, { api }) =>
  api.Projects.statuses().catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const location = params => (dispatch, getState, { api }) =>
  api.Projects.location(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const updateSupervisors = (id, data) => (dispatch, getState, { api }) =>
  api.Projects.updateSupervisors(id, data)
    .then(response => {
      dispatch(notify('info', 'Project Supervisor updated'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const updateSellers = (id, data) => (dispatch, getState, { api }) =>
  api.Projects.updateSellers(id, data)
    .then(response => {
      dispatch(notify('info', 'Project Seller updated'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const chatNotification = (id, text) => (dispatch, getState, { api }) =>
  api.Projects.chatNotification(id, text)
    .then(response => {
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const reportProjectsStatusUser = params => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.reportProjectsStatusUser(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const report = (id, data) => (dispatch, getState, { api }) =>
  api.Projects.report(id, data)
    .then(response => {
      dispatch(notify('info', 'Project report created'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const sendReport = (id, docId) => (dispatch, getState, { api }) =>
  api.Projects.sendReport(id, docId)
    .then(response => {
      dispatch(notify('info', 'Project report sended to the client'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const script = params => (dispatch, getState, { api }) =>
  api.Projects.script(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });
/************ PROJECTS ************/

/************ PROJECTS  DOCUMENTS************/
export const loadDocuments = (id, data = {}) => (dispatch, getState, { api }) =>
  api.Projects.getDocuments(id, data).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const addDocument = (id, files, type = 'Autres') => (
  dispatch,
  getState,
  { api }
) => {
  let fd = new FormData();
  for (let x = 0; x < files.length; x++) {
    fd.append('file' + x, files[x]);
  }
  fd.append('type', type);
  return api.Projects.addDocument(id, fd)
    .then(response => {
      dispatch(notify('info', 'Document saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
};

export const saveDocument = (id, doc) => (dispatch, getState, { api }) =>
  api.Projects.saveDocument(id, doc)
    .then(response => {
      dispatch(notify('info', 'Document saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removeDocument = (id, document) => (dispatch, getState, { api }) =>
  api.Projects.deleteDocument(id, document)
    .then(response => {
      dispatch(notify('info', 'Document removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const sendDocByEmail = (pid, id, data) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.sendDocByEmail(pid, id, data)
    .then(response => {
      dispatch(notify('info', 'Document sent by email'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/************ PROJECTS  DOCUMENTS************/

/************ PROJECTS  EVENTS************/
export const adminEvents = params => (dispatch, getState, { api }) =>
  api.Projects.allEvents(params)
    .then(response => {
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const loadEvents = id => (dispatch, getState, { api }) =>
  api.Projects.getEvents(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const saveEvent = (id, event) => (dispatch, getState, { api }) =>
  api.Projects.saveEvent(id, event)
    .then(response => {
      dispatch(notify('info', 'Event saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removeEvent = (id, event) => (dispatch, getState, { api }) =>
  api.Projects.deleteEvent(id, event)
    .then(response => {
      dispatch(notify('info', 'Event removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const sendEventByEmail = (pid, id, data) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.sendEventByEmail(pid, id, data)
    .then(response => {
      dispatch(notify('info', 'Event sent by email'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/************ PROJECTS  EVENTS************/

/************ PROJECTS  MAPS************/
export const loadMaps = id => (dispatch, getState, { api }) =>
  api.Projects.getMaps(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const saveMap = (id, map) => (dispatch, getState, { api }) =>
  api.Projects.saveMap(id, map)
    .then(response => {
      dispatch(notify('info', 'Map saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removeMap = (id, map) => (dispatch, getState, { api }) =>
  api.Projects.deleteMap(id, map)
    .then(response => {
      dispatch(notify('info', 'Map removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/************ PROJECTS  MAPS************/

/************ PROJECTS  MEDIAS************/
export const loadMedias = id => (dispatch, getState, { api }) =>
  api.Projects.getMedias(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const addMedia = (id, files) => (dispatch, getState, { api }) => {
  let fd = new FormData();
  for (let x = 0; x < files.length; x++) {
    fd.append('file' + x, files[x]);
  }
  return api.Projects.addMedia(id, fd)
    .then(response => {
      dispatch(notify('info', 'Media saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
};

export const updateMedia = (id, mid, doc) => (dispatch, getState, { api }) =>
  api.Projects.updateMedia(id, mid, doc)
    .then(response => {
      dispatch(notify('info', 'Media saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const updateMedias = (id, medias) => (dispatch, getState, { api }) => {
  let promise = medias.map(m => {
    return api.Projects.updateMedia(id, m._id ? m._id : m.id, m);
  });

  return Promise.all(promise)
    .then(response => {
      dispatch(notify('info', 'All medias saved'));
      return response;
    })
    .catch(err => {
      dispatch(
        notify(
          'danger',
          `Not all the medias were saved successfully due to the following error: ${err.message}`
        )
      );
      throw err;
    });
};

export const removeMedia = (id, media) => (dispatch, getState, { api }) =>
  api.Projects.deleteMedia(id, media)
    .then(response => {
      dispatch(notify('info', 'Media removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removeMedias = (id, medias) => (dispatch, getState, { api }) => {
  let promise = medias.map(m => {
    return api.Projects.deleteMedia(id, m);
  });

  return Promise.all(promise)
    .then(response => {
      dispatch(notify('info', 'All medias removed'));
      return response;
    })
    .catch(err => {
      dispatch(
        notify(
          'danger',
          `Not all the medias were removed successfully due to the following error: ${err.message}`
        )
      );
      throw err;
    });
};
/************ PROJECTS  MEDIAS************/

/************ PROJECTS  NOTES************/
export const adminNotes = params => (dispatch, getState, { api }) =>
  api.Projects.allNotes(params)
    .then(response => {
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const loadNotes = id => (dispatch, getState, { api }) =>
  api.Projects.getNotes(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const saveNote = (id, note) => (dispatch, getState, { api }) =>
  api.Projects.saveNote(id, note)
    .then(response => {
      dispatch(notify('info', 'Note saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removeNote = (id, note) => (dispatch, getState, { api }) =>
  api.Projects.deleteNote(id, note)
    .then(response => {
      dispatch(notify('info', 'Note removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const sendNoteByEmail = (pid, id, data) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.sendNoteByEmail(pid, id, data)
    .then(response => {
      dispatch(notify('info', 'Note sent by email'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const addNoteMedia = (pid, id, files) => (
  dispatch,
  getState,
  { api }
) => {
  let fd = new FormData();
  for (let x = 0; x < files.length; x++) {
    fd.append('file' + x, files[x]);
  }

  return api.Projects.addNoteMedia(pid, id, fd)
    .then(response => {
      dispatch(notify('info', 'Note Media saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
};

export const deleteNoteMedia = (pid, nid, id) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.deleteNoteMedia(pid, nid, id)
    .then(response => {
      dispatch(notify('info', 'Note Media deleted'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const updateNoteMedia = (pid, nid, media) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.updateNoteMedia(pid, nid, media)
    .then(response => {
      dispatch(notify('info', 'Note Media updated'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/************ PROJECTS  NOTES************/

/************ PROJECTS  OPERATIONS************/
export const loadOperations = id => (dispatch, getState, { api }) =>
  api.Projects.getOperations(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const saveOperation = (id, op) => (dispatch, getState, { api }) =>
  api.Projects.saveOperation(id, op)
    .then(response => {
      dispatch(notify('info', 'Operation saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removeOperation = (id, note) => (dispatch, getState, { api }) =>
  api.Projects.deleteOperation(id, note)
    .then(response => {
      dispatch(notify('info', 'Operation removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const createOperations = (id, ops) => (dispatch, getState, { api }) =>
  api.Projects.createOperations(id, ops)
    .then(response => {
      dispatch(notify('info', 'Operation saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const updateOperationStatus = (pid, id, op) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.updateOperationStatus(pid, id, op)
    .then(response => {
      dispatch(notify('info', 'Operation saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const estimateOperations = (id, data) => (dispatch, getState, { api }) =>
  api.Projects.estimateOperations(id, data)
    .then(response => {
      dispatch(notify('info', 'Document with operation estimation created'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/************ PROJECTS  OPERATIONS************/

/************ PROJECTS  PAYMENTS************/
export const loadPayments = (id, params) => (dispatch, getState, { api }) =>
  api.Projects.getPayments(id, params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const savePayment = (id, payment) => (dispatch, getState, { api }) =>
  api.Projects.savePayment(id, payment)
    .then(response => {
      dispatch(notify('info', 'Payment saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removePayment = (id, payment) => (dispatch, getState, { api }) =>
  api.Projects.deletePayment(id, payment)
    .then(response => {
      dispatch(notify('info', 'Payment removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const paymentPdfBills = (pid, id, data) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.paymentPdfBills(pid, id, data)
    .then(response => {
      dispatch(notify('info', 'Payment bills documentes created'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const paymentPdfBill = (pid, id) => async (
  dispatch,
  getState,
  { api }
) =>
  await api.Projects.paymentPdfBill(pid, id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });
/************ PROJECTS  PAYMENTS************/

/************ PROJECTS  PLANS************/
export const loadPlans = (id, data = {}) => (dispatch, getState, { api }) =>
  api.Projects.getPlans(id, data).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const loadPlan = (pid, id) => (dispatch, getState, { api }) =>
  api.Projects.getPlan(pid, id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const addPlan = (id, data) => (dispatch, getState, { api }) => {
  let fd = new FormData();
  Object.keys(data).forEach(key => {
    fd.append(key, data[key]);
  });

  return api.Projects.addPlan(id, fd)
    .then(response => {
      dispatch(notify('info', 'Plan saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
};

export const savePlan = (id, doc) => (dispatch, getState, { api }) =>
  api.Projects.savePlan(id, doc)
    .then(response => {
      dispatch(notify('info', 'Plan saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removePlan = (id, plan) => (dispatch, getState, { api }) =>
  api.Projects.deletePlan(id, plan)
    .then(response => {
      dispatch(notify('info', 'Plan removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

/** PROJECTS  PLANS NOTES*/
export const loadPlanNotes = (pid, planId) => (dispatch, getState, { api }) =>
  api.Projects.getPlanNotes(pid, planId).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const loadPlanNote = (pid, planId, id) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.getPlanNote(pid, planId, id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const addPlanNote = (pid, planId, data) => (
  dispatch,
  getState,
  { api }
) => {
  return api.Projects.addPlanNote(pid, planId, data)
    .then(response => {
      dispatch(notify('info', 'Plan saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
};

export const savePlanNote = (pid, planId, id, doc) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.savePlanNote(pid, planId, id, doc)
    .then(response => {
      dispatch(notify('info', 'Plan Note saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removePlanNote = (pid, planId, id) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.deletePlanNote(pid, planId, id)
    .then(response => {
      dispatch(notify('info', 'Plan Note removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const sendPlanNoteByEmail = (pid, planId, id, data) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.sendPlanNoteByEmail(pid, planId, id, data)
    .then(response => {
      dispatch(notify('info', 'Plan Note sent by email'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/** PROJECTS  PLANS NOTES*/

/** PROJECTS  PLANS TASKS*/
export const loadPlanTasks = (pid, planId) => (dispatch, getState, { api }) =>
  api.Projects.getPlanTasks(pid, planId).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const loadPlanTask = (pid, planId, id) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.getPlanTask(pid, planId, id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const addPlanTask = (pid, planId, data) => (
  dispatch,
  getState,
  { api }
) => {
  return api.Projects.addPlanTask(pid, planId, data)
    .then(response => {
      dispatch(notify('info', 'Plan saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
};

export const savePlanTask = (pid, planId, id, doc) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.savePlanTask(pid, planId, id, doc)
    .then(response => {
      dispatch(notify('info', 'Plan Task saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removePlanTask = (pid, planId, id) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.deletePlanTask(pid, planId, id)
    .then(response => {
      dispatch(notify('info', 'Plan Task removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const sendPlanTaskByEmail = (pid, planId, id, data) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.sendPlanTaskByEmail(pid, planId, id, data)
    .then(response => {
      dispatch(notify('info', 'Plan Task sent by email'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/** PROJECTS  PLANS TASKS*/

/** PROJECTS  PLANS MEDIAS*/
export const loadPlanImages = (pid, planId) => (dispatch, getState, { api }) =>
  api.Projects.getPlanImages(pid, planId).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const loadPlanImage = (pid, planId, id) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.getPlanImage(pid, planId, id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const addPlanImage = (pid, planId, data, files) => (
  dispatch,
  getState,
  { api }
) => {
  let fd = new FormData();
  Object.keys(data).forEach(key => {
    fd.append(key, data[key]);
  });
  for (let x = 0; x < files.length; x++) {
    fd.append('file' + x, files[x]);
  }

  return api.Projects.addPlanImage(pid, planId, fd)
    .then(response => {
      dispatch(notify('info', 'Plan saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
};

export const savePlanImage = (pid, planId, id, doc) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.savePlanImage(pid, planId, id, doc)
    .then(response => {
      dispatch(notify('info', 'Plan Image saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removePlanImage = (pid, planId, id) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.deletePlanImage(pid, planId, id)
    .then(response => {
      dispatch(notify('info', 'Plan Image removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const sendPlanImageByEmail = (pid, planId, id, data) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.sendPlanImageByEmail(pid, planId, id, data)
    .then(response => {
      dispatch(notify('info', 'Plan Image sent by email'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/** PROJECTS  PLANS MEDIAS*/
/************ PROJECTS  PLANS************/

/************ PROJECTS  VISITS************/
export const adminVisits = params => (dispatch, getState, { api }) =>
  api.Projects.allVisits(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const loadVisits = id => (dispatch, getState, { api }) =>
  api.Projects.getVisits(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const saveVisit = (id, visit) => (dispatch, getState, { api }) =>
  api.Projects.saveVisit(id, visit)
    .then(response => {
      dispatch(notify('info', 'Visit saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const deleteVisit = (id, visit) => (dispatch, getState, { api }) =>
  api.Projects.deleteVisit(id, visit)
    .then(response => {
      dispatch(notify('info', 'Document removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/************ PROJECTS  VISITS************/

/************ PROJECTS  DEVIS************/
export const loadAllDevis = (id, query = {}) => (dispatch, getState, { api }) =>
  api.Projects.getDevis(id, query).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const countDevisByProject = id => (dispatch, getState, { api }) =>
  api.Projects.countDevisByProject(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const loadOneDevis = (id, dId, query = {}) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.getDevi(id, dId, query).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const saveOneDevis = (id, devis) => (dispatch, getState, { api }) =>
  api.Projects.saveDevi(id, devis)
    .then(response => {
      dispatch(notify('info', 'Devis saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removeDevis = (pid, id) => (dispatch, getState, { api }) =>
  api.Projects.deleteDevi(pid, id)
    .then(response => {
      dispatch(notify('info', 'Devis removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const duplicateDevis = (pid, id) => (dispatch, getState, { api }) =>
  api.Projects.duplicateDevis(pid, id)
    .then(response => {
      dispatch(notify('info', 'Devis duplicated'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const updateDevisMaterials = (pid, id, materials) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.updateDevisMaterials(pid, id, materials)
    .then(response => {
      dispatch(notify('info', 'Devis materials updated'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const pdf = (pid, id) => async (dispatch, getState, { api }) =>
  await api.Projects.devisPdfUrl(pid, id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const pdfGet = (pid, id, params) => async (
  dispatch,
  getState,
  { api }
) =>
  await api.Projects.devisPdfUrlGet(pid, id, params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const processImages = medias => async (dispatch, getState, { api }) =>
  await api.Projects.processImages(medias).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const pdfCompound = pid => async (dispatch, getState, { api }) =>
  await api.Projects.devisCompoundPdfUrl(pid).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const estimateDevis = (id, data) => (dispatch, getState, { api }) =>
  api.Projects.estimateDevis(id, data)
    .then(response => {
      dispatch(notify('info', 'Document with operation estimation created'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

/** PROJECTS  DEVIS DOCUMETS*/
export const allDevisDocuments = (pid, did) => (dispatch, getState, { api }) =>
  api.Projects.allDevisDocuments(pid, did).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const oneDevisDocuments = (pid, did, id) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.oneDevisDocuments(pid, did, id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const createDevisDocuments = (pid, did, files) => (
  dispatch,
  getState,
  { api }
) => {
  let fd = new FormData();
  for (let x = 0; x < files.length; x++) {
    fd.append('file' + x, files[x]);
  }

  return api.Projects.createDevisDocuments(pid, did, fd)
    .then(response => {
      dispatch(notify('info', 'Devis Document saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
};

export const updateDevisDocuments = (pid, did, data) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.updateDevisDocuments(pid, did, data)
    .then(response => {
      dispatch(notify('info', 'Devis Document saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const delDevisDocuments = (pid, did, id) => (
  dispatch,
  getState,
  { api }
) =>
  api.Projects.delDevisDocuments(pid, did, id)
    .then(response => {
      dispatch(notify('info', 'Devis Document removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/** PROJECTS  DEVIS DOCUMETS*/
/************ PROJECTS  DEVIS************/

/************ PROJECTS  TASKS************/
export const loadTasksLists = pid => (dispatch, getState, { api }) =>
  api.Projects.getTasksLists(pid).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const getTasksList = (pid, id) => (dispatch, getState, { api }) =>
  api.Projects.getTasksList(pid, id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const saveTasksList = (pid, list) => (dispatch, getState, { api }) =>
  api.Projects.saveTasksList(pid, list)
    .then(response => {
      dispatch(notify('info', 'Task List saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removeTasksList = (pid, id) => (dispatch, getState, { api }) =>
  api.Projects.deleteTasksList(pid, id)
    .then(response => {
      dispatch(notify('info', 'Task List removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const saveTask = (pid, listId, task) => (dispatch, getState, { api }) =>
  api.Projects.saveTask(pid, listId, task)
    .then(response => {
      dispatch(notify('info', 'Task saved'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const removeTask = (pid, listId, id) => (dispatch, getState, { api }) =>
  api.Projects.deleteTask(pid, listId, id)
    .then(response => {
      dispatch(notify('info', 'Task removed'));
      return response;
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
/************ PROJECTS  TASKS************/
