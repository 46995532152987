import React from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { FormGroup, Input, Label, Col, Row } from 'reactstrap';

class QuestionRadioIcon extends React.Component {
  render() {
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h5 className={'text-left'}>
              <Trans>{this.props.question}</Trans>
            </h5>
          </Col>
          {this.props.options.map((option, key) => {
            return (
              <Col xs={12} key={key}>
                <div className="radio-icon">
                  <div className="point-icon">
                    <i className={`fa ${option.icon}`} />
                  </div>
                  <FormGroup
                    check
                    className="form-check-radio p-3 mb-2 bg-white options-radios-card"
                  >
                    <Label check>
                      <Input
                        type="radio"
                        name="radios"
                        onChange={() =>
                          this.props.onConfirm(option.value, option.nextIndex)
                        }
                      />
                      <span className="form-check-sign" />
                      <Trans>{option.label}</Trans>
                    </Label>
                  </FormGroup>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

QuestionRadioIcon.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object)
};

export default connect()(translate('translations-fr')(QuestionRadioIcon));
// export default QuestionRadio;
