import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { all } from '../../helpers/actions/users';
import { loadStatuses } from '../../helpers/actions/projects';
import { projectType } from '../../helpers/nomenclators';
import { supervisorName } from '../../helpers/formatters';
import Select from 'react-select';
import { Accordion } from '../../components';

class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supervisors: [],
      supervisor: '',
      statuses: [],
      status: '',
      search: '',
      type: ''
    };
  }

  componentWillReceiveProps() {}

  componentWillMount() {
    let { dispatch } = this.props;

    Promise.all([
      dispatch(all({ limit: 1000, role: 'supervisor' })),
      dispatch(loadStatuses())
    ])
      .then(([supervisors, { status }]) => {
        this.setState({
          statuses: status,
          supervisors: supervisors.data
        });
      })
      .catch(() => {});
  }

  setFilter = (name, value) => {
    this.setState(ps => ({
      ...ps,
      [name]: value
    }));
    this.props.filter(name, value);
  };

  render() {
    let {
      supervisors,
      supervisor,
      statuses,
      status,
      search,
      type
    } = this.state;
    let { t } = this.props;

    return (
      <Card className={'mb-0 mb-md-3'}>
        <div className={'d-none d-md-inline'}>
          <CardBody>
            <Row>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Search</Trans>
                  </Label>
                  <Input
                    type="text"
                    value={search}
                    onChange={event =>
                      this.setFilter('search', event.target.value)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Type</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={projectType.map(pt => ({
                      label: t(pt),
                      value: pt
                    }))}
                    value={type}
                    onChange={event =>
                      this.setFilter('type', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Supervisor</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={supervisors.map(sup => ({
                      label: supervisorName(sup),
                      value: sup._id
                    }))}
                    value={supervisor}
                    onChange={event =>
                      this.setFilter('supervisor', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Status</Trans>
                  </Label>
                  <Select
                    className="primary"
                    options={statuses.map(s => ({ label: t(s), value: s }))}
                    value={status}
                    onChange={event =>
                      this.setFilter('status', event ? event.value : null)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </div>
        <div className={'d-inline d-md-none'}>
          <CardBody>
            <Row>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label>
                    <Trans>Search</Trans>
                  </Label>
                  <Input
                    type="text"
                    value={search}
                    onChange={event =>
                      this.setFilter('search', event.target.value)
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Accordion
              key={'projects-list-advanced-filters'}
              plain
              defaultOpened={-1}
              components={[
                {
                  title: (
                    <h6 style={{ display: 'inline' }}>
                      <Trans>More Filters</Trans>
                    </h6>
                  ),
                  text: (
                    <Row>
                      <Col xs={12} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Type</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={projectType.map(pt => ({
                              label: t(pt),
                              value: pt
                            }))}
                            value={type}
                            onChange={event =>
                              this.setFilter('type', event ? event.value : null)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Supervisor</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={supervisors.map(sup => ({
                              label: supervisorName(sup),
                              value: sup._id
                            }))}
                            value={supervisor}
                            onChange={event =>
                              this.setFilter(
                                'supervisor',
                                event ? event.value : null
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={12} md={3}>
                        <FormGroup>
                          <Label>
                            <Trans>Status</Trans>
                          </Label>
                          <Select
                            className="primary"
                            options={statuses.map(s => ({
                              label: t(s),
                              value: s
                            }))}
                            value={status}
                            onChange={event =>
                              this.setFilter(
                                'status',
                                event ? event.value : null
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  )
                }
              ]}
            />
            <Row>
              <Col className={'text-right'} xs={12} md={3}>
                <Link to={'/projects/new'} className="btn btn-sm btn-info m-0">
                  <Trans>Add</Trans>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </div>
      </Card>
    );
  }
}

export default connect()(translate('translations-fr')(Filters));
