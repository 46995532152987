import React from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { PanelHeader } from '../../components';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  componentWillMount() {}

  render() {
    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content" />
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(Dashboard));
